import { Button, Grid } from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import Swal from "sweetalert2";
import api from './../../../../../../Apis'
import { requestOptions } from "../../../../../../Helpers/function";
import { useTranslation } from "react-i18next";
export default function PostItemError(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        loading: false
    })
    const detailPost = () => {
        props.detailPost(props.postItem);
    }

    const rePublish = () => {
        Swal.fire({
            title: t("post.confirmRepost"),
            showDenyButton: true,
            confirmButtonText: t("common.alert.confirm"),
            denyButtonText: t("common.alert.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                setState({
                    ...state,
                    loading: true
                })
                fetch(api.publish_post_special_to_facebook, requestOptions('POST', props.cookie, {id: props.postItem.id, fanpage_id: props.postItem.fanpage_id, lang: i18n.language}))
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            Swal.fire({
                                title: result.message,
                                icon: 'success',
                                timer: '1500'
                            })
                            setState({
                                ...state,
                                loading: false
                            })
                            props.realoadListPostError(props.stt);
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                            })
                            setState({
                                ...state,
                                loading: false
                            })
                        }
                    }
                )
            }
        })
        
    }

    return (
        <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
            <Grid item xs={1}>
                {props.stt + 1}
            </Grid>
            <Grid item xs={4}>
                {props.postItem.fanpage_name}
            </Grid>
            <Grid item xs={2}>
                <Button
                    variant="contained"
                    className="text-transform"
                    color="warning"
                    onClick={detailPost}
                >{t("common.detail")}</Button>
            </Grid>
            <Grid item xs={3}>
                {props.postItem.log_publish}
            </Grid>
            <Grid item xs={2}>
                <LoadingButton onClick={rePublish} startIcon={<ReplayIcon/>} className="text-transform" loading={state.loading} variant="outlined">
                   {t("post.repost")}
                </LoadingButton>
            </Grid>
        </Grid>
    )
}