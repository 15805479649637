import { combineReducers } from "redux";
import userReducer from "./UserReducer";
import messageReducer from "./MessageReducer";
import phoneNumberReducer from "./PhoneNumberFromMessageReducer";
import messSampleReducer from "./MessSampleReducer";
import loadingReducer from "./LoadingReducer";

const rootReducer = combineReducers({
    user: userReducer,
    message: messageReducer,
    phone_number: phoneNumberReducer,
    mess_sample: messSampleReducer,
    loading: loadingReducer
});

export default rootReducer;