import { Autocomplete, Backdrop, Button, CircularProgress, Container, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router";
import api from './../../../../../Apis';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Product from "../../Fanpages/Messages/Orders/Components/Product";
import Swal from "sweetalert2";
import LoadingButton from "@mui/lab/LoadingButton";
import ButtonUpdateOrder from './ButtonUpdateOrder';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Box } from "@mui/system";
import TabList from "@mui/lab/TabList";
import TimeLineStatusOrder from "./TimeLineStatusOrder";
import Chat from "./Chat";
import CreateBillTransport from "./CreateBillTransport";
import DetailBillTransport from "./DetailBillTransport";
import {getAddress} from './../../../../../Helpers/function'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function OrderDetail() {
    const { t, i18n } = useTranslation();
    const {id} = useParams();
    let navigate = useNavigate();
    const [state, setState] = useState({
        value: '1',
        loading_update: false,
        loading_backdrop: true,
        dialog: false,
        order: '',
        provinces: [],
        district_by_province: [],
        get_ward_by_district: [],
        service_codes: [],
        transports: [],
        payment_methods: [],
        status: [],
        status_transports: {},
        employees: [],
        products: [],
        error: '',
        history_orders: [],
        messages: [],
        fanpage_id: '',
        conversation_id: '',
        warehouses: [],
        errors: {}
    })
    const [cookies, setCookie] = useCookies('is_login');
    
    useEffect(() => {
        callApi();
    }, [])

    const callApi = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                id: id,
                lang: i18n.language
            })
        };
        fetch(api.order_detail, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        order: result.order,
                        provinces: result.provinces,
                        district_by_province: result.district_by_province,
                        get_ward_by_district: result.get_ward_by_district,
                        transports: result.transport,
                        payment_methods: result.payment_methods,
                        status: result.status,
                        employees: result.employees,
                        products: result.products,
                        loading_backdrop: false,
                        history_orders: result.history_orders,
                        messages: result.messages,
                        fanpage_id: result.fanpage_id,
                        conversation_id: result.conversation_id,
                        warehouses: result.warehouses,
                        status_transports: result.status_transports,
                        service_codes: result.service_codes
                    })
                } else {
                    setState({
                        ...state,
                        order: {},
                        error: result.message,
                        loading_backdrop: false
                    })
                }
            }
        )
    }

    const removeProduct = (index) => {
        const order = {...state.order};
        if (order.order_details.length == 1) {
            Swal.fire(t("order.message.cannotRemoveAllProducts"), '', 'warning');
        } else {
            // order.total = order.total - (order.order_details[index].price * order.order_details[index].amount);
            order.order_details.splice(index, 1);
            setState({
                ...state,
                order: order
            })
        }
        
    }

    const addToCart = (products) => {
        const carts = [];
        products.map((productItem) => {
            const item = JSON.parse(productItem);
            carts.push({
                'id': item.id,
                'product': {
                    'name': item.product_name,
                    'attribute': item.attribute,
                    'price': item.price
                },
                'amount': 1,
                'price': item.price
            });
        })
        const order = {...state.order};
        order.order_details = carts.concat(order.order_details);
        setState({
            ...state,
            order: order,
            dialog: false
        });
    }

    const changeTransport = (e) => {
        const order = {...state.order};
        order.transport = e.target.value;
        order.customer.province = {id: '', name: ''};
        order.customer.district = {id: '', name: ''};
        order.customer.ward = {id: '', name: ''};
        const res = getAddress(e.target.value, cookies.is_login, api.get_province_by_transport, null, null, i18n.language);
        res.then( result => {
            setState({
                ...state,
                order: order,
                provinces: result
            })
        })
    }

    const total = (data) => {
        if (data.length > 0) {
            return data.map(item => item.price * item.amount).reduce((prev, next) => prev + next);
        }

        return 0;
    }
    
    const is_number = (value) => {
        if ((Number(value) == value && value % 1 == 0) || (Number(value) == value && value % 1 != 0)) {
            return true;
        }

        return false
    }

    const changeNumber = (index, e) => {
        if (e.target.value > 0) {
            const order = {...state.order};
            order.order_details[index].amount = e.target.value;
            setState({
                ...state,
                order: order
            })
        }

    }

    const changeOrder = (e) => {
        const order = {...state.order};
        const index_name = e.target.name;
        if (index_name == 'extra' || index_name == 'discount') {
            if (!isNaN(e.target.value)) {
                var value = e.target.value == '' ? 0 : Number(e.target.value);
                order[index_name] = value;
                if (index_name == 'discount') {
                    const total_price = total(order.order_details);
                    const percent = total_price == 0 ? 0 : value/total_price * 100;
                    order.discount_percent = percent;
                }
                setState({
                    ...state,
                    order: order
                })
            }
        } else if (index_name == 'discount_percent') {
            if (is_number(e.target.value)) {
                const value = e.target.value;
                const total_price = total(order.order_details);
                order.discount = total_price * value/100;
                order.discount_percent = value;
                setState({
                    ...state,
                    order: order
                })
            }
        } else if (index_name == 'address' || index_name == 'name' || index_name == 'phone') {
            order.customer[index_name] = e.target.value;
            setState({
                ...state,
                order: order
            })
        } else {
            var value = e.target.value;
            order[index_name] = value;
            setState({
                ...state,
                order: order
            })
        }
        
        // console.log(e.target.value, e.target.name);
    }

    const changeDistrict = (e, obj) => {
        if (obj != null) {
            const order = {...state.order};
            order.customer.ward = {id: '', name: ''};
            order.customer.district = obj;
            const res = getAddress(order.transport, cookies.is_login, api.get_ward, null, obj.id, i18n.language);
            res.then( result => {
                setState({
                    ...state,
                    order: order,
                    get_ward_by_district: result
                })
            })
            // const requestOptions = {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': 'Bearer ' + cookies.is_login
            //     },
            //     body: JSON.stringify({
            //         district_id: obj.id
            //     })
            // };
            // fetch(api.get_ward, requestOptions)
            // .then(res => res.json())
            // .then(
            //     (result) => {
            //         if (result.success) {
            //             const order = {...state.order};
            //             order.customer.district = obj;
            //             setState({
            //                 ...state,
            //                 get_ward_by_district: result.datas,
            //                 order: order
            //             })
            //         }
            //     }
            // )
        }
    }

    const changeWard = (e, obj) => {
        if (obj != null) {
            const order = {...state.order};
            order.customer.ward = obj;
            setState({
                ...state,
                order: order
            })
        }
        
    }

    const changeProvince = (e, obj) => {
        if (obj != null) {
            const order = {...state.order};
            order.customer.district = {id: '', name: ''};
            order.customer.ward = {id: '', name: ''};
            order.customer.province = obj;
            const res = getAddress(order.transport, cookies.is_login, api.get_district, obj.id, null, i18n.language);
            res.then( result => {
                setState({
                    ...state,
                    order: order,
                    district_by_province: result
                })
            })
            // const requestOptions = {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': 'Bearer ' + cookies.is_login
            //     },
            //     body: JSON.stringify({
            //         province_id: obj.id
            //     })
            // };
            // fetch(api.get_district, requestOptions)
            // .then(res => res.json())
            // .then(
            //     (result) => {
            //         if (result.success) {
            //             const order = {...state.order};
            //             order.customer.province = obj;
            //             setState({
            //                 ...state,
            //                 district_by_province: result.datas,
            //                 order: order
            //             })
            //         }
            //     }
            // )
        }
        
    }

    const refreshOrder = (data) => {
        setState({
            ...state,
            order: data
        })
    }

    const responseValidate = (errors) => {
        setState({
            ...state,
            errors: errors
        })
    }

    const reloadPage = () => {
        setState({
            ...state,
            loading_backdrop: true
        })
        setTimeout(() => {
            callApi();
        }, 300);
    }

    return (
        <Container maxWidth="xl" className='m-t-40'>
            {
                state.loading_backdrop
                ?
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={state.loading_backdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                state.error != ''
                ?
                <p style={{textAlign: 'center'}}>
                    {
                        state.error
                    }
                    <br></br><br></br>
                    <Button onClick={reloadPage} startIcon={<RefreshRoundedIcon/>} className="text-transform" variant="contained">
                       {t("common.reload")}
                    </Button>
                </p>
                :
                <div>
                    <Button
                        className="text-transform"
                        startIcon={<ArrowBackRoundedIcon/>}
                        variant="contained"
                        color="inherit"
                        onClick={() => navigate(-1)}
                    >{t("common.back")}</Button>
                    <TabContext value={state.value}>
                        <Box sx={{ borderBottom: 0.5, borderColor: 'divider', borderColor: 'rgba(0, 0, 0, 0.12)'}}>
                            <TabList onChange={(e, newValue) => setState({...state, value: newValue})} aria-label="lab API tabs example">
                                <Tab className='text-transform' label={t("order.label.orderDetails")} value="1" />
                                <Tab className='text-transform' label={t("order.table.shippingOrder")} value="4" />
                                <Tab className='text-transform' label={t("order.label.chatContent")} value="2" />
                                <Tab className='text-transform' label={t("order.label.updateHistory")} value="3" />
                            </TabList>
                        </Box>
                        <TabPanel style={{padding: '10px 0px'}} value="4">
                            {
                                state.order.code_transport == null
                                ?
                                <center>
                                    <i>{t("order.orderDetail.orderNotShipped")}</i>
                                </center>
                                :
                                <DetailBillTransport 
                                    state={state}
                                    cookie={cookies.is_login}
                                />
                            }
                        </TabPanel>
                        <TabPanel style={{padding: '10px 0px'}} value="1">
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={6}>
                                    <h2 className="m-t-0 m-b-0">
                                        {t("order.table.order")} {state.order.code}
                                    </h2>
                                    <p style={{marginBottom: '30px'}}>
                                        {
                                            state.status[state.order.status] != null &&
                                            <span className="label" style={{background: state.status[state.order.status].color, color: '#fff'}}>
                                                {state.status[state.order.status].name}
                                            </span>
                                        }
                                    </p>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <CreateBillTransport
                                            state={state}
                                            cookie={cookies.is_login}
                                            order_id={id}
                                            refreshOrder={refreshOrder}
                                        />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} md={6}>
                                            <TextField 
                                                fullWidth
                                                value={state.order.customer ? state.order.customer.name : ''}
                                                size="large"
                                                id="outlined-basic"
                                                label={t("order.label.customName")}
                                                variant="outlined"
                                                name="name"
                                                onChange={changeOrder}
                                                helperText={state.errors['customer.name']?.message}
                                                error={state.errors['customer.name']?.status}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <TextField 
                                                    fullWidth 
                                                    value={state.order.customer ? state.order.customer.phone : ''}
                                                    size="large"
                                                    id="outlined-basic"
                                                    label={t("common.label.phoneNumber")}
                                                    variant="outlined"
                                                    name="phone"
                                                    onChange={changeOrder}
                                                    helperText={state.errors['customer.phone']?.message}
                                                    error={state.errors['customer.phone']?.status}
                                                />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <FormControl size="large" fullWidth error={state.errors.transport?.status}>
                                                <InputLabel id="demo-simple-select-label">{t("common.label.shippingCarrier")}</InputLabel>
                                                <Select
                                                    onChange={changeTransport}
                                                    //onChange={changeOrder}
                                                    labelId="demo-simple-select-label"
                                                    name="transport"
                                                    id="demo-simple-select"
                                                    value={state.order.transport != undefined && state.order.transport}
                                                    label={t("common.label.shippingCarrier")}
                                                    helperText={'chinh123'}
                                                    
                                                >
                                                    {
                                                        state.transports.map((value, index) => {
                                                            return (
                                                                <MenuItem value={index}>
                                                                    {value}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText sx={{marginLeft: '0px'}}>{state.errors.transport != undefined ? state.errors.transport.message : t("order.orderDetail.selectLocationWhenChangingCarrier")}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Autocomplete
                                                id="size-small-outlined"
                                                size="large"
                                                name="province"
                                                onChange={changeProvince}
                                                options={state.provinces}
                                                value={{name: state.order.customer ? state.order.customer.province.name : '', id: state.order.customer ? state.order.customer.province.id : ''}}
                                                getOptionLabel={(option) => option.name}
                                                isOptionEqualToValue={(option) => option.id}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("common.label.provinceCity")}
                                                    helperText={state.errors['customer.province.id']?.message}
                                                    error={state.errors['customer.province.id']?.status}
                                                />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Autocomplete
                                                id="size-small-outlined"
                                                size="large"
                                                name="district"
                                                onChange={changeDistrict}
                                                options={state.district_by_province}
                                                value={{name: state.order.customer ? state.order.customer.district.name : '', id: state.order.customer ? state.order.customer.district.id : ''}}
                                                getOptionLabel={(option) => option.name}
                                                isOptionEqualToValue={(option) => option.id}
                                                renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    label={t("common.label.district")}
                                                    helperText={state.errors['customer.district.id']?.message}
                                                    error={state.errors['customer.district.id']?.status}
                                                />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Autocomplete
                                                id="size-small-outlined"
                                                size="large"
                                                name="ward"
                                                onChange={changeWard}
                                                options={state.get_ward_by_district}
                                                value={{name: state.order.customer ? state.order.customer.ward.name : '', id: state.order.customer ? state.order.customer.ward.id : ''}}
                                                getOptionLabel={(option) => option.name}
                                                isOptionEqualToValue={(option) => option.id}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("common.label.wardCommune")}
                                                    helperText={state.errors['customer.ward.id']?.message}
                                                    error={state.errors['customer.ward.id']?.status}
                                                />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <TextField 
                                                    fullWidth 
                                                    value={state.order.customer ? state.order.customer.address : ''}
                                                    size="large"
                                                    id="outlined-basic"
                                                    label={t("common.label.address")}
                                                    helperText={state.errors['customer.address']?.message}
                                                    error={state.errors['customer.address']?.status}
                                                    variant="outlined"
                                                    name="address"
                                                    onChange={changeOrder}
                                                />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <FormControl size="large" fullWidth>
                                                <InputLabel id="demo-simple-select-label">{t("common.label.payment")}</InputLabel>
                                                <Select
                                                    onChange={changeOrder}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name="payment_method"
                                                    value={state.order.payment_method != undefined && state.order.payment_method}
                                                    label={t("common.label.payment")}
                                                >
                                                    {
                                                        state.payment_methods.map((value, index) => {
                                                            return (
                                                                <MenuItem value={index}>
                                                                    {value}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        
                                        <Grid item xs={6} md={6}>
                                            <FormControl size="large" fullWidth>
                                                <InputLabel id="demo-simple-select-label">{t("order.label.status")}</InputLabel>
                                                <Select
                                                    onChange={changeOrder}
                                                    name="status"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={state.order.status != undefined && state.order.status}
                                                    label={t("order.tabel.shipping")}
                                                >
                                                    {
                                                        state.status.map((value, index) => {
                                                            return (
                                                                <MenuItem value={index}>
                                                                    {value.name}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <FormControl size="large" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Sale</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    onChange={changeOrder}
                                                    name="user_id"
                                                    value={state.order.user_id != undefined && state.order.user_id}
                                                    label="Sale"
                                                >
                                                    {
                                                        state.employees.map((value, index) => {
                                                            return (
                                                                <MenuItem value={value.id}>
                                                                    {value.name}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <textarea name="note" value={state.order.note} onChange={changeOrder} placeholder={t("order.orderDetail.orderNotes")} cols="30" rows="4" className="textarea"></textarea>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TableContainer component={Paper}>
                                        <Dialog open={state.dialog} fullWidth="true" maxWidth='lg' onClose={() => {setState({...state, dialog: false})}}>
                                            <DialogTitle>{t("order.productList")}</DialogTitle>
                                                <DialogContent>
                                                    <Product cookie={cookies.is_login} products={state.products} addToCart={addToCart}/>
                                                </DialogContent>
                                        </Dialog>
                                        <Table aria-label="simple table" className="table-cart">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="right" colSpan={5}>
                                                        <Button onClick={() => setState({...state, dialog: true})} startIcon={<AddRoundedIcon/>} sx={{textAlign: "left", textTransform: "unset"}} size="small" variant="contained">
                                                            {t("order.product")}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>{t("order.product")}</TableCell>
                                                    <TableCell align="center">{t("order.quantity")}</TableCell>
                                                    <TableCell align="right">{t("order.priceVND")}</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                state.order.order_details.map((item, key) => (
                                                    <TableRow
                                                    key={key}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell scope="row">
                                                            {item.product.name}
                                                            {(item.product.attribute != '' && item.product.attribute != null) ? ' (' + item.product.attribute + ')' : ''}
                                                            {/* <small>
                                                                (
                                                                    {item.product.product_type_group}: <b>{item.product.product_type_group_name}</b>
                                                                    {item.product.product_type_name != null && ', '}
                                                                    {item.product.product_type != null && item.product.product_type + ': '}<b>{item.product.product_type_name}</b>
                                                                )
                                                            </small> */}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <TextField
                                                                size="small"
                                                                onChange={(e) => changeNumber(key, e)}
                                                                id="standard-helperText"
                                                                variant="outlined"
                                                                value={item.amount}
                                                                type="number"
                                                            />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <b>
                                                                {Number(item.price * item.amount).toLocaleString()}
                                                            </b>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <ClearRoundedIcon onClick={() => removeProduct(key)} className="cursor" size="small" color="error"/>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <b>{t("order.additionalCharge")}</b>
                                                    </TableCell>
                                                    <TableCell colSpan={2}>
                                                        <TextField name="extra" onChange={changeOrder} fullWidth size="small" id="outlined-basic" variant="outlined" value={state.order.extra}/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <b>{t("order.discount")}</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField size="small" id="outlined-basic" onChange={changeOrder} name="discount" value={state.order.discount} label={t("order.label.amount")} variant="outlined" />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField size="small" onChange={changeOrder} id="outlined-basic" name="discount_percent" value={state.order.discount_percent} label="%" variant="outlined" />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <b>{t("order.totalAmount")}</b>
                                                    </TableCell>
                                                    <TableCell>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <b style={{fontSize: '30px', color: "rgb(236, 136, 0)"}}>
                                                            {Number(total(state.order.order_details) + parseFloat(state.order.extra) - parseFloat(state.order.discount)).toLocaleString()}
                                                        </b>
                                                    </TableCell>
                                                    <TableCell>
                                                    </TableCell>
                                                </TableRow>                               
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <ButtonUpdateOrder responseValidate={responseValidate} cookie={cookies.is_login} state={state}/>
                            </Grid>
                        </TabPanel>
                        <TabPanel style={{padding: '10px 0px'}} value="2">
                            <Chat 
                                messages={state.messages}
                                cookie={cookies.is_login}
                                fanpage_id={state.fanpage_id}
                                conversation_id={state.conversation_id}
                                avatar_user={state.order.avatar_user}
                            />
                        </TabPanel>
                        <TabPanel style={{padding: '10px 0px'}} value="3">
                            <TimeLineStatusOrder
                                status={state.status}
                                history_orders={state.history_orders}
                            />
                        </TabPanel>
                    </TabContext>
                </div>  
            }

        </Container>
    )
}

export default OrderDetail;