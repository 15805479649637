import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import { useForm } from "react-hook-form";
import { convertNumber } from "../../../../../Helpers/function";
import { useState } from "react";
import { useTranslation } from 'react-i18next'

export default function FormSearch(props) {
    const { t } = useTranslation();
    const {register, handleSubmit, reset, control, setValue} = useForm();
    const [state, setState] = useState({
        type_time: 'all'
    })

    const searchCustomer = (data) => {
        props.searchCustomer(data);
    }

    const refresh = () => {
        reset();
        setState({
            ...state,
            type_time: 'all'
        })
        props.refresh();
    }

    const onChangeTypeTime = (e) => {
        setState({
            ...state,
            type_time: e.target.value
        })
    }

    const date_start = new Date().getFullYear() + '-' + convertNumber(parseInt(1 + new Date().getMonth())) + '-01';
    const date_end = new Date().getFullYear() + '-' + convertNumber(parseInt(1 + new Date().getMonth())) + '-' + convertNumber(new Date().getDate());

    return (
        <form onSubmit={handleSubmit(searchCustomer)}>
            <Grid container spacing={2}>
                <Grid item xs={2} md={2}>
                    <TextField
                        id="outlined-select-currency"
                        size="small"
                        fullWidth
                        placeholder={t("customer.placeholder.find")}
                        label={t("customer.label.keyword")}
                        {...register('keyword')}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={2} md={2}>
                    <FormControl size="small" fullWidth>
                        <InputLabel id="demo-simple-select-label">{t("customer.label.selectTime")}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.type_time}
                            label={t("customer.label.selectTime")}
                            {...register('type_time')}
                            onChange={onChangeTypeTime}
                        >
                            <MenuItem value="all">
                                {t("customer.allTime")}
                            </MenuItem>
                            <MenuItem value="custom">
                                {t("customer.customize")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {
                    state.type_time == 'custom' &&
                    <Grid item xs={2} md={2}>
                        <TextField {...register('date_start')} defaultValue={date_start} fullWidth type="date" size="small" id="outlined-basic" label={t("customer.label.fromDate")} variant="outlined" />
                    </Grid>
                }
                {
                    state.type_time == 'custom' &&
                    <Grid item xs={2} md={2}>
                        <TextField {...register('date_end')} defaultValue={date_end} fullWidth type="date" size="small" id="outlined-basic" label={t('customer.label.toDate')} variant="outlined" />
                    </Grid>
                }
                <Grid item xs={2} md={2}>
                    <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        fullWidth
                        label={t("customer.label.phoneNumber")}
                        defaultValue="-1"
                        {...register('phone')}
                    >
                        <MenuItem value="-1">
                           {t("customer.allPhoneNumbers")}
                        </MenuItem>
                        <MenuItem value="1">
                            {t("customer.hasPhoneNumber")}
                        </MenuItem>
                        <MenuItem value="0">
                            {t("customer.noPhoneNumber")}
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={2}>
                    <Button sx={{marginRight: '5px'}} startIcon={<SearchIcon/>} type="submit" className="text-transform"  variant="contained">
                        {t("common.search")}
                    </Button>
                    <Button onClick={refresh} startIcon={<ReplayIcon/>} color="error" className="text-transform"  variant="contained">
                        {t("common.reload")}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}