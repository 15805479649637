import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./Locales/en/translation.json";
import translationVI from "./Locales/vi/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  vi: {
    translation: translationVI,
  },
};

const languageDetectorOptions = {
  order: ['localStorage', 'cookie', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'], // Thứ tự ưu tiên để phát hiện ngôn ngữ
  caches: ['localStorage'], // Đảm bảo ngôn ngữ được lưu vào localStorage
  // lookupQuerystring: 'lang' // Tham số query string để phát hiện ngôn ngữ (ví dụ: ?lang=en)a
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // lng: "vi",
    fallbackLng: "vi",
    debug: true,
    detection: languageDetectorOptions,
    interpolation: {
      escapeValue: false,
    },
    
  });
