import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SortByAlphaRoundedIcon from '@mui/icons-material/SortByAlphaRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import { Autocomplete, Avatar, Button, Checkbox, FormControlLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Radio, RadioGroup, TextField, Tooltip } from "@mui/material";
import { useEffect, useState } from 'react';
import api from '../../../../../../../Apis'
import Swal from 'sweetalert2'
import Time from './Time';
import TagSearch from './TagSearch';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import PhoneDisabledRoundedIcon from '@mui/icons-material/PhoneDisabledRounded';
import CommentsDisabledRoundedIcon from '@mui/icons-material/CommentsDisabledRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import MmsRoundedIcon from '@mui/icons-material/MmsRounded';
import InboxRoundedIcon from '@mui/icons-material/InboxRounded';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from "react-i18next";
/**
 * Search theo ngày thì tính hết các giá trị state, search các trường còn lại thì k lấy ngày
 * Search theo saler thì tính các giá trị sate, search các trường còn lại thì k tính saler
 * @param {*} props 
 * @returns 
 */

 const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
 const checkedIcon = <CheckBoxIcon fontSize="small" />;

function Filter(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        fanpages: [],
        limit: 20,
        filter: {
            un_read: '',
            un_rep: '',
            tags: [],
            limit: 50,
            has_phone: '',
            hasnt_phone: '',
            all: '',
            done: '',
            type_conversation: 'all',
            chat_messenger: '', // hội thoại tin nhắn
            chat_post: '', // hội thoại trong bài viết (chính là comment),
            fanpage_ids: [],
            user_id: '',
            conversation_type: 'search'
        },
    })

    const loadMoreFanpage = () => {
        const new_limit = parseInt(state.limit) + 20;
        setState({
            ...state,
            limit: new_limit
        })
    }

    useEffect(() => {
        if (props.fanpages.length > 0) {
            setState({
                ...state,
                fanpages: props.fanpages
            })
        }
    }, [props.fanpages])

    const onChangeFanpage = (value) => () => {
        if (value == -1) {
            if (state.filter.fanpage_ids.indexOf(value) !== -1) {
                var filter = {...state.filter};
                filter.fanpage_ids = []
                setState({...state, filter: filter})
            } else {
                const newChecked = [...state.filter.fanpage_ids];
                props.fanpages.map((item) => {
                    newChecked.push(item.fanpage_id);
                })
                newChecked.push(-1);
                var filter = {...state.filter};
                filter.fanpage_ids = newChecked;
                setState({...state, filter: filter})
            }
        } else {
            const currentIndex = state.filter.fanpage_ids.indexOf(value);
            const newChecked = [...state.filter.fanpage_ids];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            var filter = {...state.filter};
            filter.fanpage_ids = newChecked;
            setState({...state, filter: filter})
        }
        filter.conversation_type = 'search';
        delete filter.offset;
        filter['limit'] = props.conversation_num;
        filter["lang"] = i18n.language;
        props.loadConversation();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify(filter)
        };
        //fetch(api.filter_conversation, requestOptions)
        fetch(api.get_conversation, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    props.updateConversation(result, filter);
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
    };

    const filter = (name) => {
        var filter = {...state.filter};
        if (name == 'all') {
            filter = {                
                un_read: '',
                un_rep: '',
                tags: [],
                limit: props.conversation_num,
                has_phone: '',
                hasnt_phone: '',
                all: '',
                done: '',
                type_conversation: 'all',
                chat_messenger: '', // hội thoại tin nhắn
                chat_post: '', // hội thoại trong bài viết (chính là comment),
                fanpage_ids: [],
                user_id: '',
                conversation_type: 'not_search'
            }
            setState({
                ...state,
                filter: filter,
            })
        } else {
            filter.conversation_type = 'search';
            if (name == 'all' || name == 'comment' || name == 'messenger') {
                filter.type_conversation = name;
            } else {
                if (name == 'has_phone') {
                    filter['hasnt_phone'] = '';
                } else if (name == 'hasnt_phone') {
                    filter['has_phone'] = '';
                } 
                filter[name] = filter[name] == true ? '' : true;
            }
            filter["conversation_id"] = props.conversation_id;
        }
        delete filter.offset;
        filter['limit'] = props.conversation_num;
        filter["lang"] = i18n.language;
        setState({
            ...state,
            filter: filter
        })
        props.loadConversation();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify(filter)
        };
        //fetch(api.filter_conversation, requestOptions)
        fetch(api.get_conversation, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    props.updateConversation(result, filter);
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
    }

    const responseProps = (date_start, date_end) => {
        const filter = {
            ...state.filter,
            conversation_type: 'search',
            date_start: date_start,
            date_end: date_end
        }
        delete filter.offset;
        filter['limit'] = props.conversation_num;
        filter['lang'] = i18n.language;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            // body: JSON.stringify({
            //     date_start: date_start,
            //     date_end: date_end,
            //     un_read: state.filter.un_read,
            //     un_rep: state.filter.un_rep,
            //     tags: state.filter.tags,
            //     limit: state.filter.limit,
            // })
            body: JSON.stringify(filter)
        };
        props.loadConversation();
        //fetch(api.filter_conversation, requestOptions)
        fetch(api.get_conversation, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    props.updateConversation(result, filter);
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
    }

    const responePropsTag = (data) => {
        const filter = {...state.filter}
        filter.conversation_type = 'search';
        const new_tags = [...state.filter.tags];
        
        if (new_tags.indexOf(data) === -1) {
            new_tags.push(data);
        } else {
            new_tags.splice(new_tags.indexOf(data), 1);
        }
        filter['tags'] = new_tags;
        filter['lang'] = i18n.language;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify(filter)
        };
        setState({...state, filter: filter});
        props.loadConversation();
        //fetch(api.filter_conversation, requestOptions)
        fetch(api.get_conversation, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    props.updateConversation(result, filter);
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
    }

    const limitConversation = (e) => {
        const filter = {...state.filter};
        filter['limit'] = e.target.value;
        filter['lang'] = i18n.language;
        // filter['tags'] = state.filter.tags;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            // body: JSON.stringify({
            //     un_read: state.filter.un_read,
            //     un_rep: state.filter.un_rep,
            //     tags: state.filter.tags,
            //     limit: e.target.value,
            // })
            body: JSON.stringify(filter)
        };
        //fetch(api.filter_conversation, requestOptions)
        fetch(api.get_conversation, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        filter: filter
                    })
                    props.updateConversation(result, filter);
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
    }

    const changeSaler = (e) => {
        const filter = {...state.filter};
        delete filter.offset;
        filter['limit'] = props.conversation_num;
        filter['user_id'] = e.target.value;
        filter.conversation_type = 'search';
        filter["conversation_id"] = props.conversation_id;
        filter["lang"] = i18n.language;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify(filter)
        };
        setState({
            ...state,
            filter: filter
        })
        props.loadConversation();
        //fetch(api.filter_conversation, requestOptions)
        fetch(api.get_conversation, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        props.updateConversation(result, filter);
                    } else {
                        Swal.fire({
                            title: result.message,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            timer: 1500
                        })
                    }
                }
            )
    }

    return (
        <div className="filter">
            <ul>
                <Tooltip onClick={() => filter('all')} title={t("liveChat.filter.allMessagesComments")} arrow placement="right">
                    <li className={state.filter.type_conversation == 'all' && 'active'}>
                        <SortByAlphaRoundedIcon/>
                    </li>
                </Tooltip>
                <Tooltip arrow onClick={() => filter('messenger')} name="messenger" title={t("liveChat.filter.filterMessageConversations")} placement="right">
                    <li className={state.filter.type_conversation == 'messenger' && 'active'}>
                        <InboxRoundedIcon name="messenger"/>
                    </li>
                </Tooltip>
                <Tooltip arrow onClick={() => filter('comment')} name="comment" title={t("liveChat.filter.filterCommentConversations")} placement="right">
                    <li name="comment" className={state.filter.type_conversation == 'comment' && 'active'}>
                        <MmsRoundedIcon name="comment"/>
                    </li>
                </Tooltip>
                <Tooltip title={t("liveChat.filter.selectFanpage")} placement="top" arrow>
                    <li>
                        <FacebookRoundedIcon/>
                        <div className='list-saler' style={{width: '290px'}}>
                            <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <ListItem
                                    secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onChangeFanpage(-1)}
                                        checked={state.filter.fanpage_ids.indexOf(-1) !== -1}
                                    />
                                    }
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemText primary={t("common.all")}/>
                                    </ListItemButton>
                                </ListItem>
                                {state.fanpages.slice(0, state.limit).map((value) => {
                                    return (
                                        <ListItem
                                            key={value.fanpage_id}
                                            secondaryAction={
                                            <Checkbox
                                                edge="end"
                                                onChange={onChangeFanpage(value.fanpage_id)}
                                                checked={state.filter.fanpage_ids.indexOf(value.fanpage_id) !== -1}
                                            />
                                            }
                                            disablePadding
                                        >
                                            <ListItemButton>
                                            <ListItemAvatar>
                                                <Avatar
                                                alt={value.name}
                                                src={value.image}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={value.name} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                            {
                                state.fanpages.length > state.limit &&
                                <center>
                                    <Button onClick={loadMoreFanpage} variant='outlined'>
                                        {t("common.seeMore")}
                                    </Button>
                                </center>
                            }
                        </div>
                    </li>
                </Tooltip>
                <Tooltip arrow onClick={() => filter('un_read')} title={t("liveChat.filter.filterUnreadConversations")} placement="right">
                    <li className={state.filter.un_read == true && 'active'}>
                        <VisibilityOffRoundedIcon/>
                    </li>
                </Tooltip>
                <Tooltip arrow title={t("liveChat.filter.filterByTime")} placement="top">
                    <li>
                        <EventAvailableRoundedIcon/>
                        <Time responseProps={responseProps}/> 
                    </li>
                </Tooltip>
                <Tooltip arrow onClick={() => filter('done')} title={t("liveChat.filter.filterCompletedConversations")} placement="right">
                    <li className={state.filter.done == true && 'active'}>
                        <CheckRoundedIcon name="done"/>
                    </li>
                </Tooltip>
                <Tooltip  title={t("liveChat.filter.filterBySale")} placement="top" arrow>
                    <li>
                        <PersonRoundedIcon/>
                        <div className='list-saler' style={{width: '290px'}}>
                            {/* <Autocomplete
                                multiple
                                helperText={state.error?.fanpages?.message}
                                id="checkboxes-tags-demo"
                                onChange={changeSaler}
                                options={ props.employees }
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.full_name}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    { option.full_name }
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField error={state.error?.fanpages?.status} helperText={state.error?.fanpages?.message} {...params} size="small" label="Chọn nhân viên *" />
                                )}
                            /> */}
                            <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    style={{padding: "0px 20px"}}
                                    onChange={changeSaler}
                                >
                                    <FormControlLabel value="-1" control={<Radio />} label={t("common.all")} />
                                {
                                    props.employees.map((employee, key) => {
                                        return (
                                            <FormControlLabel value={employee.user_id} control={<Radio />} label={employee.full_name} />
                                        );
                                    })
                                }
                                </RadioGroup>
                            </List>
                        </div>
                    </li>
                </Tooltip>
                {/* <Tooltip  title="Hiển thị" placement="top" arrow>
                    <li>
                        <FindInPageRoundedIcon/>
                        <div className='list-saler'>
                            <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    style={{padding: "0px 20px"}}
                                    onChange={limitConversation}
                                    value={state.filter.limit}
                                >
                                {[20, 50, 100, 300, 500, 600].map((value) => {
                                    const labelId = `checkbox-list-secondary-label-${value}`;
                                    return (
                                        <FormControlLabel value={value} control={<Radio />} label={value == 600 ? 'Tất cả' : value + ' hội thoại'} />
                                    );
                                })}
                                </RadioGroup>
                            </List>
                        </div>
                    </li>
                </Tooltip> */}
                <Tooltip  title={t("liveChat.filter.filterByTag")} placement="top" arrow>
                    <li>
                        <LocalOfferRoundedIcon/>
                        <TagSearch filter={state.filter} responePropsTag={responePropsTag} tags={props.tags}/>
                    </li>
                </Tooltip>
                <Tooltip onClick={() => filter('un_rep')} arrow name="un_rep" title={t("liveChat.filter.filterUnansweredConversations")} placement="right">
                    <li className={state.filter.un_rep == true && 'active'}>
                        <CommentsDisabledRoundedIcon/>
                    </li>
                </Tooltip>
                <Tooltip onClick={() => filter('has_phone')} arrow title={t("liveChat.filter.filterConversationsWithPhoneNumber")} placement="right">
                    <li className={state.filter.has_phone == true && 'active'}>
                        <LocalPhoneRoundedIcon />
                    </li>
                </Tooltip>
                <Tooltip onClick={() => filter('hasnt_phone')} arrow title={t("liveChat.filter.filterConversationsWithoutPhoneNumber")} placement="right">
                    <li className={state.filter.hasnt_phone == true && 'active'}>
                        <PhoneDisabledRoundedIcon/>
                    </li>
                </Tooltip>
            </ul>
        </div>
    )
}

export default Filter;