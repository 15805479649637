const initState = {
    loading: false,
}
const loadingReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOADING':
            return action.payload; 
        default:
            return state;
    }
}

export default loadingReducer;