var pusherConfig = {
    dev: {
        key: "599c142c597b726c859e",
        app_id: "1346454",
        secret: "33fea44b328d4708c9e1",
        cluster: "ap1"
    },
    pro: {
        app_id: "1346456",
        key: "574215790bec8d7eecb7",
        secret: "cc9cc9ce58e3f4a78eb7",
        cluster: "ap1"
    }
}

export default pusherConfig;
