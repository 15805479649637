import { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";
import api from "./../../../Apis";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation, Trans } from "react-i18next";
function AvatarProfile(props) {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["is_login"]);

  const logout = (type) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies.is_login,
      },
      body: JSON.stringify({
        lang: i18n.language,
      }),
    };
    fetch(type == "delete" ? api.delete_account : api.logout, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        const beamsClient = new PusherPushNotifications.Client({
          instanceId: process.env.REACT_APP_PUSHER_INSTANCEID,
        });
        beamsClient
          .start()
          .then(() =>
            beamsClient.removeDeviceInterest("shop-" + result.shop_id)
          )
          .catch((e) => console.error("Could not remove device interest", e));
        if (result.success) {
          removeCookie("is_login", { path: "/", domain: "." + result.domain });
          removeCookie("is_login", { path: "/" });

          return navigate("/");
        } else {
          Swal.fire({
            title: result.message,
            timer: 2000,
            icon: "error",
          });
        }
      });
  };

  return (
    <div style={{ marginLeft: "0px" }}>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="">
          <IconButton
            style={{ marginLeft: "0px" }}
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              <img src={props.user_info.user.avatar}></img>
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={logout}>
					<ListItemIcon>
						<CreditCardRoundedIcon fontSize="small" />
					</ListItemIcon>
					Số dư: {Number(props.user_info.user.balance).toLocaleString()} vnđ
				</MenuItem>
				<MenuItem>
					<ListItemIcon>
						<AddRoundedIcon fontSize="small" />
					</ListItemIcon>
					<Link to="/dashboard/bank" className="a-none">
						Nạp tiền
					</Link>
				</MenuItem> */}
        <MenuItem onClick={() => logout("logout")}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("header.user.logOut")}
        </MenuItem>
        {/* <MenuItem onClick={() => logout('delete')}>
					<ListItemIcon>
						<DeleteIcon fontSize="small" />
					</ListItemIcon>
					{t('header.user.deleteAccount')}
				</MenuItem> */}
      </Menu>
    </div>
  );
}

export default AvatarProfile;
