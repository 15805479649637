import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import { useState } from "react"
import parse from 'html-react-parser';
export default function Notification(props) {
    const [state, setState] = useState({
        open_dialog: true
    })

    const closeDialog = () => {
        if (localStorage.getItem("noti_ids") != null) {
            const list_id_noti = JSON.parse(localStorage.getItem("noti_ids"));
            list_id_noti.push(props.notification.noti_id);
            localStorage.setItem("noti_ids", JSON.stringify(list_id_noti));
        } else {
            localStorage.setItem("noti_ids", JSON.stringify([props.notification.noti_id]));
        }
        setState({
            ...state,
            open_dialog: false
        })
    }

    return (
        <Dialog open={state.open_dialog} fullWidth="true" maxWidth='sm' onClose={closeDialog}>
            <DialogTitle>
                {props.notification?.title}
            </DialogTitle>
            <DialogContent>
                {
                    parse(props.notification?.content)
                }
            </DialogContent>
        </Dialog>
    )
}