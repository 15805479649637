import { useEffect, useState } from "react";
import api from './../../../../../../../Apis'
import { useCookies } from "react-cookie";
import { 
    checkTagExistInArray, 
    checkDateExistInArray 
} from "./../../../../../../../Helpers/function"
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { Button, Menu, MenuItem } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTranslation } from "react-i18next";

function Tag(props) {
    const { i18n } = useTranslation();
    const [state, setState] = useState({
        tag_active: '',
        conversation_tag: null
    });
    const [cookies, setCookie] = useCookies('is_login');
    useEffect(() => {
        setState({...state, conversation_tag: null});
    }, [props.conversation_id])
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const addTag = (tag) => {
        if (props.conversation_id != '') {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + cookies.is_login
                },
                body: JSON.stringify({
                    conversation_id: props.conversation_id,
                    tag_id: tag.tag_id,
                    fanpage_id: props.fanpage_id,
                    lang: i18n.language
                })
            };
            fetch(api.update_activity, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success == true) {
                        setState({...state, conversation_tag: result.datas});
                        props.update_tag(result.tags);
                    }
                }
            )
        }
    }
    return (
        <div className='tag'>
            {
                props.tags.length > 0 &&
                props.tags.slice(0, 6).map((value, key) => {
                    // const class_active = checkTagExistInArray(value.tag_id, state.conversation_tag == null ? props.conversation_tag : state.conversation_tag) == true && checkDateExistInArray(date, state.conversation_tag == null ? props.conversation_tag : state.conversation_tag) == true ? 'order-tag-active' : '';
                    if (state.conversation_tag == null) {
                        var class_active = props.conversation_tag.includes(value.tag_id) == true ? 'order-tag-active' : '';
                    } else {
                        var class_active = state.conversation_tag.includes(value.tag_id) == true ? 'order-tag-active'  : '';
                    }
                    return (
                        <span onClick={() => addTag(value)} style={{background: value.color}} className={class_active + ' order-tag'}>
                            {value.name}
                        </span>
                    )
                })   
            }
            {
                props.tags.length > 6 &&
                // <span>
                //     <MoreHorizRoundedIcon/>
                // </span>
                <Button
                    sx={{color: 'gray', minWidth: '40px'}}
                    startIcon={<MoreHorizRoundedIcon/>}
                    onClick={handleClick}
                    id="fade-button"
                    //aria-controls={open2 ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    //aria-expanded={open2 ? 'true' : undefined}
                    //onClick={handleClick2}
                    className='text-transform more-tag'
                >
                </Button>
            }
            {
                props.tags.length > 6 &&
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {
                        props.tags.slice(6).map((value, key) => {
                            return (
                                <MenuItem onClick={() => addTag(value)}>
                                    <FiberManualRecordIcon sx={{ color: value.color, fontSize: '15px', marginRight: '5px'}} size="small" /> {value.name}
                                </MenuItem>
                            )
                        })
                    }
                </Menu>
            }
            {
                <a target="_blank" href="/dashboard/setting?value=4">
                    <Button
                        sx={{color: 'gray', minWidth: '40px'}}
                        startIcon={<AddRoundedIcon/>}
                        id="fade-button"
                        //aria-controls={open2 ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        //aria-expanded={open2 ? 'true' : undefined}
                        //onClick={handleClick2}
                        className='text-transform more-tag'
                    >
                    </Button>
                </a>
            }
        </div>
    )
}

export default Tag;