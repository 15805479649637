import { Autocomplete, Avatar, Backdrop, Button, Chip, CircularProgress, Container, Grid, MenuItem, Pagination, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from './../../../../Apis'
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { convertNumber, requestOptions } from "../../../../Helpers/function";
import { Controller } from "react-hook-form";
import { Box } from "@mui/system";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CustomerOld from "./Components/CustomerOld";
import SendMessage from "./Components/SendMessage";
import ListCampaign from "./Components/ListCampaign";
import { ListFanpage } from "./Components/ListFanpage";
import FormSearch from "./Components/FormSearch";
import { useTranslation, Trans } from "react-i18next"

function Index() {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        loading: true,
        customers: [],
        page: 1,
        total_page: 1,
        total_customer_search: 0,
        total_customer_all: 0,
        list_fanpage: [],
        type_search: '',
        tab_active: '1',
        fanpage_id_selected: '',
        owner_id: '',
        max_customers: {},
        package_use: '',
        fanpage_active: {},
        loading_page: true,
        blocks: []
    })
    const {register, handleSubmit, reset, control} = useForm();
    const [cookies, setCookie] = useCookies('is_login');
    const { fanpage_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        callApi({page: state.page, fanpage_id: fanpage_id});
    }, [fanpage_id])

    const refresh = () => {
        reset();
        setState({
            ...state,
            loading: true
        })
        callApi({page: 1, fanpage_id: fanpage_id});
    }

    const callApi = (data) => {
        data['type_search'] = state.type_search;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({...data, lang: i18n.language})
        };
        fetch(api.list_customer, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        loading: false,
                        total_page: result.total_page,
                        customers: result.customers,
                        total_customer_search: result.total_customer,
                        total_customer_all: result.total_customer,
                        page: data.page,
                        list_fanpage: result.list_fanpage,
                        owner_id: result.owner_id,
                        max_customers: result.max_customers,
                        package_use: result.package_use,
                        fanpage_active: result.fanpage_active,
                        loading_page: false,
                        blocks: result.blocks
                    })
                } else {
                    setState({
                        ...state,
                        loading: false,
                    })
                }
            }
        )
    }

    const getCustomerBySearch = (body) => {
        fetch(api.list_customer, requestOptions('POST', cookies.is_login, {...body, lang: i18n.language}))
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        customers: result.customers,
                        fanpage_id_selected: result.fanpage_id_selected,
                        total_page: result.total_page,
                        total_customer_search: result.total_customer
                    })
                }
            }
        )
    }

    const handleChangePage = (e, new_page) => {
        const body = {
            page: new_page,
            type_search: 'search',
            fanpage_id: state.fanpage_id_selected
        }
        setState({
            ...state,
            loading: true,
            page: new_page,
        })
        getCustomerBySearch(body);
    }

    const searchCustomer = (data) => {
        setState({
            ...state,
            loading: true
        })
        data['fanpage_id'] = fanpage_id;
        data['page'] = 1;
        data['type_search'] = 'search';
        getCustomerBySearch(data);
    }

    const handleChangeTab = (event, newValue) => {
        setState({
            ...state,
            tab_active: newValue
        })
    }

    const redirectToFanpage = (fanpageId) => {
        if (fanpage_id != fanpageId) {
            let route = '/dashboard/' + fanpageId + '/customer';
            setState({
                ...state,
                loading_page: true
            })
    
            return navigate(route);
        }
    }

    return (
        <Container maxWidth="xl" className='m-t-40'>
            {
                state.loading_page
                &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={state.loading_page}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            <Grid container spacing={2}>
                <Grid item xs={3} md={3}>
                    <ListFanpage
                        fanpage_active={state.fanpage_active}
                        list_fanpage={state.list_fanpage}
                        redirectToFanpage={redirectToFanpage}
                        // control={control}
                    />
                    {/* <Controller
                        render={(props) => (
                            <Autocomplete
                            onChange={(e, value) => {
                                props.field.onChange(value.fanpage_id);
                            }}
                            size="small"
                            {...props}
                            value={state.fanpage_active}
                            options={state.list_fanpage}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Chọn Fanpage *"
                                variant="outlined"
                                />
                            )}
                            />
                        )}
                        name="fanpage_id"
                        control={control}
                    /> */}
                </Grid>
            </Grid>
            <br></br>
            <TabContext value={state.tab_active}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                        <Tab label={t("customer.tabs.tabTitle1")} value="1" />
                        <Tab label={t("customer.tabs.tabTitle2")} value="2" />
                        <Tab label={t("customer.tabs.tabTitle3")} value="3" />
                        <Tab label={t("customer.tabs.tabTitle4")} value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    {/* <Container maxWidth="xl" className='m-t-40'> */}
                        <h3>{t("customer.customerList")}</h3>
                        <FormSearch
                            searchCustomer={searchCustomer}
                            refresh={refresh}
                        />
                        <br></br>
                        <Grid item xs={12} md={12}>
                            <p>
                                {/* Tổng: <b>{Number(state.total_customer_search).toLocaleString()} khách hàng</b> */}
                                <Trans
                                    i18nKey={"customer.totalCustomers"}
                                    values={{
                                        totalCustomers: Number(state.total_customer_search).toLocaleString()
                                    }}
                                    components={{ b: <b /> }}
                                />
                            </p>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{whiteSpace: 'nowrap'}}>
                                            <TableCell>{t("customer.table.no")}</TableCell>
                                            <TableCell>{t("customer.table.name")}</TableCell>
                                            <TableCell>{t("customer.table.phone")}</TableCell>
                                            <TableCell>{t("customer.table.address")}</TableCell>
                                            {/* <TableCell>Fanpage</TableCell> */}
                                            {/* <TableCell>Nguồn</TableCell> */}
                                            <TableCell>{t("customer.table.lastMessage")}</TableCell>
                                            <TableCell sx={{textAlign: 'center !important'}}>{t("customer.table.action.title")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            state.loading
                                            ?
                                                <TableRow>
                                                    <TableCell colSpan={8} align="center">
                                                        <CircularProgress />
                                                    </TableCell>
                                                </TableRow>
                                            :
                                            state.customers.length > 0
                                            ?
                                                state.customers.map((value, key) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell>
                                                                { (state.page - 1) * 20 + key + 1 }
                                                            </TableCell>
                                                            <TableCell>
                                                                <Chip
                                                                    avatar={<Avatar alt={value.name} src={value.picture} />}
                                                                    label={value.name}
                                                                    variant="outlined"
                                                                />
                                                            </TableCell>
                                                            <TableCell>{value.phone}</TableCell>
                                                            <TableCell>{value.address}</TableCell>
                                                            {/* <TableCell>
                                                                <p className="display-flex align-item">
                                                                    <Avatar sx={{marginRight: '10px'}} alt={value.fanpage.name} src={value.fanpage.picture} />
                                                                    <b>{value.fanpage.name}</b>
                                                                </p>
                                                            </TableCell> */}
                                                            {/* <TableCell>{value.origin}</TableCell> */}
                                                            <TableCell>{value.last_time_reply}</TableCell>
                                                            <TableCell align="center">
                                                                <Link to={'/dashboard/live-chat?user_id=' + value.conversation_id} >
                                                                    <Tooltip arrow placement="top" title={t("tooltop.chat")}>
                                                                            <ChatRoundedIcon color="primary" className="cursor" sx={{fontSize: '20px'}}/>
                                                                    </Tooltip>
                                                                </Link>
                                                                
                                                            </TableCell>
                                                        </TableRow> 
                                                    )
                                                })
                                            :
                                            <TableRow>
                                                <TableCell colSpan={8} align="center">
                                                    <i>{t("common.message.emptyData")}</i>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <br></br>
                        {
                            state.total_page > 1 &&
                            <Grid item xs={12} md={12} className="display-flex justify-content-center">
                                <Stack spacing={2}>
                                    <Pagination
                                        onChange={handleChangePage}
                                        color="primary"
                                        count={state.total_page}
                                        showFirstButton
                                        showLastButton
                                    />
                                </Stack>
                            </Grid>
                        }
                        <br></br>
                        <br></br>
                    {/* </Container> */}
                </TabPanel>
                <TabPanel value="2">
                    <CustomerOld
                        fanpage_id={fanpage_id}
                        cookie={cookies.is_login}
                        owner_id={state.owner_id}
                        total_customer_all={state.total_customer_all}
                        max_customers={state.max_customers}
                        package_use={state.package_use}
                    />
                </TabPanel>
                <TabPanel value="3">
                    <SendMessage
                        fanpage_id={fanpage_id}
                        blocks={state.blocks}
                        cookie={cookies.is_login}
                    />
                </TabPanel>
                <TabPanel value="4">
                    <ListCampaign
                    fanpage_id={fanpage_id}
                    cookie={cookies.is_login}
                    />
                </TabPanel>
            </TabContext>
        </Container>
    )
}

export default Index;