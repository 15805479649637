const initState = {
    name: '',
    username: '',
    email: ''
}
const userReducer = (state = initState, action) => {
    switch (action.type) {
        case 'PROFILE':
            //const newUsers = {...state, action.payload};
            // newUsers.push(action.payload);
            // const new_obj = {
            //     ...state,
            //     email: action.payload.email,
            //     name: action.payload.name,
            //     username: action.payload.username
            // };
            return action.payload;
            // return {
            //     ...state,
            //     users: newUsers
            // };    
        default:
            return state;
    }
}

export default userReducer;