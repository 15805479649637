import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

function Attachment(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        img: '',
        open_dialog: false
    })

    const renderTagAttachment = (attachment) => {
        if (attachment.image_data != null) {
            return (
                <img className='crosshair' onClick={() => setState({...state, open_dialog: true, img: attachment.image_data})} src={attachment.image_data}></img>
            )
        } else if (attachment.file_url != null) {
            return (
                <a style={{float: 'right', color: '#000'}} href={attachment.file_url} target="_blank">
                    {t("liveChat.conversation.attachedFile")}
                </a>
            )
        } else if (attachment.video_data != null) {
            return (
                <video width="320" controls>
                    <source src={attachment.video_data} type="video/mp4"></source>
                </video>
            )
        }
    }

    const renderDivAttachment = () => {
        if (props.attachments.length > 0) {
            return (
                <div className={props.position_mess + ' clear image'}>
                    <div className='mess-text' style={{width: '65%', maxWidth: 'unset'}}>
                        {
                            props.attachments.map((attachmentItem, key) => {
                                return (
                                    <div>
                                        {renderTagAttachment(attachmentItem)}
                                        {/* <img onClick={() => setState({...state, open_dialog: true, img: attachmentItem.image_data})} src={attachmentItem.image_data}></img> */}
                                        {
                                            (attachmentItem.buttons != undefined && Array.isArray(attachmentItem.buttons) && attachmentItem.buttons.length > 0) &&
                                            <ul className='list-button' style={{background: '#0084ff', padding: '20px', borderRadius: '10px'}}>
                                                {
                                                    attachmentItem.buttons.map((buttonItem, key_button) => {
                                                        return (
                                                            <li>
                                                                {buttonItem.title} <br></br>
                                                            </li>  
                                                        )
                                                    })
                                                }
                                            </ul>
                                        }
                                        {
                                            (attachmentItem.elements != undefined && Array.isArray(attachmentItem.elements) && attachmentItem.elements.length > 0) &&
                                            <div className='block' style={{overflowX: 'scroll', whiteSpace: 'nowrap'}}>
                                                {
                                                    attachmentItem.elements.map((genericItem) => {
                                                        return (
                                                            <div className='message-item' style={{width: '50%', marginRight: '20px', display: 'inline-block'}}>
                                                                <div>
                                                                    <img src={genericItem.image_url} style={{width: '100%', height: '150px', float: 'unset'}}></img><br></br>
                                                                    <b>{genericItem.title}</b><br></br>
                                                                    <small>{genericItem.subtitle}</small><br></br>
                                                                    {
                                                                        (genericItem.buttons != undefined && Array.isArray(genericItem.buttons) && genericItem.buttons.length > 0) &&
                                                                        <ul className='list-button'>
                                                                            {
                                                                                genericItem.buttons.map((generic_button) => {
                                                                                    return (
                                                                                        <li>
                                                                                            {generic_button.title} <br></br>
                                                                                        </li>  
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    }
                                                                </div>
                                                            </div>  
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        {
                                            !props.sending &&
                                            <CheckCircleRoundedIcon className="check-send check-send-attachment"/>
                                            // <CircularProgress size={13} sx={{marginLeft: 1}}></CircularProgress>
                                        }
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                </div>
            )
        } else if (props.attachment.file_url != null) {
            return (
                <div className={props.position_mess + ' clear'}>
                    <div className='mess-text'>
                        <a href={props.attachment.file_url.link} target="_blank">
                            {props.attachment.file_url.name}
                        </a>
                    </div>
                </div>
            )
        } else if (props.attachment.video_data != null) {
            return (
                <div className={props.position_mess + ' clear video'}>
                    <div className='mess-text'>
                        <video width="320" controls>
                            <source src={props.attachment.video_data} type="video/mp4"></source>
                        </video>
                    </div>
                </div>
            )
        }
    }

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;
      
        return (
          <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: "#fff",
                  background: "gray"
                }}
              >
                <CloseIcon/>
              </IconButton>
            ) : null}
          </DialogTitle>
        );
      };

    return (
        <div>
            <Dialog
                onClose={() => setState({...state, open_dialog: false, img: ''})}
                open={state.open_dialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth={true}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setState({...state, open_dialog: false})}>
                </BootstrapDialogTitle>
                <DialogContent>
                    <DialogContentText className="t-center" id="alert-dialog-description">
                        <img src={state.img}></img>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {renderDivAttachment()}
        </div>
    )
    
}

export default Attachment;