import api from "./../Apis";

export const checkTagExistInArray = (tag_id, array) => {
    return array.some(function(el) {
        return el.tag_id === tag_id;
    }); 
}

export const checkDateExistInArray = (date, array) => {
    return array.some(function(el) {
        return el.date === date;
    }); 
}

export const check = (date, array) => {
    return array.some(function(el) {
        return el.date === date;
    }); 
}

export const convertNumber = (number) => {
    if (number < 10) {
        return '0' + number;
    }

    return number;
}

export const debounce = (fn, delay) => {
    var timeoutID = null
    return function () {
      clearTimeout(timeoutID)
      var args = arguments
      var that = this
      timeoutID = setTimeout(function () {
        fn.apply(that, args)
      }, delay)
    }
}

export const find_phone_number = (text) => {
    if (text != '' && text != null) {
        var result = text.match(/[\+]?\d{10}|\(\d{3}\)\s?-\d{6}/gm);
        if (Array.isArray(result)) {
            return result;
        }
    
        return null;
    }

    return null;
}

export const replaceArrayItemInString = (string, array_find) => {
    for (var i = 0; i < array_find.length; i++) 
        string = string.replace(array_find[i], "<span>" + array_find[i] + "</span>");

    return string;
}

export const getAddress = (transport_id, cookie, url_api, province_id, district_id, lang) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookie
        },
        body: JSON.stringify({
            transport_id: transport_id,
            province_id: province_id,
            district_id: district_id,
            lang: lang
        })
    };
    return fetch(url_api, requestOptions)
    .then(res => res.json())
    .then((result) => {
        if (result.success) {
            return result.datas;
        } else {
            return [];
        } 
    });
}

export const searchStringInArray = (string, strArray) => {
    for (var j = 0; j < strArray.length; j++) {
        if (string != '') {
            if (strArray[j]['keyword'].match(string)) return j;
        }
    }

    return -1;
}

export const search = (string, strArray, index) => {
    const new_array = [];
    const new_string = removeVietnameseTones(string.toLowerCase());
    for (var j = 0; j < strArray.length; j++) {
        if (new_string != '') {
            if (removeVietnameseTones(strArray[j][index].toLowerCase()).match(new_string)) {
                new_array.push(strArray[j]);
            }
        }
    }

    return new_array;
}

export const removeVietnameseTones = (str) => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
    str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
    str = str.replace(/đ/g,"d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g," ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");

    return str;
}

export const requestOptions = (method, cookie, body) => {
    const obj = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookie
        }
    };
    if (body != null) {
        obj['body'] = JSON.stringify(body);
    }

    return obj;
}

export const domain_origin = 'https://app.' + process.env.REACT_APP_DOMAIN;