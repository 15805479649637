import { useEffect, useState, useRef } from "react";
import Pusher from "pusher-js";
import api from "./../../../../../../Apis";
import {
  Avatar,
  Badge,
  Button,
  Chip,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ring from "./../../../../../../ring.mp3";
import ring_message from "./../../../../../../ring_message.mp3";
import { convertNumber } from "./../../../../../../Helpers/function.js";
import Filter from "./Components/Filter";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../../../../../../Store/Actions/MessageAction";
import { requestOptions } from "../../../../../../Helpers/function";
import { loading } from "../../../../../../Store/Actions/Loading";
import LoadMoreConversation from "./Components/LoadMoreConversation";
import { getMessSample } from "../../../../../../Store/Actions/GetMessSample.js";
import { useTranslation } from "react-i18next";

function Index(props) {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    conversations: [],
    conversation_id: "",
    tags: [],
    mess_samples: [],
    fanpage_name: "",
    picture_fanpage: "",
    conversation_tags: {},
    loading: true,
    products: [],
    customer: {
      name: "",
      phone: "",
      province_id: "",
      district_id: "",
      ward_id: "",
      address: "",
    },
    employees: [],
    payment_methods: [],
    transports: [],
    filter_search: {},
  });
  const dispatch = useDispatch();
  /**
   * Sắp xếp thời gian gần nhất lên đầu
   */
  const compare = (a, b) => {
    const time_a = new Date(a.updated_at);
    const mili_time_a = time_a.getTime();
    const time_b = new Date(b.updated_at);
    const mili_time_b = time_b.getTime();
    if (mili_time_a < mili_time_b) {
      return 1;
    }
    if (mili_time_a > mili_time_b) {
      return -1;
    }
    return 0;
  };
  const data_redux = useSelector((state) => state.message);
  const data_loading_redux = useSelector((state) => state.loading);

  useEffect(() => {
    if (
      data_redux.new_conversation != undefined &&
      data_redux.new_conversation != ""
    ) {
      const res = data_redux.new_conversation;
      /**
       * Nếu có cuộc trò chuyện mới thì cho lên đầu
       * Ngược lại thì sắp xếp theo time để thứ tự hiển thị cái mới nhất lên đầu
       */
      if (res.status_new_conversation == true) {
        setState((oldState) => {
          var new_item = res.conversation_tags;
          return {
            ...oldState,
            conversations: [res.new_conversation, ...oldState.conversations],
            conversation_tags: { new_item, ...oldState.conversation_tags },
          };
        });
        if (res.new_conversation.sender_chat != props.fanpage_id) {
          if (res.new_conversation.type) {
            new Audio(ring).play();
          } else {
            new Audio(ring_message).play();
          }
        }
      } else {
        setState((oldState) => {
          let items = [...oldState.conversations];
          var index = -1;
          if (items.length > 0) {
            for (var i = 0; i < items.length; i++) {
              if (
                items[i].conversation_id == res.new_conversation.conversation_id
              ) {
                var index = i;
                break;
              }
            }
          }

          if (index >= 0) {
            // 2. Make a shallow copy of the item you want to mutate
            let item = { ...items[index] };
            // 3. Replace the property you're intested in
            item.last_message = res.new_conversation.last_message;
            item.updated_at = res.new_conversation.updated_at;
            item.status_read = res.new_conversation.status_read;
            // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
            items[index] = item;

            return { ...oldState, conversations: items.sort(compare) };
          } else {
            var new_item = res.conversation_tags;
            return {
              ...oldState,
              conversations: [res.new_conversation, ...oldState.conversations],
              conversation_tags: { new_item, ...oldState.conversation_tags },
            };
          }
        });
        if (res.new_conversation.sender_chat != props.fanpage_id) {
          if (res.new_conversation.type) {
            new Audio(ring).play();
          } else {
            new Audio(ring_message).play();
          }
        }
      }
    }
  }, [data_redux.new_conversation]);

  useEffect(() => {
    setState({
      ...state,
      conversations: props.data_api?.datas,
      picture_fanpage: props.data_api?.picture_fanpage,
      tags: props.data_api?.tags,
      mess_samples: props.data_api?.messs_samples,
      fanpage_name: props.data_api?.fanpage_name,
      conversation_tags: props.data_api?.conversation_tags,
      loading: false,
      products: props.data_api?.products?.success
        ? props.data_api?.products?.datas
        : [],
      employees: props.data_api?.employees,
      payment_methods: props.data_api?.payment_methods,
      transports: props.data_api?.transports,
    });
  }, [props.data_api]);

  useEffect(() => {
    if (props.conversation_id in props.tag_actions) {
      let items = { ...state.conversation_tags };
      items[props.conversation_id] = Object.values(props.tag_actions)[0];
      setState({ ...state, conversation_tags: items });
    } else {
      let items = { ...state.conversation_tags };
      items[props.conversation_id] = [];
      setState({ ...state, conversation_tags: items });
    }
  }, [props.tag_actions]);

  useEffect(() => {
    if (
      props.status_done_conversation == true ||
      props.status_done_conversation == false
    ) {
      const copy_conversation = [...state.conversations];
      copy_conversation.splice(props.stt_conversation, 1);
      setState({
        ...state,
        conversations: copy_conversation,
      });
    }
  }, [props.status_done_conversation]);
  const ref = useRef();
  // useEffect(() => {
  //     const div = ref.current;
  //     div.addEventListener('scroll', () => {
  //         const element = document.getElementById('conversation');
  //         console.log(parseInt(element.offsetHeight), parseInt(element.scrollTop), element.scrollHeight);
  //         if (parseInt(element.offsetHeight) + parseInt(element.scrollTop) >= element.scrollHeight) {
  //             //if (state.conversations.length >= props.conversation_num) {
  //                 load_more_conversation();
  //                 const action = loading({
  //                     loading: true
  //                 });
  //                 dispatch(action);
  //             //}
  //         }
  //       });
  // }, [])

  const loadMoreConversation = (result) => {
    const new_conversation = [...state.conversations];
    const new_conversation_tags = result.conversation_tags;
    setState({
      ...state,
      conversations: new_conversation.concat(result.datas),
      conversation_tags: {
        ...state.conversation_tags,
        ...new_conversation_tags,
      },
    });
  };

  const load_more_data = () => {};

  useEffect(() => {
    // 1. Make a shallow copy of the items
    let items = [...state.conversations];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...items[props.stt_conversation] };
    // 3. Replace the property you're intested in
    item.status_read = 0;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[props.stt_conversation] = item;
    // 5. Set the state to our new copy
    setState({ ...state, conversations: items });
  }, [props.status_read]);

  const detailMessage = (data, stt_conversation) => {
    data.customer["province"] = data.province;
    data.customer["district"] = data.district;
    data.customer["ward"] = data.ward;
    /**
     * Bắn vào redux để thay đổi conversation_id phục vụ cho việc webhook bắn về để map với conversation
     */
    const action = message({
      message: "",
      conversation_id: data.conversation_id,
      new_conversation: "",
    });
    const actionMessSample = getMessSample({}); // để khi mở popup ảnh lên k bị selected nữa
    dispatch(actionMessSample);
    dispatch(action);
    /**
     * end
     */
    props.detailMessage(data, stt_conversation, state);
    if (data.status_read == 0) {
      setState((oldState) => {
        let old_conversations = [
          ...new Map(
            oldState.conversations.map((item) => [
              item["conversation_id"],
              item,
            ])
          ).values(),
        ];
        old_conversations[stt_conversation]["status_read"] = 1;
        return {
          ...oldState,
          conversations: old_conversations,
        };
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setState({ ...state, loading: true });
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + props.cookie,
        },
        body: JSON.stringify({
          fanpage_ids: [],
          key: e.target.value,
          conversation_type: "search",
          lang: i18n.language,
        }),
      };
      fetch(api.get_conversation, requestOptions)
        .then((res) => res.json())
        .then((result) => {
          if (result.success == true) {
            setState({
              ...state,
              loading: false,
              conversations: result.datas,
            });
          } else {
            setState({
              ...state,
              loading: false,
            });
          }
        });
    }
  };

  const loadConversation = () => {
    setState({
      ...state,
      loading: true,
    });
  };

  const updateConversation = (data, filter_search) => {
    //setState({...state, loading: true});
    setTimeout(() => {
      if (!data.conversation_is_active) {
        props.updateConversationId(); // nếu kết quả tìm kiếm không có conversation hiện tại thì block chat và block tạo đơn ẩn đi
      }
      setState({
        ...state,
        conversations: data.datas,
        conversation_tags: data.conversation_tags,
        filter_search: filter_search,
        loading: false,
      });
    }, 200);
  };
  /**
   * Lấy những conversation unique dựa vào trường conversation_id, do pusher thi thoảng bắn double
   */
  const list_conversation = [
    ...new Map(
      state.conversations.map((item) => [item["conversation_id"], item])
    ).values(),
  ];
  console.log(props.conversation_id, "chinh1997");
  return (
    <div className="list-user">
      <Filter
        loadConversation={loadConversation}
        employees={state.employees}
        conversation_id={props.conversation_id}
        tags={state.tags}
        updateConversation={updateConversation}
        cookie={props.cookie}
        conversation_num={props.conversation_num}
        fanpage_id={props.fanpage_id}
        fanpages={
          props.data_api.fanpages != undefined ? props.data_api.fanpages : []
        }
      />
      <div style={{ width: "90%" }}>
        <div className="search">
          <input
            onKeyDown={handleKeyDown}
            placeholder={t("liveChat.enterNamePhoneEnter")}
          ></input>
        </div>
        <div className="conversation" id="conversation" ref={ref}>
          {state.loading ? (
            <center className="m-t-20">
              <CircularProgress></CircularProgress>
            </center>
          ) : list_conversation.length > 0 ? (
            list_conversation.map((value, key) => {
              const element_selected =
                value.conversation_id == props.conversation_id
                  ? "customer-active"
                  : "";
              const element_readed =
                value.status_read == 0 ? "unread" : "readed";
              const date = new Date(value.updated_at);
              return (
                <div
                  key={key}
                  className={element_selected + " customer " + element_readed}
                  onClick={() => detailMessage(value, key)}
                >
                  <div className="avatar">
                    <Avatar
                      sx={{ width: 36, height: 36 }}
                      src={value.link_avatar}
                    />
                    {/* <img src={value.link_avatar}></img> */}
                    {!value.status_read ? (
                      <Badge
                        className="num-mess"
                        badgeContent={1}
                        large
                        color="error"
                      ></Badge>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="info">
                    <p className="m-t-0 m-b-0 name">
                      {props.conversation_changed_name[
                        value.conversation_id
                      ] !== undefined
                        ? props.conversation_changed_name[value.conversation_id]
                        : value.sender_name}
                      <span
                        style={{
                          float: "right",
                          fontSize: "13px",
                          color: "#696969",
                        }}
                      >
                        {convertNumber(date.getDate()) +
                          "/" +
                          convertNumber(parseInt(date.getMonth() + 1))}
                      </span>
                    </p>
                    <p className="m-t-0 m-b-0">{value.last_message}</p>
                    <p className="m-t-0 mb-2 display-flex align-item justify-content-between">
                      {/* <Avatar sx={{ width: 24, height: 24 }} alt={value.fanpage.name} src={value.fanpage.picture} /> */}
                      <div className="limit-one-row">
                        <Tooltip title={value.fanpage.name} arrow placement="top">
                          <span>Page: {value.fanpage.name}</span>
                        </Tooltip>
                      </div>
                      <span
                        style={{
                          color: "#fff",
                          padding: "1px 5px",
                          borderRadius: "3px",
                          background: `${value.type ? "#e65100" : "#1B74E4"}`,
                        }}
                        className="m-b-0 m-t-0"
                      >
                        {/* <Chip
                                                avatar={<Avatar alt={value.type ? 'Bình luận' : 'Tin nhắn'} src={value.platform} />}
                                                label={value.type ? t("liveChat.comment") : t("liveChat.message")}
                                                variant="outlined"
                                                /> */}
                        {value.type ? "comment" : "inbox"}
                      </span>
                    </p>
                    {value.conversation_id in state.conversation_tags && (
                      <p className="t-right m-b-0 m-t-0">
                        {state.conversation_tags[value.conversation_id].map(
                          (tag, key_tag) => {
                            return (
                              <Tooltip title={tag.name} placement="top" arrow>
                                <FiberManualRecordIcon
                                  sx={{ color: tag.color, fontSize: "15px" }}
                                  size="small"
                                />
                              </Tooltip>
                            );
                          }
                        )}
                      </p>
                    )}
                    {/* <p className="m-b-0 m-t-0">
                                            <Chip
                                            avatar={<Avatar alt={value.type ? 'Bình luận' : 'Tin nhắn'} src={value.platform} />}
                                            label={value.type ? t("liveChat.comment") : t("liveChat.message")}
                                            variant="outlined"
                                            />
                                        </p> */}
                  </div>
                </div>
              );
            })
          ) : (
            <center className="m-t-20">{t("common.message.emptyData")}</center>
          )}
          {!state.loading &&
            state.conversations.length >= props.conversation_num && (
              <LoadMoreConversation
                filter_search={state.filter_search}
                conversations={state.conversations}
                cookie={props.cookie}
                loadMoreConversation={loadMoreConversation}
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default Index;
