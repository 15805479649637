import { Grid } from "@mui/material";
import { createSelectable } from "react-selectable-fast";

const ImageItem = ({ selectableRef, isSelected, isSelecting, value })  => (
    <Grid item xs={2} sx={{position: 'relative'}}>
        <div ref={selectableRef} className={`cursor-pointer block-image ${isSelected ? "image-selected" : ""} ${isSelecting ? "selecting" : ""}`}>
            <img className='lib-image' src={value.link}></img>
        </div>
    </Grid>
)

export default createSelectable(ImageItem);