import { Alert, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../Apis";
import { useTranslation } from "react-i18next";

export default function Noti(props) {
    const { i18n } = useTranslation();
    const { cookies } = props;
    const [state, setState] = useState({
        total_day: null,
        message: null
    })
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            }
        };
        fetch(api.get_duration + `?lang=${i18n.language}`, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        total_day: result.total_day,
                        message: result.message
                    })
                }
            }
        )
    }, [])

    const getSeverity = () => {
        const total_day = state.total_day;
        if (total_day <= 7) {
            return "error";
        } else if (total_day > 7 && total_day <= 15) {
            return "warning"
        } else {
            return "success"
        }
    }

    return (
        <Grid item xs={12} md={12}>
            {
                state.total_day != null &&
                <Alert severity={getSeverity()}>
                    { state.message }
                </Alert>
            }
        </Grid>
    )
}