import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import api from './../../../../../Apis'
import { useTranslation } from "react-i18next";

function BillOrder() {
    const { t, i18n } = useTranslation();
    const {id} = useParams();
    const [cookies, setCookie] = useCookies('is_login');
    const [state, setState] = useState({
        data: '',
        error: ''
    })

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                order_id: id,
                lang: i18n.language
            })
        };
        fetch(api.print_bill_order, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        data: result.data
                    })
                    window.print();
                } else {
                    setState({
                        ...state,
                        error: result.message
                    })
                }
            }
        )
    }, [id])

    return (
        <Container maxWidth="sm">
            {
                state.error != ''
                ?
                <Grid item xs={12}>
                    <center>{state.error}</center>
                </Grid>
                :
                state.data != '' &&
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <img src={state.data.fanpage?.avatar}></img>
                        <p>
                            {state.data.fanpage?.name}
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <p>{t("order.billOrder.orderCode")}: <b>{state.data?.code}</b></p>
                        <p>{t("order.label.createDate")}: <b>{state.data?.created_at}</b></p>
                    </Grid>
                    <Grid item xs={12}>
                        <hr style={{margin: '0px', border: '1px dashed #ccc'}}></hr>
                    </Grid>
                    <Grid item xs={12}>
                        <p>
                           {t("order.billOrder.recipient")}: <b>{state.data?.name}</b>
                        </p>
                        <p>
                            {t("order.billOrder.shippingAddress")}: <b>{state.data?.address}, {state.data?.ward}, {state.data?.district}, {state.data?.province}</b>
                        </p>
                        <p>
                            {t("common.label.phoneNumber")}: <b>{state.data?.phone}</b>
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <hr style={{margin: '0px', border: '1px dashed #ccc'}}></hr>
                    </Grid>
                    <Grid item xs={12}>
                        <p>{t("order.billOrder.goodsContent")}:</p>
                        <ol style={{paddingLeft: '15px'}}>
                            {
                                state.data.detail_orders.map((detail, index) => {
                                    return (
                                        <li>
                                            <b>{detail.product.name}, {t("order.quantity")}: {detail.amount} ({detail.product.product_type_group_name}{detail.product.product_type_name != null && ', ' + detail.product.product_type_name})</b>
                                        </li>
                                    )
                                })
                            }
                        </ol>
                    </Grid>
                    <Grid item xs={12}>
                        <hr style={{margin: '0px', border: '1px dashed #ccc'}}></hr>
                    </Grid>
                    <Grid item xs={12}>
                        <p>{t("order.billOrder.receiverPayment")}: <b style={{fontSize: '30px'}}>{Number(state.data.total).toLocaleString()} VNĐ</b></p>
                    </Grid>
                </Grid>
            }
            <style dangerouslySetInnerHTML={{__html: "@page { size: auto;  margin: 0mm; }" }} />
        </Container>
    )
}

export default BillOrder;