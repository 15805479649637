import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from './../../../../Apis'
import {Link} from 'react-router-dom'
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";

const useData = (access_token) => {
    const { i18n } = useTranslation();
    const [fanpages, setFanpage] = useState('');
    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            body: JSON.stringify({
                lang: i18n.language
            })
        };
        fetch(api.get_fanpage, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success == true) {
                    setFanpage(result.datas);
                }
            }
        )
    }, [])

    return fanpages;
}

function Fanpage() {
    const [cookies, setCookie] = useCookies('is_login');
    const fanpages = useData(cookies.is_login);
    return (
        <Container maxWidth="xl">
            {
                fanpages != '' ? 
                fanpages.map((value, key) => {
                    return (
                        <div className="fanpage-item">
                            <div className="fanpage-image">
                                {
                                    value.fanpage_avatar != null && <img src={value.fanpage_avatar}></img>
                                }
                            </div>
                            <div className="fanpage-name">
                                {value.fanpage_name}
                            </div>
                            {
                                value.fanpage_like != null && 
                                <div className="fanpage-like">
                                    {value.fanpage_like} Likes
                                </div>
                            }
                            
                            <div className="fanpage-like">
                                <Link to={'/dashboard/fanpage/' + value.fanpage_id + '/bai-viet'}>Bài viết</Link>
                            </div>
                            <div className="fanpage-like">
                                <Link to={'/dashboard/fanpage/' + value.fanpage_id + '/tin-nhan'}>Tin nhắn</Link>
                            </div>
                            <div className="fanpage-like">
                                <Link to={'/dashboard/fanpage/' + value.fanpage_id + '/webhook-setting'}>Đăng ký webhook</Link>
                            </div>
                        </div>
                    )
                })
                :
                <div>
                    <center>
                        Dữ liệu trống
                    </center>
                </div>
            }
            
        </Container>
    )
}

export default Fanpage;