import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, TextField, MenuItem, Select, FormControl, InputLabel, Grid, Autocomplete, Checkbox } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useEffect, useState } from 'react';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useForm } from 'react-hook-form';
import api from './../../../../../Apis';
import Swal from 'sweetalert2';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import LoadingButton from '@mui/lab/LoadingButton';
import FanpageByUser from './FanpageByUser';
import { useTranslation } from "react-i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function Employee(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        employees: [],
        is_loading: false,
        fanpage_selected: [],
        error: {
            email: {
                status: false,
                message: ''
            },
            role_id: {
                status: false,
                message: ''
            }
        }
    })
    const {register, handleSubmit, reset} = useForm();

    useEffect(() => {
        setState({
            ...state,
            employees: props.employees
        })
    }, [props.employees])

    const deleteEmployee = (index, user_id) => {
        Swal.fire({
            title: t("common.alert.confirmDelete") ,
            showDenyButton: true,
            confirmButtonText: t("common.alert.confirm"),
            denyButtonText: t("common.alert.cancel"),
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        user_child_id: user_id,
                        lang: i18n.language
                    })
                };
                fetch(api.delete_employee, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const array_copy = [...state.employees];
                            array_copy.splice(index, 1);
                            setState({...state, employees: array_copy});
                            Swal.fire({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                timer: 1500
                            })
                        }
                    }
                )
            }
        })
    }

    const changeRoleEmployee = (user_id, event) => {
        Swal.fire({
            title: t("setting.confirmEditPermissions"),
            showDenyButton: true,
            confirmButtonText: t("common.alert.confirm"),
            denyButtonText: t("common.alert.cancel"),
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        user_child_id: user_id,
                        role_id: event.target.value,
                        lang: i18n.language
                    })
                };
                fetch(api.update_employee, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            Swal.fire({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                timer: 1500
                            })
                        }
                    }
                )
            }
        })
    }

    const onSubmitEmployee = (data) => {
        setState({...state, is_loading: true})
        data["fanpages"] = state.fanpage_selected;
        data["lang"] = i18n.language;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify(data)
        };
        fetch(api.add_employee, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        employees: [...state.employees, result.data],
                        fanpage_selected: [],
                        is_loading: false,
                        error: {
                            email: {
                                status: false,
                                message: ''
                            },
                            role_id: {
                                status: false,
                                message: ''
                            },
                            fanpages: {
                                status: false,
                                message: ''
                            }
                        }
                    })
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1000
                    })
                } else {
                    if (result.type == 'validate') {
                        setState({
                            ...state,
                            is_loading: false,
                            error: {
                                email: {
                                    status: result.errors.email != undefined ? true : false,
                                    message: result.errors.email != undefined ? result.errors.email.message : ''
                                },
                                role_id: {
                                    status: result.errors.role_id != undefined ? true : false,
                                    message: result.errors.role_id != undefined ? result.errors.role_id.message : ''
                                },
                                fanpages: {
                                    status: true,
                                    message: result.errors?.fanpages?.message
                                }
                            }
                        })
                    } else {
                        setState({...state, is_loading: false})
                        Swal.fire({
                            title: result.message,
                            icon: 'error',
                            timer: 1000
                        })
                    }
                }
            }
        )
    }

    const onCheckedFanpage = (e, data) => {
        setState({...state, fanpage_selected: data})
    }
    
    return (
        <TabPanel style={{padding: '10px 0px'}} value="2">
            <table>
                {
                    props.roles.map((value, key) => {
                        return (
                            <tr>
                                <td>
                                    <b style={{textTransform: 'capitalize'}}>{value.name}</b>
                                </td>
                                <td>
                                    :
                                </td>
                                <td>
                                    {value.description}
                                </td>
                            </tr>
                        )
                    })
                }
            </table>
            <br></br>
            <form onSubmit={handleSubmit(onSubmitEmployee)}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField required helperText={state.error.email.message} {...register('email')} error={state.error.email.status} fullWidth size="small" id="outlined-basic" label={t("setting.label.emailOrUsername")} variant="outlined" />
                    </Grid>
                    <Grid item xs={1}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{t("setting.label.permission")}</InputLabel>
                            <Select
                                {...register('role_id')}
                                fullWidth
                                size="small"
                                label={t("setting.label.permission")}
                                defaultValue={2}
                                error={state.error.role_id.status}
                                >
                                {
                                    props.roles.map((value, index) => {
                                        if (value.name != 'owner') {
                                            return (
                                                <MenuItem value={value.id}>
                                                    <em>{value.name}</em>
                                                </MenuItem>
                                            )
                                        }
                                        
                                    })
                                }
                            </Select>
                        </FormControl>
                        {
                            state.error.role_id.status &&
                            <p style={{fontSize: '0.75rem', color: '#d32f2f'}}>
                                {state.error.role_id.message}
                            </p>
                        }
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            multiple
                            helperText={state.error?.fanpages?.message}
                            id="checkboxes-tags-demo"
                            onChange={onCheckedFanpage}
                            options={props.fanpages}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name  + ' (' + option.fanpage_id + ')'}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField error={state.error?.fanpages?.status} helperText={state.error?.fanpages?.message} {...params} size="small" label={t("setting.label.selectFanpageToManage")} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <LoadingButton loading={ state.is_loading } type="submit" className="text-transform" variant="contained">
                           {t("common.confirm")}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
            <br></br>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("setting.tabs.tabTitle1")}</TableCell>
                            <TableCell>{t("setting.label.permission")}</TableCell>
                            <TableCell>{t("setting.managedFanpage")}</TableCell>
                            <TableCell>{t("setting.action")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            state.employees.map((row, key) => (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell component="th" scope="row">
                                        <p>{t("setting.name")}: {row.full_name}</p>
                                        <p>Username: {row.username}</p>
                                        <p>Email: {row.email}</p>
                                    </TableCell>
                                    <TableCell>
                                        {
                                            row.roles.name != 'owner' &&
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">{t("setting.label.permission")}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    label={t("setting.label.permission")}
                                                    defaultValue={row.roles.id}
                                                    onChange={(e) => changeRoleEmployee(row.user_id, e)}
                                                    >
                                                    {
                                                        props.roles.map((value, index) => {
                                                            if (value.name != 'owner') {
                                                                return (
                                                                    <MenuItem value={value.id}>
                                                                        <em>{value.name}</em>
                                                                    </MenuItem>
                                                                )
                                                            }
                                                            
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        }
                                    </TableCell>
                                    <TableCell style={{ width: "300px" }}>
                                        {
                                            row.roles.name != 'owner'
                                            &&
                                            <FanpageByUser
                                            key={ key }
                                            user={row}
                                            cookie={ props.cookie }
                                            fanpage_selected={ row.fanpages }
                                            list_fanpage={ props.fanpages }
                                            />
                                        }   
                                    </TableCell>
                                    <TableCell>
                                        {
                                            row.roles.name != 'owner' &&
                                            <Button onClick={() => deleteEmployee(key, row.user_id)} variant="contained" color="error">
                                                <DeleteRoundedIcon></DeleteRoundedIcon>
                                            </Button>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </TabPanel>
    )
}

export default Employee;