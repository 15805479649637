import TabPanel from "@mui/lab/TabPanel";
import { Autocomplete, Button, Chip, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useEffect, useState } from "react";
import DialogKeywordComment from "./DialogKeywordComment";
import CircularProgress from '@mui/material/CircularProgress';
import api from './../../../../../Apis';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Swal from "sweetalert2";
import DialogKeywordMessage from "./DialogKeywordMessage";
import { useTranslation } from "react-i18next";

export default function Keyword(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        dialog_keyword_comment: false,
        dialog_keyword_message: false,
        loading_tab: true,
        data_api: {},
        index_edit: null,
        data_edit: null,
        index_edit_message: null,
        data_edit_message: null,
        id_keyword_message: ''
    })

    useEffect(() => {
        if (props.value == 8) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({
                    lang: i18n.language
                })
            };
            fetch(api.get_data_keyword, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        setState({
                            ...state,
                            loading_tab: false,
                            data_api: result.data
                        })
                    }
                }
            )
        }
    }, [props.value])

    const editKeyword = (data, index) => {
        setState({
            ...state,
            dialog_keyword_comment: true,
            index_edit: index,
            data_edit: data
        })
    }

    const editKeywordMessage = (data, index) => {
        setState({
            ...state,
            dialog_keyword_message: true,
            index_edit_message: index,
            data_edit_message: data,
            id_keyword_message: data.id
        })
    }

    const createKeywordComment = (data) => {
        const data_api = {...state.data_api};
        data_api.data_fanpage_active = [data, ...data_api.data_fanpage_active];
        setState({
            ...state,
            data_api: data_api,
            dialog_keyword_comment: false
        });
    }

    const updateKeywordComment = (data) => {
        const data_api = {...state.data_api};
        data_api.data_fanpage_active[state.index_edit] = data;
        setState({
            ...state,
            data_api: data_api,
            dialog_keyword_comment: false,
            index_edit: null,
            data_edit: null
        })
    }

    const deleteKeyword = (data, index) => {
        Swal.fire({
            title: t("common.alert.confirmDelete"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("common.alert.confirm"),
            cancelButtonText: t("common.alert.cancel")
        }).then((result) => {
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        id: data.id,
                        lang: i18n.language
                    })
                };
                fetch(api.delete_keyword, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const data_api = {...state.data_api}
                            data_api.data_fanpage_active.splice(index, 1);
                            setState({
                                ...state,
                                data_api: data_api
                            })
                            Swal.fire({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                timer: 1500
                            })
                        }
                    }
                )
            }
          })
    }

    const onChangeActiveKeyword = (e, id, index) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                is_active: e.target.checked,
                id: id,
                lang: i18n.language
            })
        };
        fetch(api.change_status_active, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    const data_api = {...state.data_api}
                    data_api.data_fanpage_active[index].is_active = e.target.checked;
                    setState({
                        ...state,
                        data_api: data_api
                    })
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        timer: 1500
                    })
                }
            }
        )
    }

    const changeFanpage = (e, data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                fanpage_id: data.fanpage_id,
                lang: i18n.language
            })
        };
        fetch(api.change_fanpage_in_keyword, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    const data_api = {...state.data_api};
                    data_api.data_fanpage_active = result.data.data_fanpage_active;
                    data_api.page_active = result.data.page_active;
                    setState({
                        ...state,
                        data_api: data_api
                    })
                }
            }
        )
    }

    const onSaveKeywordMessage = (data) => {
        data['fanpage_id'] = state.data_api?.page_active?.fanpage_id;
        if (state.index_edit_message != null) {
            data['id'] = state.id_keyword_message;
        }
        data['lang'] = i18n.language;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify(data)
        };
        fetch(state.index_edit_message != null ? api.update_keyword_message : api.create_keyword_message, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: '1500'
                    })
                    const data_api = {...state.data_api};
                    
                    if (state.index_edit_message != null) {
                        data_api.data_fanpage_active[state.index_edit_message] = result.new_data;
                    } else {
                        data_api.data_fanpage_active = [result.new_data, ...data_api.data_fanpage_active];
                    }
                    setState({
                        ...state,
                        data_api: data_api,
                        dialog_keyword_message: false
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        timer: '1500'
                    })
                }
            }
        )
    }

    const removeSettingKeyword = (type) => {
        const name = type == "comment" ? t("setting.table.comment") : t("setting.table.message");
        Swal.fire({
            title: t("setting.deleteAllSettings", { name }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("common.alert.confirm"),
            cancelButtonText: t("common.alert.cancel")
        }).then((result) => {
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        type: type,
                        lang: i18n.language
                    })
                };
                fetch(api.remove_setting_keyword, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            Swal.fire({
                                title: result.message,
                                icon: 'success',
                                timer: '1500'
                            })
                            const data_api = {...state.data_api};
                            data_api.data_fanpage_active = [];
                            setState({
                                ...state,
                                data_api: data_api
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                timer: '1500'
                            })
                        }
                    }
                )
            }
        })
    }

    return (
        <TabPanel style={{padding: '30px 0px'}} value="8">
            <Dialog
                open={state.dialog_keyword_comment}
                fullWidth="true"
                maxWidth='md'
                onClose={() => setState({...state, dialog_keyword_comment: false, index_edit: null, data_edit: null})}
            >
                <DialogTitle>{t("setting.commentKeywordSettings")}</DialogTitle>
                <DialogContent>
                    <DialogKeywordComment
                        fanpage_id={state.data_api?.page_active?.fanpage_id}
                        cookie={props.cookie}
                        data_edit={state.data_edit}
                        index_edit={state.index_edit}
                        updateKeywordComment={updateKeywordComment}
                        createKeywordComment={createKeywordComment}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={state.dialog_keyword_message}
                fullWidth="true"
                maxWidth='md'
                onClose={() => setState({...state, dialog_keyword_message: false, index_edit_message: null, data_edit_message: null})}
            >
                <DialogTitle>{t("setting.keywordSettingsInfo")}</DialogTitle>
                <DialogContent>
                    <DialogKeywordMessage
                        onSaveKeywordMessage={onSaveKeywordMessage}
                        index_edit_message={state.index_edit_message}
                        data_edit_message={state.data_edit_message}
                        blocks={state.data_api?.blocks}
                    />
                </DialogContent>
            </Dialog>
        {
            state.loading_tab
            ?
                <p className="t-center">
                    <CircularProgress />
                </p>
            :
            <div>
                <h3 className="m-t-0">
                   {t("setting.autoReplySettingsForKeywords")}
                </h3>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            {/* <InputLabel id="demo-simple-select-label">Chọn Fanpage</InputLabel> */}
                            <Autocomplete
                                id="tags-outlined"
                                options={state.data_api?.fanpages}
                                getOptionLabel={(option) => option.name}
                                defaultValue={state.data_api?.page_active}
                                onChange={changeFanpage}
                                filterSelectedOptions
                                size="small"
                                renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    label={t("common.selectFanpage")}
                                />
                                )}
                            />
                            {/* <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={state.data_api?.page_active.fanpage_id}
                                label="Chọn Fanpage"
                                onChange={changeFanpage}
                            >
                                {
                                    state.data_api?.fanpages.map((value, key) => {
                                        return (
                                            <MenuItem value={value.fanpage_id}>{value.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select> */}
                        </FormControl>
                    </Grid>
                </Grid>
                <br></br>
                <Button
                    startIcon={<AddOutlinedIcon/>}
                    sx={{marginRight: '10px'}}
                    className="text-transform"
                    variant="contained"
                    onClick={() => setState({...state, dialog_keyword_message: true})}
                >
                       {t("setting.messageKeywords")}
                </Button>
                <Button
                    startIcon={<AddOutlinedIcon/>}
                    className="text-transform"
                    variant="contained"
                    sx={{marginRight: '10px'}}
                    color="success"
                    onClick={() => setState({...state, dialog_keyword_comment: true})}
                >
                    {t("setting.commentKeywords")}
                </Button>
                <Button
                    startIcon={<DeleteOutlineOutlinedIcon/>}
                    className="text-transform"
                    sx={{marginRight: '10px'}}
                    variant="contained"
                    color="error"
                    onClick={() => removeSettingKeyword('comment')}
                >
                    {t("setting.deleteCommentSettings")}
                </Button>
                <Button
                    startIcon={<DeleteOutlineOutlinedIcon/>}
                    className="text-transform"
                    variant="contained"
                    color="warning"
                    onClick={() => removeSettingKeyword('message')}
                >
                   {t("setting.deleteMessageSettings")}
                </Button>
                <h3>
                    {t("setting.commentKeywords")}
                </h3>
                <Grid container spacing={2}>
                    <Grid item xs={12}>                    
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("setting.table.no")}</TableCell>
                                        <TableCell>{t("setting.table.enableDisable")}</TableCell>
                                        <TableCell>{t("setting.table.keyword")}</TableCell>
                                        <TableCell sx={{width: '100px'}}>Post ID</TableCell>
                                        <TableCell sx={{width: '100px'}}>{t("setting.table.comment")}</TableCell>
                                        <TableCell sx={{width: '100px'}}>{t("setting.table.message")}</TableCell>
                                        <TableCell>{t("setting.table.automatic")}</TableCell>
                                        <TableCell>{t("setting.action")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        state.data_api?.data_fanpage_active &&
                                        Array.isArray(state.data_api.data_fanpage_active) &&
                                        state.data_api.data_fanpage_active.map((value, key) => {
                                            if (value.type == 'comment') {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{key + 1}</TableCell>
                                                        <TableCell>
                                                            <Switch onChange={(e) => onChangeActiveKeyword(e, value.id, key)} defaultChecked={value.is_active} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                value.type_content_comment == 'comment_keyword'
                                                                ?
                                                                    value.keyword_comment
                                                                :
                                                                value.type_content_comment == 'comment_any'
                                                                ?
                                                                    t("setting.any")
                                                                :
                                                                value.type_content_comment == 'comment_phone'
                                                                ?
                                                                t("common.label.phoneNumber")
                                                                :
                                                                ''
                                                                
                                                            }
                                                        </TableCell>
                                                        <TableCell sx={{width: '300px', wordBreak: 'break-all'}}>{value.post_id}</TableCell>
                                                        <TableCell sx={{width: '200px'}}>{value.message_comment}</TableCell>
                                                        <TableCell sx={{width: '200px'}}>{value.message_inbox}</TableCell>
                                                        <TableCell sx={{width: '150px'}}>
                                                            <p>
                                                                <Chip
                                                                label={t("setting.label.autoCreateOrder")}
                                                                // deleteIcon={value.is_create_order ? <DoneIcon color="success"/> : <CloseOutlinedIcon/>}
                                                                // onDelete={onDelete}
                                                                sx={{textDecoration: !value.is_create_order ? 'line-through' : 'none'}}
                                                                /> 
                                                            </p>
                                                            <p>
                                                                <Chip
                                                                label={value.is_tag ? 'Check Tag >=' + value.tag_number : 'Check Tag'}
                                                                // deleteIcon={value.is_create_order ? <DoneIcon color="success"/> : <CloseOutlinedIcon/>}
                                                                // onDelete={onDelete}
                                                                sx={{textDecoration: !value.is_tag ? 'line-through' : 'none'}}
                                                                /> 
                                                            </p>
                                                            <p>
                                                               <Chip
                                                                label={t("setting.label.autoHideComment")}
                                                                // deleteIcon={value.is_hidden_comment ? <DoneIcon color="success"/> : <CloseOutlinedIcon/>}
                                                                // onDelete={onDelete}
                                                                sx={{textDecoration: !value.is_hidden_comment ? 'line-through' : 'none'}}
                                                                /> 
                                                            </p>
                                                            <p>
                                                               <Chip
                                                                label={t("setting.label.replyCommentOncePerCustomerPerPost")}
                                                                // deleteIcon={value.is_rep_comment ? <DoneIcon color="success"/> : <CloseOutlinedIcon/>}
                                                                // onDelete={onDelete}
                                                                sx={{textDecoration: !value.is_rep_comment ? 'line-through' : 'none'}}
                                                                /> 
                                                            </p>
                                                            <p>
                                                               <Chip
                                                                label={t("setting.label.messageOncePerCustomerPerPost")}
                                                                // deleteIcon={value.is_inbox ? <DoneIcon color="success"/> : <CloseOutlinedIcon/>}
                                                                // onDelete={onDelete}
                                                                sx={{textDecoration: !value.is_inbox ? 'line-through' : 'none'}}
                                                                /> 
                                                            </p>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => editKeyword(value, key)} size="small" sx={{marginRight: '5px', minWidth: '45px'}} variant="contained" color="warning">
                                                                <CreateOutlinedIcon/>
                                                            </Button>
                                                            <Button onClick={() => deleteKeyword(value, key)} size="small" sx={{minWidth: '45px'}} variant="contained" color="error">
                                                                <DeleteOutlineOutlinedIcon/>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                            
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <h3>
                    {t("setting.messageKeywords")}
                </h3>
                <Grid container spacing={2}>
                    <Grid item xs={12}>                    
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{width: '5%'}}>{t("setting.table.no")}</TableCell>
                                        <TableCell sx={{width: '5%'}}>{t("setting.table.enableDisable")}</TableCell>
                                        <TableCell sx={{width: '20%'}}>{t("setting.table.keyword")}</TableCell>
                                        <TableCell sx={{width: '30%'}}>{t("setting.table.message")}</TableCell>
                                        <TableCell sx={{width: '30%'}}>{t("setting.table.replyBlock")}</TableCell>
                                        <TableCell sx={{width: '10%'}}>{t("setting.action")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        state.data_api?.data_fanpage_active &&
                                        Array.isArray(state.data_api.data_fanpage_active) &&
                                        state.data_api.data_fanpage_active.map((value, key) => {
                                            if (value.type == 'message') {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{key + 1}</TableCell>
                                                        <TableCell>
                                                            <Switch onChange={(e) => onChangeActiveKeyword(e, value.id, key)} defaultChecked={value.is_active} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {value.keyword_comment}
                                                        </TableCell>
                                                        <TableCell>
                                                            {value.message_inbox}
                                                        </TableCell>
                                                        <TableCell>
                                                            {value.block.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => editKeywordMessage(value, key)} size="small" sx={{marginRight: '5px', minWidth: '45px'}} variant="contained" color="warning">
                                                                <CreateOutlinedIcon/>
                                                            </Button>
                                                            <Button onClick={() => deleteKeyword(value, key)} size="small" sx={{minWidth: '45px'}} variant="contained" color="error">
                                                                <DeleteOutlineOutlinedIcon/>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        }
        </TabPanel>
    )
}