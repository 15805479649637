import {useEffect, useState} from "react";
import Pusher from "pusher-js";
import { useCookies } from "react-cookie";
import api from './../../../../../Apis';
import { useTranslation } from "react-i18next";

function Index() {
    const { i18n } = useTranslation();
    const [username, setUsername] = useState('username');
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [works, setWork] = useState(['nâu cơm', 'rửa bát', 'quét nhà']);
    let allMessages = [];
    const [cookies, setCookie] = useCookies('is_login');

    // useEffect(() => {
    //     Pusher.logToConsole = true;

    //     const pusher = new Pusher('599c142c597b726c859e', {
    //         cluster: 'ap1'
    //     });

    //     const channel = pusher.subscribe('my-channel');
    //     channel.bind('my-event', function (data) {
    //         console.log(JSON.stringify(data));
    //         allMessages.push(data);
    //         setMessages(allMessages);
    //     });
    // }, []);

    const submit = async e => {
        e.preventDefault(); // không load lại trang
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                message: message,
                lang: i18n.language
            })
        };
        fetch(api.send_message, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                console.log(result);
            }
        )
        const t = messages.push(message);
        setMessages(t);
        setMessage('');
        // await fetch('http://localhost:8000/api/messages', {
        //     method: 'POST',
        //     headers: {'Content-Type': 'application/json'},
        //     body: JSON.stringify({
        //         username,
        //         message
        //     })
        // });

    }

    const addWork = () => {
        // const new_work = [...works].push(['rửa bát']);
        // setWork(new_work);
        setWork([...works, works.push('đi chơi')]);
        // works.push('đi chơi' + Math.random());
        //console.log(works);
        //console.log(works, new_work);
        // setWork(works);
    }
    return (
        <div className="container">
            <button type="button" onClick={addWork}>Thêm</button>
            <ul>
                {
                    works.map((value, key) => {
                        return (
                            <li>
                                {value}
                            </li>
                        )
                    })
                }
            </ul>
            <div className="d-flex flex-column align-items-stretch flex-shrink-0 bg-white">
                <div
                    className="d-flex align-items-center flex-shrink-0 p-3 link-dark text-decoration-none border-bottom">
                    <input className="fs-5 fw-semibold" value={username}
                           onChange={e => setUsername(e.target.value)}/>
                </div>
                <div className="list-group list-group-flush border-bottom scrollarea">
                    {messages.map(message => {
                        return (
                            <div className="list-group-item list-group-item-action py-3 lh-tight">
                                <div className="d-flex w-100 align-items-center justify-content-between">
                                    <strong className="mb-1">{message.username}</strong>
                                </div>
                                <div className="col-10 mb-1 small">{message.message}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <form onSubmit={e => submit(e)}>
                <input className="form-control" placeholder="Write a message" value={message}
                       onChange={e => setMessage(e.target.value)}
                />
            </form>
        </div>
    );
}

export default Index;