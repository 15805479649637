import { useSelect } from "@mui/base";
import { Avatar, Button, Chip, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box } from "@mui/system";
import Swal from "sweetalert2";
import { requestOptions, searchStringInArray } from "../../../../../../../Helpers/function";
import { getMessSample } from "../../../../../../../Store/Actions/GetMessSample";
import api from './../../../../../../../Apis'
import { useTranslation } from "react-i18next";

function Index(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        value: '',
        mess_sample: '',
        attachments: [],
        dialog: false,
        index_mess_sample_search: -1
    })
    const dispatch = useDispatch();
    const data_redux = useSelector(state => state.mess_sample);
    useEffect(() => {
        setState({
            ...state,
            value: data_redux.message,
            attachments: data_redux.attachments,
            index_mess_sample_search: -1
        })
    }, [data_redux.rand])

    useEffect(() => {
        setState({
            ...state,
            value: '',
            attachments: []
        })
    }, [])

    const handleUserKeyPress = e => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            
            if (state.value != '' || state.attachments.length > 0) {
                props.onSendMessage(state.value, state.attachments);
                setState({
                    ...state,
                    value: '',
                    mess_sample: '',
                    attachments: []
                })
            } else {
                Swal.fire({
                    title: t("liveChat.conversation.requireMessageOrImage"),
                    icon: 'warning'
                })
            }
        }
    };
    const changeValue = (e) => {
        const index = searchStringInArray(e.target.value, props.mess_sample);
        setState({
            ...state,
            value: e.target.value,
            index_mess_sample_search: index,
        })
    }
    const removeAttachment = (index) => {
        const attachment_copy = [...state.attachments];
        attachment_copy.splice(index, 1);
        setState({
            ...state,
            attachments: attachment_copy,
            dialog: attachment_copy.length == 0 ? false : true
        })
    }
    
    const pickMessSample = () => {
        var content = props.mess_sample[state.index_mess_sample_search]['content'];
        const array_replace = [{key: "{name}", value: props.name_sender}, {key: "{page_name}", value: props.fanpage_name}];
        for (var i = 0; i < array_replace.length; i++) {
            var content_new = content.replaceAll(array_replace[i].key, array_replace[i].value);
            content = content_new;
        }
        const action = getMessSample({
            message: content_new,
            attachments: props.mess_sample[state.index_mess_sample_search]['images'],
            rand: Math.random(),
            page_name: 'livechat'
        });
        dispatch(action);
    }

    const handlePaste = (e) => {
        if (e.clipboardData.files.length) {
            const fileObject = e.clipboardData.files[0];
            const file = {
              getRawFile: () => fileObject,
              name: fileObject.name,
              size: fileObject.size,
              status: 2,
              progress: 0,
            };
            
            let fileReader = new FileReader();
            fileReader.readAsDataURL(e.clipboardData.files[0]);
            fileReader.onload = (event) => {
                // fetch(api.upload_image_base64, requestOptions('POST', props.cookie, {image: event.target.result}))
                // .then(res => res.json())
                // .then(
                //     (result) => {
                //         console.log(result);
                //     }
                // )
                setState({
                    ...state,
                    attachments: [
                        ...state.attachments,
                        {
                            file_url: null,
                            image_data: event.target.result,//URL.createObjectURL(e.clipboardData.files[0]),
                            mime_type: "image/jpeg",
                            video_data: null
                        }
                    ]
                })
                //console.log(event.target.result);
            }
            //console.log(URL.createObjectURL(e.clipboardData.files[0]));
          }
    }

    return (
        <form>
            <Dialog
                fullWidth="true"
                maxWidth='sm'
                open={state.dialog}
                onClose={() => setState({...state, dialog: false})}
            >
                <DialogTitle sx={{display: "flex", justifyContent: "space-between"}}>
                    {t("liveChat.conversation.attachedImage")}
                    <Button startIcon={<CloseOutlinedIcon />} variant="contained" color="warning" onClick={() => setState({...state, dialog: false})}>
                        {t("common.close")}
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            {
                                state.attachments.map((attachmentItem, key) => {
                                    return (
                                        <Grid item xs={2} sx={{position: 'relative'}}>
                                            <div className="image-attachment position-relative">
                                                <img className="w-100" src={attachmentItem.image_data}></img>
                                                <CloseOutlinedIcon onClick={() => removeAttachment(key)} className="cursor"/>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
            {
                state.index_mess_sample_search != -1 &&
                <div onClick={pickMessSample} style={{padding: "0px 10px", marginBottom: '10px'}} className="cursor attachments result-search-mess">
                    {t("liveChat.conversation.quickTemplate")}: <b>{props.mess_sample[state.index_mess_sample_search]['keyword']}</b>
                </div>
            }
            {
                state.attachments.length > 0 &&
                <div style={{padding: "0px 10px", marginBottom: '10px'}} className="attachments">
                    <Chip
                        onClick={() => setState({...state, dialog: true})}
                        onDelete={() => setState({...state, attachments: []})}
                        label={state.attachments.length + " " + t("liveChat.conversation.attachedImage")}
                        icon={<ImageOutlinedIcon/>}
                    color="warning" />
                </div>
            }
            {
                <textarea onPaste={handlePaste} placeholder={t("liveChat.conversation.pressEnterToSend")} onChange={changeValue} onKeyPress={handleUserKeyPress} value={state.value}></textarea>
            }
            {
                state.attachments.length > 0 &&
                <style dangerouslySetInnerHTML={{__html: ".detail-mess{height: 50% !important} .write-mess{height: 42% !important} .messenger-box .chat .write-mess .tag {height: 20%} .messenger-box .chat .write-mess .form-chat form textarea {height: 70%} .messenger-box .chat .write-mess .form-chat {height: calc(65% - 30px)}" }} />
            }
            
        </form>
    )
}

export default Index;