import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Copyright(props) {
    const { t } = useTranslation();
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            <a className="a-none" style={{marginRight: '10px', color: 'unset'}} href={`https://${ process.env.REACT_APP_DOMAIN }`} target="_blank">{t("header.home.title")}</a>
            <Link className="a-none" style={{marginRight: '10px', color: 'unset'}} to="/">{t("auth.title")}</Link>
            <br></br><br></br>
            {'Copyright © '} { process.env.REACT_APP_NAME } 2022
        </Typography>
    );
}

export default Copyright;