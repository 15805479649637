import { Avatar, Backdrop, Button, Chip, CircularProgress, TableCell, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import api from "../../../../../Apis";
import { useTranslation } from "react-i18next";

export default function GroupFanpageItem(props) {
    const { t, i18n } = useTranslation();
    const { stt, data, total_fanpage, fanpages, cookie, index_group } = props;
    const [state, setState] = useState({
        fanpageList: [],
        is_loading_page: false
    })

    useEffect(() => {
        setState({...state, fanpageList: fanpages})
    }, [fanpages])
    
    const handleDelete = (fanpage_id, index) => {
        Swal.fire({
            title: t("groupFanpage.message.confirmRemoveFanpage"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("common.alert.confirm"),
            cancelButtonText: t("common.alert.cancel")
        }).then((result) => {
            if (result.isConfirmed) {
                setState({...state, is_loading_page: true})
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookie
                    },
                    body: JSON.stringify({
                        fanpage_id: fanpage_id,
                        lang: i18n.language
                    })
                };
                fetch(api.delete_fanpage_in_group, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            Swal.fire(result.message, "", "success")
                            const fanpageListCopy = [...state.fanpageList]
                            fanpageListCopy.splice(index, 1)
                            setState({...state, fanpageList: fanpageListCopy, is_loading_page: false})
                        } else {
                            Swal.fire(result.message, "", "warning")
                            setState({...state, is_loading_page: false})
                        }
                    }
                )
            }
        })
    };

    const deleteGroup = (group_name) => {
        Swal.fire({
            title: t("groupFanpage.message.confirmDeleteGroup"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("common.alert.confirm"),
            cancelButtonText: t("common.alert.cancel")
        }).then((result) => {
            if (result.isConfirmed) {
                setState({...state, is_loading_page: true})
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookie
                    },
                    body: JSON.stringify({
                        group_name: group_name,
                        lang: i18n.language
                    })
                };
                fetch(api.delete_group_fanpage, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            Swal.fire(result.message, "", "success")
                            setState({...state, is_loading_page: false})
                            props.updateGroupAfterDelete(index_group)
                        } else {
                            Swal.fire(result.message, "", "warning")
                            setState({...state, is_loading_page: false})
                        }
                    }
                )
            }
        })
    }

    return (
        <>
            {
                state.is_loading_page
                &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={state.is_loading_page}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            <TableRow>
                <TableCell>{ stt }</TableCell>
                <TableCell>{ data.group_name }</TableCell>
                <TableCell>{ total_fanpage }</TableCell>
                <TableCell>
                    {
                        state.fanpageList.map((fanpageItem, index) => {
                            return (
                                <p>
                                    <Chip
                                    avatar={<Avatar alt={ fanpageItem.name } src={ fanpageItem.avatar } />}
                                    label={ fanpageItem.name + " (" + fanpageItem.fanpage_id + ")" }
                                    variant="outlined"
                                    onDelete={() => handleDelete(fanpageItem.fanpage_id, index)}
                                    />
                                </p>
                            )
                        })
                    }
                </TableCell>
                <TableCell>
                    <Button onClick={() => deleteGroup(data.group_name)} color="error" variant="contained">{t("groupFanpage.table.deleteGroup")}</Button>
                </TableCell>
            </TableRow>
        </>
    )
}