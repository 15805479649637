const initState = {
    message: '',
    attachments: [],
    rand: ''
};
const messSampleReducer = (state = initState, action) => {
    switch (action.type) {
        case 'MESS_SAMPLE':
            return action.payload; 
        default:
            return state;
    }
}

export default messSampleReducer;