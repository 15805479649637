import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";

function CheckBoxItem(props) {
    const [state, setState] = useState({
        is_checked: false
    })
    useEffect(() => {
        setState({
            ...state,
            is_checked: props.order.is_checked
        })
    }, [props.order.is_checked])

    const changeCheckbox = () => {
        //console.log(props.order.is_checked);
        setState({
            ...state,
            is_checked: !state.is_checked
        })
        props.updateChecked(props.index)
    }

    //console.log(data);

    return (
        <Checkbox 
            checked={state.is_checked}
            onChange={changeCheckbox}
        />
    )
}

export default CheckBoxItem;