import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Dialog, DialogActions, DialogContent, Fade, DialogTitle, Menu, TextField, MenuItem, InputLabel, FormControl, Select, Tooltip } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useEffect, useMemo, useState } from 'react';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useForm } from 'react-hook-form';
import api from './../../../../../Apis';
import Swal from 'sweetalert2';
import LoadingButton from '@mui/lab/LoadingButton';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useTranslation } from 'react-i18next';

function CouponMessage(props) {
    const { t,  i18n } = useTranslation();
    const [state, setState] = useState({
        open: false,
        anchorEl: null,
        symbol: '',
        edit: false,
        dialog_send_message: false,
        coupon_messages: [],
        message_error: '',
        data_edit: {},
        coupon_message_id: null,
        loading: false
    })
    const {register, handleSubmit, reset} = useForm({
        defaultValues: useMemo(() => {
            return state.data_edit;
        }, [state.data_edit])
    });

    const onSubmitCouponMessage = (data) => {
        const formData = new FormData();
        // formData.append('file', data.file[0] == undefined ? '' : data.file[0]);
        // formData.append('fanpage_id', data.fanpage_id);
        formData.append('name', data.name);
        formData.append('content', data.content);
        formData.append('id', state.data_edit.id == undefined ? '' : state.data_edit.id);
        formData.append('lang', i18n.language);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + props.cookie
            },
            body: formData
        };
        fetch(api.create_coupon_message, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        timer: 2000,
                        icon: 'success'
                    })
                    if (state.data_edit.id != undefined) {
                        const coupon_message_copy = [...state.coupon_messages];
                        coupon_message_copy[state.data_edit.index] = result.coupon_message_new;
                        setState({
                            ...state,
                            open: false,
                            coupon_messages: coupon_message_copy
                        })
                    } else {
                        setState({
                            ...state,
                            open: false,
                            coupon_messages: [result.coupon_message_new, ...state.coupon_messages]
                        })
                    }
                } else {
                    Swal.fire({
                        title: result.message,
                        timer: 2000,
                        icon: 'error'
                    })
                }
            }
        )
    }

    const onSubmitSendCouponMessage = (data) => {
        setState({
            ...state,
            loading: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                fanpage_id: data.fanpage_id,
                type_customer: data.type_customer,
                coupon_message_id: state.coupon_message_id,
                lang: i18n.language
            })
        };
        fetch(api.send_series_coupon_message, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 2000
                    })
                    setState({
                        ...state,
                        loading: false,
                        dialog_send_message: false
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        timer: 2000
                    })
                    setState({
                        ...state,
                        loading: false,
                    })
                }
            }
        )
    }

    const closeDialog = () => {
        setState({
            ...state,
            data_edit: {},
            open: false
        })
    }

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            }
        };
        fetch(api.get_coupon_message + `?lang=${i18n.language}`, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        coupon_messages: result.coupon_messages
                    })
                } else {
                    setState({
                        ...state,
                        message: result.message
                    })
                }
            }
        )
    }, [])

    useEffect(() => {
        reset();
        reset(state.data_edit);
    }, [state.data_edit]);

    const editCouponMessage = (index, item) => {
        setState({
            ...state,
            open: true,
            data_edit: {
                index: index,
                id: item.id,
                name: item.name,
                fanpage_id: item.fanpage_id,
                content: item.content
            }
        })
    }

    const deleteCouponMessage = (index, item) => {
        Swal.fire({
            title: t("common.confirm.confirmDelete"),
            showDenyButton: true,
            confirmButtonText: t("common.alert.comfirm"),
            denyButtonText: t("common.alert.cancel"),
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const coupon_message_copy = [...state.coupon_messages];
                coupon_message_copy[index].loading = true;
                setState({
                    ...state,
                    coupon_messages: coupon_message_copy
                })
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        id: item.id,
                        lang: i18n.language
                    })
                };
                fetch(api.delete_coupon_message, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            coupon_message_copy.splice(index, 1);
                            setState({
                                ...state,
                                coupon_messages: coupon_message_copy
                            })
                            Swal.fire({
                                title: result.message,
                                timer: 2000,
                                icon: 'success'
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                timer: 2000,
                                icon: 'error'
                            })
                        }
                    }
                )
            }
        })
    }
    
    return (
        <TabPanel value="7" style={{padding: '10px 0px'}}>
            <p>
                <Button onClick={() => {setState({...state, open: true, symbol: ''})}} startIcon={<AddRoundedIcon/>} sx={{textTransform: 'none'}} variant="contained">{t("common.add")}</Button>
            </p>
            <Dialog open={state.open} fullWidth="true" maxWidth='sm' onClose={closeDialog}>
                <DialogTitle>{t("setting.addPromotionMessage")}</DialogTitle>
                <form onSubmit={handleSubmit(onSubmitCouponMessage)}>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label={t("setting.label.displayName")}
                            variant="outlined"
                            type="text"
                            fullWidth
                            {...register('name')}
                            required
                        />
                        <textarea 
                            required {...register('content')}
                            className="textarea"
                            rows={10}
                            placeholder={t("setting.messageReplyContent")}
                            style={{marginTop: '10px'}}
                        ></textarea>
                        <small>
                            {t("setting.abbreviationTermsMessage")}
                        </small>
                        <ul>
                            <li><b>{"{name}"}</b>: {t("setting.customerName")}</li>
                            <li><b>{"{page_name}"}</b>: {t("setting.pageName")}</li>
                        </ul>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>{t("common.close")}</Button>
                        <Button type="submit">{t("common.save")}</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={state.dialog_send_message} fullWidth="true" maxWidth='sm' onClose={() => setState({...state, dialog_send_message: false})}>
                <DialogTitle>{t("setting.sendMessageToCustomer")}</DialogTitle>
                <form onSubmit={handleSubmit(onSubmitSendCouponMessage)}>
                    <DialogContent>
                        <FormControl fullWidth margin="dense">
                            <InputLabel id="demo-simple-select-label">{t("setting.label.pageCustomers")}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t("setting.label.pageCustomers")}
                                {...register('fanpage_id')}
                                defaultValue={state.data_edit.fanpage_id}
                            >
                                {
                                    props.fanpages.map((value, index) => {
                                        return (
                                            <MenuItem value={value.fanpage_id}>
                                                {value.name}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="dense">
                            <InputLabel id="demo-simple-select-label">{t("setting.label.sendToCustomer")}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t("setting.label.sendToCustomer")}
                                {...register('type_customer')}
                            >
                                <MenuItem value="all">
                                    {t("common.all")}
                                </MenuItem>
                                <MenuItem value="bought">
                                    {t("setting.purchased")}
                                </MenuItem>
                                <MenuItem value="not_bought">
                                    {t("setting.notPurchased")}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog} variant='contained' color="error" className='text-transform'>{t("common.close")}</Button>
                        <LoadingButton loading={state.loading} type="submit" variant='contained' className='text-transform'>{t("customer.confirmSend")}</LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
            <TableContainer component={Paper}>
                
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("setting.table.no")}</TableCell>
                            <TableCell>{t("setting.table.creator")}</TableCell>
                            <TableCell>{t("setting.label.displayName")}</TableCell>
                            <TableCell>{t("setting.content")}</TableCell>
                            <TableCell>{t("setting.action")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            state.message_error != ''
                            ?
                                <TableCell colSpan={7} align="center">
                                    {t("common.message.pleaseTryAgain")}
                                </TableCell>
                            :
                            state.coupon_messages.length == 0
                            ?
                                <TableCell colSpan={7} align="center">
                                     {t("common.message.emptyData")}
                                </TableCell>
                            :
                            state.coupon_messages.map((row, key) => (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell component="th" scope="row">
                                        {key + 1}
                                    </TableCell>
                                    <TableCell>{row.user_create}</TableCell>
                                    <TableCell>
                                        {row.name}
                                    </TableCell>
                                    <TableCell>{row.content}</TableCell>
                                    <TableCell>
                                        <Tooltip title={t("setting.sendToCustomer")} arrow placement="top">
                                            <Button 
                                                size="small"
                                                sx={{marginRight: '5px', minWidth: '45px'}}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => setState({...state, dialog_send_message: true, coupon_message_id: row.id})}
                                            >
                                                <SendRoundedIcon></SendRoundedIcon>
                                            </Button>
                                        </Tooltip>
                                        <Button 
                                            size="small"
                                            sx={{marginRight: '5px', minWidth: '45px'}}
                                            variant="contained"
                                            color="warning"
                                            onClick={() => editCouponMessage(key, row)}
                                        >
                                            <EditRoundedIcon></EditRoundedIcon>
                                        </Button>
                                        <LoadingButton
                                            loading={row.loading}
                                            size="small"
                                            sx={{marginRight: '5px', minWidth: '45px'}}
                                            variant="contained"
                                            color="error"
                                            onClick={() => deleteCouponMessage(key, row)}
                                        >
                                            <DeleteRoundedIcon></DeleteRoundedIcon>
                                        </LoadingButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </TabPanel>
    )
}

export default CouponMessage;