import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { Grid, MenuItem, Dialog, TextField, DialogTitle, DialogContent, Button, Autocomplete, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Tab } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import api from './../../../../../Apis'
import {convertNumber, getAddress} from './../../../../../Helpers/function'
import Swal from 'sweetalert2';
import { Controller } from "react-hook-form";
import Product from '../../Fanpages/Messages/Orders/Components/Product';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import FormCreateBill from './FormCreateBill';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import TabContext from '@mui/lab/TabContext';
import { Box } from "@mui/system";
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import { useTranslation } from "react-i18next"

function Filter(props) {
    const { t, i18n } = useTranslation();
    const {register, handleSubmit, reset} = useForm();
    const {register: register2, handleSubmit: handleSubmit2, reset: reset2, control} = useForm();
    const [state, setState] = useState({
        dialog_create_bill: false,
        loading_search: false,
        loading_export: false,
        loading_reset: false,
        loading_submit: false,
        dialog: false,
        dialog_product: false,
        districts: [],
        wards: [],
        provinces: [],
        product: {
            extra: 0,
            discount: 0,
            discount_percent: 0,
            detail_products: []
        },
        transport: '',
        errors: {},
        value: "1"
    })
    const searchOrder = (data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({...data, lang: i18n.language})
        };
        fetch(api.search_order, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        loading_search: false,
                    })
                    props.search_order(result, data);
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        timer: 1500
                    })
                }
            }
        )
    }

    const resetForm = () => {
        reset();
        props.callApi();
    }

    const exportOrder = (data) => {
        setState({
            ...state,
            loading_export: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            responseType: 'blob',
            body: JSON.stringify({...data, lang: i18n.language})
        };
        fetch(api.export_order, requestOptions)
        .then(res => res.blob())
        .then(
            (result) => {
                const url = window.URL.createObjectURL(new Blob([result]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${Date.now()}.xlsx`);
                document.body.appendChild(link);
                link.click();
                setState({
                    ...state,
                    loading_export: false
                })
            }
        )
    }
    
    const changeProvince = (e, province) => {
        const res = getAddress(state.transport, props.cookie, api.get_district, province.id, i18n.language);
        res.then(result => {
            setState({
                ...state,
                districts: result
            })
        })
    }

    const changeTransport = (e) => {
        const res = getAddress(e.target.value, props.cookie, api.get_province_by_transport, null, i18n.language);
        res.then( result => {
            setState({
                ...state,
                provinces: result,
                transport: e.target.value
            })
        })
    }

    const changeDistrict = (e, district) => {
        const res = getAddress(state.transport, props.cookie, api.get_ward, null, district.id, i18n.language);
        res.then(result => {
            setState({
                ...state,
                wards: result
            })
        })
    }

    const createOrder = (data) => {
        if (state.product.detail_products.length == 0) {
            Swal.fire(t("order.message.productNotSelected"), '', 'warning');
        } else {
            if (data.province_id == undefined) {
                data['province_id'] = null;
            }
            if (data.district_id == undefined) {
                data['district_id'] = null;
            }
            if (data.ward_id == undefined) {
                data['ward_id'] = null;
            }
            setState({
                ...state,
                loading_submit: true
            })
            data['product'] = state.product;
            data['lang'] = i18n.language;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify(data)
            };
            fetch(api.create_handle_order, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        reset2();
                        Swal.fire({
                            title: result.message,
                            icon: 'success',
                            timer: 1500
                        })
                        setState({
                            ...state,
                            dialog: false,
                            loading_submit: false,
                            product: {
                                extra: 0,
                                discount: 0,
                                discount_percent: 0,
                                detail_products: []
                            }
                        })
                        props.refreshOrder(result.data);
                    } else {
                        if (result.type == 'validate') {
                            // var text_error = '';
                            // result.errors.map((value, key) => {
                            //     text_error+= '<p>' + value + '</p>';
                            // })
                            Swal.fire({
                                title: t("order.message.checkFields"),
                                icon: 'warning',
                                timer: 2000
                                // html: text_error
                            })
                            setState({
                                ...state,
                                loading_submit: false,
                                errors: result.errors
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                timer: 1500
                            })
                            setState({
                                ...state,
                                loading_submit: false,
                            })
                        }
                    }
                }
            )
        }
    }

    const addToCart = (products) => {
        const carts = [];
        products.map((productItem) => {
            const item = JSON.parse(productItem);
            carts.push({
                'id': item.id,
                'product': {
                    'name': item.product_name,
                    'attribute': item.attribute,
                    'price': item.price
                },
                'amount': 1,
                'price': item.price,
                'weight': item.weight
            });
        })
        const product = {...state.product};
        product.detail_products = carts.concat(product.detail_products);
        setState({
            ...state,
            dialog_product: false,
            product: product
        });
    }

    const is_number = (value) => {
        if ((Number(value) == value && value % 1 == 0) || (Number(value) == value && value % 1 != 0)) {
            return true;
        }

        return false
    }

    const changeNumber = (index, e) => {
        if (e.target.value > 0) {
            const product = {...state.product};
            product.detail_products[index].amount = e.target.value;
            setState({
                ...state,
                product: product
            })
        }

    }

    const changeOrder = (e) => {
        const product = {...state.product};
        const index_name = e.target.name;

        if (index_name == 'extra' || index_name == 'discount') {
            if (!isNaN(e.target.value)) {
                var value = e.target.value == '' ? 0 : Number(e.target.value);
                product[index_name] = value;
                if (index_name == 'discount') {
                    const total_price = total(product.detail_products);
                    const percent = total_price == 0 ? 0 : value/total_price * 100;
                    product.discount_percent = percent;
                }
                setState({
                    ...state,
                    product: product
                })
            }
        } else if (index_name == 'discount_percent') {
            if (is_number(e.target.value)) {
                const value = e.target.value;
                const total_price = total(product.detail_products);
                product.discount = total_price * value/100;
                product.discount_percent = value;
                setState({
                    ...state,
                    product: product
                })
            }
        }
    }

    const removeProduct = (index) => {
        const product = {...state.product};
        product.detail_products.splice(index, 1);
        setState({
            ...state,
            product: product
        })
    }

    const total = (data) => {
        if (data.length > 0) {
            return data.map(item => item.price * item.amount).reduce((prev, next) => prev + next);
        }

        return 0;
    }

    const exportBillOrderPicked = () => {
        const list_order_ids = [];
        const orders = props.state.orders.map((value, index) => {
            if (value.is_checked) {
                list_order_ids.push(value.id);
            }
        })
        if (list_order_ids.length == 0) {
            Swal.fire({
                title: t("order.message.selectOrder"),
                icon: 'warning',
                timer: 2000
            })
        } else {
            setState({
                ...state,
                dialog_create_bill: true
            })
            // const requestOptions = {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': 'Bearer ' + props.cookie
            //     },
            //     body: JSON.stringify({
            //         list_order_ids: list_order_ids
            //     })
            // };
            // fetch(api.create_bill_picked, requestOptions)
            // .then(res => res.json())
            // .then(
            //     (result) => {
            //         if (result.success) {}
            //     }
            // )
        }
    }

    const date = new Date();
    const date_now = date.getFullYear() + '-' + convertNumber(date.getMonth()  + 1) + '-' + convertNumber(date.getDate());
    const date_start = date.getFullYear() + '-' + convertNumber(date.getMonth()  + 1) + '-01';
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth()+1, 1);
    const date_end = date.getFullYear() + '-' + convertNumber(date.getMonth()  + 1) + '-' + lastDayOfMonth.getDate();

    return (
        <div>
            <Dialog open={state.dialog_create_bill} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog_create_bill: false})}}>
                <DialogTitle>{t("order.exportShippingLabel")}</DialogTitle>
                <DialogContent>
                    <TabContext value={state.value}>
                        <Box sx={{ borderBottom: 0.5, borderColor: 'divider', borderColor: 'rgba(0, 0, 0, 0.12)'}}>
                            <TabList onChange={(e, newValue) => setState({...state, value: newValue})} aria-label="lab API tabs example">
                                <Tab className='text-transform' label="GHTK" value="1" />
                                <Tab className='text-transform' label="GHN" value="2" />
                                <Tab className='text-transform' label="VNPOST" value="3" />
                                <Tab className='text-transform' label="Viettel Post" value="4" />
                            </TabList>
                        </Box>
                        <TabPanel style={{padding: '10px 0px'}} value="1">
                            <FormCreateBill
                                cookie={props.cookie}
                                order_id={null}
                                state={props.state}
                                value={state.value}
                                type="multiple-order"
                            />
                        </TabPanel>
                        <TabPanel style={{padding: '10px 0px'}} value="2">
                            <FormCreateBill
                                cookie={props.cookie}
                                order_id={null}
                                state={props.state}
                                value={state.value}
                                type="multiple-order"
                            />
                        </TabPanel>
                        <TabPanel style={{padding: '10px 0px'}} value="3">
                            <FormCreateBill
                                cookie={props.cookie}
                                order_id={null}
                                state={props.state}
                                value={state.value}
                                type="multiple-order"
                            />
                        </TabPanel>
                        <TabPanel style={{padding: '10px 0px'}} value="4">
                            <center>
                                <i>
                                    {t("order.detailsRequiredForShippingLabel")}
                                </i>
                            </center>
                        </TabPanel>
                    </TabContext>
                </DialogContent>
            </Dialog>
            <Dialog open={state.dialog} fullWidth="true" maxWidth='md' onClose={() => {setState({...state, dialog: false,  errors: {}})}}>
                <DialogTitle>{t("order.orderInformation")}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit2(createOrder)}>
                        <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                            <Grid item xs={4}>
                                <TextField
                                    type="date"
                                    {...register2('date')}
                                    defaultValue={date_now}
                                    fullWidth
                                    margin="dense"
                                    id="outlined-required"
                                    label={t("order.label.createDate")}
                                    variant="outlined"
                                    helperText={state.errors.date?.message}
                                    error={state.errors.date?.status}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...register2('name')}
                                    fullWidth
                                    margin="dense"
                                    id="outlined-required"
                                    label={t("order.label.customerName")}
                                    variant="outlined"
                                    helperText={state.errors.name?.message}
                                    error={state.errors.name?.status}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...register2('phone')}
                                    fullWidth
                                    margin="dense"
                                    id="outlined-required"
                                    label={t("order.label.phoneNumber")}
                                    variant="outlined"
                                    helperText={state.errors.phone?.message}
                                    error={state.errors.phone?.status}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    margin="dense"
                                    {...register2('transport', { 
                                        onChange: (e) => changeTransport(e) 
                                    })}
                                    fullWidth
                                    label={t("order.label.shippingCarrier")}
                                    helperText={state.errors.transport?.message}
                                    error={state.errors.transport?.status}
                                >
                                    
                                    {
                                        props.state.transports.map((value, index) => {
                                            return (
                                                <MenuItem value={index}>{value}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    render={(props) => (
                                        <Autocomplete
                                        onChange={(e, value) => {
                                            changeProvince(e, value);
                                            props.field.onChange(value.id);
                                        }}
                                        {...props}
                                        options={state.provinces}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                            margin="dense"
                                            {...params}
                                            label={t("order.label.provinceCity")}
                                            variant="outlined"
                                            helperText={state.errors.province_id?.message}
                                            error={state.errors.province_id?.status}
                                            />
                                        )}
                                        />
                                    )}
                                    name="province_id"
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    render={(props) => (
                                        <Autocomplete
                                        onChange={(e, value) => {
                                            changeDistrict(e, value);
                                            props.field.onChange(value.id);
                                        }}
                                        {...props}
                                        options={state.districts}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                            margin="dense"
                                            {...params}
                                            label={t("order.label.district")}
                                            variant="outlined"
                                            helperText={state.errors.district_id?.message}
                                            error={state.errors.district_id?.status}
                                            />
                                        )}
                                        />
                                    )}
                                    name="district_id"
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    render={(props) => (
                                        <Autocomplete
                                        onChange={(e, value) => {
                                            props.field.onChange(value.id);
                                        }}
                                        {...props}
                                        options={state.wards}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                            margin="dense"
                                            {...params}
                                            label={t("order.label.ward")}
                                            variant="outlined"
                                            helperText={state.errors.ward_id?.message}
                                            error={state.errors.ward_id?.status}
                                            />
                                        )}
                                        />
                                    )}
                                    name="ward_id"
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    {...register2('address')}
                                    fullWidth
                                    margin="dense"
                                    id="outlined-required"
                                    label={t("order.label.addressDetails")}
                                    variant="outlined"
                                    helperText={state.errors.address?.message}
                                    error={state.errors.address?.status}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    margin="dense"
                                    {...register2('payment_method')}
                                    fullWidth
                                    label={t("order.label.payment")}
                                    helperText={state.errors.payment_method?.message}
                                    error={state.errors.payment_method?.status}
                                >
                                    {
                                        props.state.payment_methods.map((value, index) => {
                                            return (
                                                <MenuItem value={index}>{value}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField {...register2('note')} fullWidth margin="dense" id="outlined-required" label={t("order.label.note")} variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    margin="dense"
                                    {...register2('page')}
                                    helperText={state.errors.page?.message}
                                    error={state.errors.page?.status}
                                    fullWidth
                                    label="Page *"
                                >
                                    {
                                        props.state.pages.map((value, index) => {
                                            return (
                                                <MenuItem value={value.id}>{value.name}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    margin="dense"
                                    name="user_id"
                                    {...register2('user_id')}
                                    fullWidth
                                    label={t("order.label.orderCreator")}
                                    helperText={state.errors.user_id?.message}
                                    error={state.errors.user_id?.status}
                                >
                                    {
                                        props.state.employees.map((value, index) => {
                                            return (
                                                <MenuItem value={value.id}>{value.name}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Dialog open={state.dialog_product} fullWidth="true" maxWidth='lg' onClose={() => {setState({...state, dialog_product: false})}}>
                                        <DialogTitle>{t("order.productList")}</DialogTitle>
                                            <DialogContent>
                                                <Product
                                                    cookie={props.cookie}
                                                    products={props.state.products}
                                                    addToCart={addToCart}
                                                />
                                            </DialogContent>
                                    </Dialog>
                                    <Table aria-label="simple table" className="table-cart">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="right" colSpan={5}>
                                                    <Button onClick={() => setState({...state, dialog_product: true})} startIcon={<AddRoundedIcon/>} sx={{textAlign: "left", textTransform: "unset"}} size="small" variant="contained">
                                                        {t("order.product")}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>{t("order.product")}</TableCell>
                                                <TableCell align="center">{t("order.quantity")}</TableCell>
                                                <TableCell align="right">{t("order.priceVND")}</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {state.product.detail_products.map((item, key) => (
                                                <TableRow
                                                key={key}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell scope="row">
                                                        {item.product.name}
                                                        {
                                                            item.product.attribute != null && item.product.attribute != ''
                                                            ?
                                                            ' (' + item.product.attribute + ')'
                                                            :
                                                            ''
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <TextField
                                                            size="small"
                                                            onChange={(e) => changeNumber(key, e)}
                                                            id="standard-helperText"
                                                            variant="outlined"
                                                            value={item.amount}
                                                            type="number"
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <b>
                                                            {Number(item.price * item.amount).toLocaleString()}
                                                        </b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <ClearRoundedIcon onClick={() => removeProduct(key)} className="cursor" size="small" color="error"/>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <b>{t("order.additionalCharge")}</b>
                                                </TableCell>
                                                <TableCell colSpan={2}>
                                                    <TextField name="extra" onChange={changeOrder} fullWidth size="small" id="outlined-basic" variant="outlined" value={state.product.extra}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <b>{t("order.discount")}</b>
                                                </TableCell>
                                                <TableCell>
                                                    <TextField size="small" onChange={changeOrder} id="outlined-basic" name="discount" value={state.product.discount} label={t("order.label.amount")} variant="outlined" />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField size="small" onChange={changeOrder}  id="outlined-basic" value={state.product.discount_percent} name="discount_percent" label="%" variant="outlined" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <b>{t("order.totalAmount")}</b>
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <b style={{fontSize: '30px', color: "rgb(236, 136, 0)"}}>
                                                        {Number(total(state.product.detail_products) + parseFloat(state.product.extra) - parseFloat(state.product.discount)).toLocaleString()}
                                                    </b>
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </TableRow>                               
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton type="submit" size="large" fullWidth loading={state.loading_submit} variant="contained">
                                    {t("order.saveOrder")}
                                </LoadingButton>
                                {/* <Button fullWidth type="submit" variant='contained' size="large">
                                    Lưu đơn hàng
                                </Button> */}
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <form onSubmit={handleSubmit(searchOrder)}>
                <Grid container spacing={2}>
                    <Grid item xs={2} md={2}>
                        <TextField {...register('key')} fullWidth size="small" id="outlined-basic" label={t("order.label.namePhoneOrderCode")} variant="outlined" />
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <TextField {...register('date_start')} defaultValue={date_start} fullWidth size="small" type="date" id="outlined-basic" label={t("order.label.from")} variant="outlined" />
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <TextField {...register('date_end')} defaultValue={date_now} fullWidth size="small" type="date" id="outlined-basic" label={t("order.label.to")} variant="outlined" />
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            {...register('status')}
                            size="small"
                            fullWidth
                            label={t("order.label.status")}
                            defaultValue={-1}
                        >
                            <MenuItem value={-1}>{t("order.all")}</MenuItem>
                            {
                                props.state.order_status.map((value, index) => {
                                    return (
                                        <MenuItem value={index}>{value.name}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <TextField
                            {...register('fanpage_id')}
                            id="outlined-select-currency"
                            select
                            size="small"
                            fullWidth
                            label="Page"
                            defaultValue={-1}
                        >
                            <MenuItem value={-1}>{t("order.all")}</MenuItem>
                            {
                                props.state.pages.map((value, index) => {
                                    return (
                                        <MenuItem value={value.id}>{value.name}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <TextField
                            {...register('payment_method')}
                            id="outlined-select-currency"
                            select
                            size="small"
                            fullWidth
                            label={t("order.label.payment")}
                            defaultValue={-1}
                        >
                            <MenuItem value={-1}>{t("order.all")}</MenuItem>
                            {
                                props.state.payment_methods.map((value, index) => {
                                    return (
                                        <MenuItem value={index}>{value}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Grid>
                    
                    <Grid item xs={2} md={2}>
                        <TextField
                            {...register('transport')}
                            id="outlined-select-currency"
                            select
                            size="small"
                            fullWidth
                            label={t("order.label.shippingCarrier")}
                            defaultValue={-1}
                        >
                            <MenuItem value={-1}>{t("order.all")}</MenuItem>
                            {
                                props.state.transports.map((value, index) => {
                                    return (
                                        <MenuItem value={index}>{value}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <TextField
                            {...register('status_transport')}
                            id="outlined-select-currency"
                            select
                            size="small"
                            fullWidth
                            label={t("order.label.generateShippingLabel")}
                            defaultValue={-1}
                        >
                            <MenuItem value={-1}>{t("order.all")}</MenuItem>
                            <MenuItem value="1">{t("order.shipped")}</MenuItem>
                            <MenuItem value="0">{t("order.notShipped")}</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <TextField
                            {...register('sale')}
                            id="outlined-select-currency"
                            select
                            size="small"
                            fullWidth
                            label="Sale"
                            defaultValue={-1}
                        >
                            <MenuItem value={-1}>{t("order.all")}</MenuItem>
                            {
                                props.state.employees.map((value, index) => {
                                    return (
                                        <MenuItem value={value.id}>{value.name}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <LoadingButton
                            type='submit'
                            loading={state.loading_search}
                            startIcon={<SearchRoundedIcon/>}
                            style={{marginRight: '5px'}}
                            className="text-transform"
                            variant="contained">
                                {t("common.search")}
                        </LoadingButton>
                        <LoadingButton
                            type="reset"
                            loading={state.loading_reset}
                            startIcon={<RefreshRoundedIcon/>}
                            style={{marginRight: '5px'}}
                            className="text-transform"
                            color="error"
                            variant="contained"
                            onClick={resetForm}
                        >
                               {t("order.reset")}
                        </LoadingButton>
                        <LoadingButton
                            loading={state.loading_export}
                            startIcon={<FileDownloadRoundedIcon/>}
                            style={{marginRight: '5px'}}
                            onClick={handleSubmit(exportOrder)}
                            className="text-transform"
                            color="success"
                            variant="contained">
                               {t("order.exportExcel")}
                        </LoadingButton>
                        <LoadingButton
                            onClick={() => setState({...state, dialog: true})}
                            loading={false}
                            startIcon={<AddRoundedIcon/>}
                            style={{marginRight: '5px'}}
                            className="text-transform"
                            color="warning"
                            variant="contained"
                        >{t("common.add")}</LoadingButton>
                        {/* <LoadingButton
                            onClick={exportBillOrderPicked}
                            startIcon={<FileDownloadRoundedIcon/>}
                            className="text-transform"
                            color="secondary"
                            variant="contained"
                        >Xuất vận đơn</LoadingButton> */}
                    </Grid>
                </Grid>
            </form>
        </div>

    )
}

export default Filter;