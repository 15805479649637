import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  fabClasses,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
import api from "./../../../../Apis";
import { useSearchParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
export default function Index() {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [monthNumber, setMonthNumber] = useState(3);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["is_login"]);
  const [code, setCode] = useState("");
  const [loadingPage, setLoadingPage] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const onChangeMonthNumber = (e) => {
    setMonthNumber(e.target.value);
  };
  useEffect(() => {
    if (searchParams.get("paymentId")) {
      setLoadingPage(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies.is_login,
        },
        body: JSON.stringify({
          paymentId: searchParams.get("paymentId"),
          token: searchParams.get("token"),
          PayerID: searchParams.get("PayerID"),
        }),
      };
      fetch(api.update_status_after_payment, requestOptions)
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            setLoadingPage(false);
            Swal.fire({
              title: result.message,
              icon: "success",
            });
          } else {
            setLoadingPage(false);
            Swal.fire({
              title: result.message,
              icon: "warning",
            });
          }
        });
    }
  }, [searchParams.get("paymentId")]);

  const createPayment = () => {
    if (monthNumber < 3) {
      Swal.fire({
        title: "Minimum 3 months required.",
        icon: "warning",
      });
    } else {
      setPaymentLoading(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies.is_login,
        },
        body: JSON.stringify({
          month_number: monthNumber,
          purchase_code: code,
        }),
      };
      fetch(api.payment_with_paypal, requestOptions)
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            window.location.href = result.url;
          } else {
            setPaymentLoading(false);
            Swal.fire({
              title: result.message,
              icon: "warning",
            });
          }
        });
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingPage}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={isOpenDialog}
        fullWidth="true"
        maxWidth="xs"
        onClose={() => setIsOpenDialog(false)}
      >
        <DialogTitle>Time</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ marginTop: "10px" }}
            min="3"
            onChange={onChangeMonthNumber}
            value={monthNumber}
            size="small"
            type="number"
            fullWidth="true"
            label="Month"
            variant="outlined"
          />
          <LoadingButton
            onClick={createPayment}
            className="text-transform"
            sx={{ marginTop: "10px" }}
            loading={paymentLoading}
            variant="contained"
          >
            Submit
          </LoadingButton>
        </DialogContent>
      </Dialog>
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pricing
        </Typography>
      </Container>
      <Container component="main" maxWidth="md">
        <Grid container spacing={5} sx={{ justifyContent: "center" }}>
          <Grid item key="" md={4}>
            <Card>
              <CardHeader
                title="Basic"
                // subheader={priceItem.code == 'grow' ? 'Phổ biến' : ''}
                titleTypographyProps={{ align: "center" }}
                // action={priceItem.code === 'grow' ? <StarIcon /> : null}
                subheaderTypographyProps={{
                  align: "center",
                }}
                sx={{
                  backgroundColor: "#E0E3E7",
                  color: "#007FFF",
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2,
                  }}
                >
                  <Typography component="h2" variant="h4">
                    <b>29$</b>
                  </Typography>
                  <br></br>
                  <Typography variant="h6" color="text.secondary">
                    /month
                  </Typography>
                </Box>
                <ul style={{ paddingLeft: "0px" }}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    sx={{ display: "flex", alignItems: "center", gap: "10px " }}
                  >
                    <CheckIcon />
                    <span>
                      <b>100</b> Facebook Pages{" "}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    sx={{ display: "flex", alignItems: "center", gap: "10px " }}
                  >
                    <CheckIcon />
                    <span>Unlimited employees</span>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    sx={{ display: "flex", alignItems: "center", gap: "10px " }}
                  >
                    <CheckIcon />
                    <span>Unlimited posts</span>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    sx={{ display: "flex", alignItems: "center", gap: "10px ", "opacity": 0 }}
                  >
                    <CheckIcon />
                    <span>7/24 Support</span>
                  </Typography>
                </ul>
              </CardContent>
              <CardActions>
                <Button
                  className="text-transform"
                  onClick={() => {
                    setIsOpenDialog(true);
                    setCode("pro");
                  }}
                  color="primary"
                  fullWidth
                  variant="contained"
                >
                  Payment
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item key="" md={4}>
            <Card>
              <CardHeader
                title="Grow"
                // subheader={priceItem.code == 'grow' ? 'Phổ biến' : ''}
                titleTypographyProps={{ align: "center" }}
                // action={priceItem.code === 'grow' ? <StarIcon /> : null}
                subheaderTypographyProps={{
                  align: "center",
                }}
                sx={{
                  backgroundColor: "#E0E3E7",
                  color: "#007FFF",
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2,
                  }}
                >
                  <Typography component="h2" variant="h4">
                    <b>79$</b>
                  </Typography>
                  <br></br>
                  <Typography variant="h6" color="text.secondary">
                    /month
                  </Typography>
                </Box>
                <ul style={{ paddingLeft: "0px" }}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    sx={{ display: "flex", alignItems: "center", gap: "10px " }}
                  >
                    <CheckIcon />
                    <span>
                      <b>500</b> Facebook Pages
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    sx={{ display: "flex", alignItems: "center", gap: "10px " }}
                  >
                    <CheckIcon />
                    <span>Unlimited employees</span>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    sx={{ display: "flex", alignItems: "center", gap: "10px " }}
                  >
                    <CheckIcon />
                    <span>Unlimited posts</span>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    sx={{ display: "flex", alignItems: "center", gap: "10px " }}
                  >
                    <CheckIcon />
                    <span>7/24 Support</span>
                  </Typography>
                </ul>
              </CardContent>
              <CardActions>
                <Button
                  onClick={() => {
                    setIsOpenDialog(true);
                    setCode("grow");
                  }}
                  className="text-transform"
                  color="primary"
                  fullWidth
                  variant="contained"
                >
                  Payment
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item key="" md={4}>
            <Card>
              <CardHeader
                title="Enterprise"
                // subheader={priceItem.code == 'grow' ? 'Phổ biến' : ''}
                titleTypographyProps={{ align: "center" }}
                // action={priceItem.code === 'grow' ? <StarIcon /> : null}
                subheaderTypographyProps={{
                  align: "center",
                }}
                sx={{
                  backgroundColor: "#E0E3E7",
                  color: "#007FFF",
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2,
                  }}
                >
                  <Typography component="h2" variant="h4">
                    <b>119$</b>
                  </Typography>
                  <br></br>
                  <Typography variant="h6" color="text.secondary">
                    /month
                  </Typography>
                </Box>
                <ul style={{ paddingLeft: "0px" }}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    sx={{ display: "flex", alignItems: "center", gap: "10px " }}
                  >
                    <CheckIcon />
                    <span>
                      <b>1.000</b> Facebook Pages
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    sx={{ display: "flex", alignItems: "center", gap: "10px " }}
                  >
                    <CheckIcon />
                    <span>Unlimited employees</span>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    sx={{ display: "flex", alignItems: "center", gap: "10px " }}
                  >
                    <CheckIcon />
                    <span>Unlimited posts</span>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    sx={{ display: "flex", alignItems: "center", gap: "10px " }}
                  >
                    <CheckIcon />
                    <span>7/24 Support</span>
                  </Typography>
                </ul>
              </CardContent>
              <CardActions>
                <Button
                  onClick={() => {
                    setIsOpenDialog(true);
                    setCode("enterprise");
                  }}
                  className="text-transform"
                  color="primary"
                  fullWidth
                  variant="contained"
                >
                  Payment
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
