import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";

export default function BlockItem(props) {
    const [state, setState] = useState({
        loading: false
    })

    const sendBlock = () => {
        setState({
            ...state,
            loading: true
        })
        props.sendBlock(props.block);
    }

    return (
        <LoadingButton
        size="small"
        fullWidth
        onClick={sendBlock}
        sx={{marginBottom: '10px'}}
        loading={state.loading}
        loadingIndicator="Đang gửi…"
        variant="outlined"
        >{props.block.name}</LoadingButton>
    )
}