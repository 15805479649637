import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useEffect, useState } from "react";
import { Container,Tab,Box,Grid, Backdrop, CircularProgress } from '@mui/material';
import MessSample from './Components/MessSample';
import Employee from './Components/Employee';
import Info from './Components/Info';
import Tag from './Components/Tag';
import { useCookies } from "react-cookie";
import api from './../../../../Apis';
import Transport from './Components/Transport';
import Warehouse from './Components/Warehouse';
import CouponMessage from './Components/CouponMessage';
import { useParams, useSearchParams } from 'react-router-dom';
import Keyword from './Components/Keyword';
import { useTranslation } from "react-i18next" 

function Index() {
    // const [value, setValue] = useState('1');
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [state, setState] = useState({
        value: '1',
        list_mess_samples: [],
        employees: [],
        roles: [],
        info_shop: {
            'email': '',
            'username': '',
            'full_name': '',
            'phone': '',
        },
        tags: [],
        settings: {
            'commission': '',
            'tag_conversation': ''
        },
        setting_transports: {},
        provinces: [],
        warehouses: [],
        loading_page: true,
        company_transports: {},
        fanpages: [],
        attachments: []
    })
    const handleChange = (event, newValue) => {
        console.log(newValue);
        setState({...state, value: newValue});
    };
    const [cookies, setCookie] = useCookies('is_login');
    const [searchParams, setSearchParams] = useSearchParams();
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const page_type = urlParams.get('id')
    // console.log(page_type);
    
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            }
        };
        fetch(api.get_data_setting + `?lang=${i18n.language}`, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        list_mess_samples: result.mess_samples,
                        employees: result.employees,
                        roles: result.roles,
                        info_shop: result.info_shop,
                        tags: result.tags,
                        settings: result.settings,
                        setting_transports: result.setting_transports,
                        provinces: result.provinces,
                        warehouses: result.warehouses,
                        loading_page: false,
                        company_transports: result.company_transports,
                        fanpages: result.fanpages,
                        attachments: result.attachments,
                        value: searchParams.get('value') != undefined && searchParams.get('value') < 7 ? searchParams.get('value') : '1'
                    });
                }
            }
        )
    }, [])
    return (
        <Container maxWidth="xl" className='m-t-40'>
            {
                state.loading_page
                ?
                <Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={state.loading_page}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
                :
                <Grid item xs={12} md={12}>
                    <TabContext value={state.value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab className='text-transform' label={t("setting.tabs.tabTitle1")} value="1" />
                                <Tab className='text-transform' label={t("setting.tabs.tabTitle2")} value="2" />
                                <Tab className='text-transform' label={t("setting.tabs.tabTitle3")} value="3" />
                                {currentLanguage === "vi" && (
                                    <Tab className='text-transform' label={t("setting.tabs.tabTitle4")} value="7" />
                                )}
                                <Tab className='text-transform' label={t("setting.tabs.tabTitle5")} value="4" />
                                {currentLanguage === "vi" && (         
                                    <Tab className='text-transform' label={t("setting.tabs.tabTitle6")} value="5" />
                                )}
                                {currentLanguage === "vi" && (
                                    <Tab className='text-transform' label={t("setting.tabs.tabTitle7")} value="6" />            
                                )}
                                <Tab className='text-transform' label={t("setting.tabs.tabTitle8")} value="8" />
                            </TabList>
                        </Box>
                        <Info cookie={cookies.is_login} info_shop={state.info_shop}/>
                        <Employee fanpages={ state.fanpages } cookie={cookies.is_login} roles={state.roles} employees={state.employees}/>
                        <MessSample
                            value={state.value}
                            list_mess_samples={state.list_mess_samples}
                            cookie={cookies.is_login}
                            attachments={state.attachments}
                        />
                        <CouponMessage 
                            list_mess_samples={state.list_mess_samples}
                            cookie={cookies.is_login}
                            fanpages={state.fanpages}
                        />
                        <Tag
                            settings={state.settings}
                            cookie={cookies.is_login}
                            tags={state.tags}
                            fanpages={state.fanpages}
                            list_mess_samples={state.list_mess_samples}
                        />
                        <Transport 
                            state={state}
                            cookie={cookies.is_login}
                        />
                        <Warehouse
                            state={state}
                            cookie={cookies.is_login}
                        />
                        <Keyword
                            value={state.value}
                            cookie={cookies.is_login}
                        />
                    </TabContext>
                </Grid>
            }
            
        </Container>
    )
}

export default Index;