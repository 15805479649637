import { useEffect, useState } from "react"
import api from '../../../../../Apis'
import Swal from "sweetalert2"
import { Dialog, DialogTitle, TextField } from "@mui/material"
import ObjectPicture from "./../../../../../Assets/Images/picture.png";
import FileManager from "../../../../Globals/FileManager";
import { useTranslation } from "react-i18next"
import AddImage from "../../Fanpages/Posts/Components/AddImage";

export default function ContentTemplateGallery(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        title: '',
        description: '',
        image: '',
        url: '',
    })
    const [dialog, setDialog] = useState(false);

    useEffect(() => {
        setState({
            ...state,
            title: props.data.title,
            description: props.data.description,
            image: props.data.image,
            url: props.data.url
        })
    }, [])

    const changeValue = (e, key) => {
        const old_state = {...state};
        old_state[key] = e.target.value;
        setState(old_state);
    }

    const updateValue = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                title: state.title,
                description: state.description,
                image: state.image,
                url: state.url,
                key: props.key_gallery,
                code: props.code,
                lang: i18n.language
            })
        };
        fetch(api.update_content_template_gallery, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'warning',
                        timer: 1500
                    })
                }
            }
        )
    }

    const updateImagePicked = (data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                image: data,
                key: props.key_gallery,
                code: props.code,
                lang: i18n.language
            })
        };
        fetch(api.update_content_template_gallery, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'warning',
                        timer: 1500
                    })
                } else {
                    setDialog(false);
                    setState({
                        ...state,
                        image: data
                    })
                }
            }
        )
    }

    return (
        <div>
            <Dialog
                    fullWidth="true"
                    maxWidth='lg'
                    open={dialog}
                    onClose={() => setDialog(false)}
                >
                <DialogTitle sx={{display: "flex", justifyContent: "space-between"}}>
                    {t("chatbot.buildScript.shopImages")}
                </DialogTitle>
                <AddImage
                updateImagePicked={updateImagePicked}
                cookie={props.cookie}
                hasUploadVideo={false}
                />
                {/* <FileManager
                    cookie={props.cookie}
                    fanpage_id={props.fanpage_id}
                    responseUrlImage={responseUrlImage}
                /> */}
            </Dialog>
            <center className="cursor" onClick={() => setDialog(true)}>
                {
                    state.image != '' &&
                    <p>
                        <img style={{width: '100%', height: '100px', objectFit: 'cover'}} src={state.image}></img>
                    </p>
                }
                <img src={ObjectPicture}></img><br></br>
                {t("chatbot.buildScript.chooseImage")}
            </center>
            <textarea
                style={{marginTop: '10px'}}
                placeholder={t("chatbot.buildScript.mainTitle")}
                className="textarea"
                value={state.title}
                rows="3"
                onBlur={updateValue}
                onChange={(e) => changeValue(e, 'title')}
            >
            </textarea>
            <br></br>
            <textarea 
                onChange={(e) => changeValue(e, 'description')}
                value={state.description}
                style={{marginTop: '10px'}}
                placeholder={t('chatbot.buildScript.subTitleOrDescription')} className="textarea" rows="3"
                onBlur={updateValue}
            ></textarea>
            <br></br>
            <TextField
                label={t("chatbot.buildScript.websiteLink")}
                margin="dense"
                onChange={(e) => changeValue(e, 'url')}
                autoComplete="off"
                onBlur={updateValue}
                type="text"
                fullWidth
                value={state.url}
            />
        </div>
    )        
}