import LoadingButton from "@mui/lab/LoadingButton";
import { Autocomplete, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { requestOptions } from "../../../../../Helpers/function";
import api from './../../../../../Apis'
import {
    Link
} from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next'

export default function SendMessage(props) {
    const { t, i18n } = useTranslation();
    const fanpages = props.fanpages;
    const {register, handleSubmit, reset, control} = useForm();
    const [state, setState] = useState({
        type_message: 24,
        total_customer: null,
        loading_check: false,
        loading_submit: false,
        type_time: ''
    })

    const storeCampaign = (data) => {
        setState({
            ...state,
            total_customer: null,
            loading_submit: true
        })
        data['fanpage_id'] = props.fanpage_id;
        data['lang'] = i18n.language;
        fetch(api.store_campaign, requestOptions('POST', props.cookie, data))
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        loading_submit: false
                    })
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'warning',
                        timer: 1500
                    })
                    setState({
                        ...state,
                        loading_check: false
                    })
                }
            }
        )
    }

    const onChangeTypeMessage = (e) => {
        setState({
            ...state,
            type_message: e.target.value
        })
    }

    const getTotalCustomerReciveBroadcast = (data) => {
        setState({
            ...state,
            total_customer: null,
            loading_check: true
        })
        const body = {
            type_message: data.type_message,
            fanpage_id: props.fanpage_id,
            lang: i18n.language
        }
        fetch(api.get_total_customer_recive_broadcast, requestOptions('POST', props.cookie, body))
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        total_customer: result.total_customer,
                        loading_check: false
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'warning',
                        timer: 1500
                    })
                    setState({
                        ...state,
                        loading_check: false
                    })
                }
            }
        )
    }

    const onChangeTypeTime = (e) => {
        setState({
            ...state,
            type_time: e.target.value
        })
    }

    const getHours = () => {
        const options = [];
        for (var hour = 0; hour <= 23; hour++) {
            options.push(
                <MenuItem value={hour}>{hour} {t("common.time.hour")}</MenuItem> 
            )
        }

        return options;
    }

    const getMinutes = () => {
        const options = [];
        for (var minute = 0; minute <= 59; minute++) {
            options.push(
                <MenuItem value={minute}>{minute} {t("common.time.minute")}</MenuItem> 
            )
        }

        return options;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={5} md={5}>
                <p style={{color: 'red', marginTop: '0px'}}>
                    {t("customer.readCarefully")}
                </p>
                <form onSubmit={handleSubmit(storeCampaign)}>
                    <TextField
                        id="outlined-select-currency"
                        size="small"
                        fullWidth
                        label={t("customer.label.campaignName")}
                        {...register('campaign_name')}
                    >
                    </TextField>
                    <FormControl fullWidth sx={{marginTop: '10px'}}>
                        <FormLabel id="demo-radio-buttons-group-label">{t("customer.label.messageType")}</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={state.type_message}
                            name="radio-buttons-group"
                            onChange={onChangeTypeMessage}
                        >
                            <FormControlLabel control={<Radio {...register('type_message')} value="24" />} label={t("customer.label.messageStandard24h")} />
                            <FormControlLabel control={<Radio {...register('type_message')} value="25" />} label={t("customer.label.messageCustomerCare")} />
                        </RadioGroup>
                    </FormControl>
                    {
                        state.type_message == 25 &&
                        <FormControl size="small" fullWidth sx={{marginTop: '10px', marginBottom: '10px'}}>
                            <InputLabel id="demo-simple-select-label">{t("customer.label.messageTag")}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t("customer.label.messageTag")}
                                {...register('message_tag')}
                            >
                                <MenuItem value="ACCOUNT_UPDATE">{t("customer.accountUpdate")}</MenuItem>
                                <MenuItem value="CONFIRMED_EVENT_UPDATE">{t("customer.confirmedEventUpdate")}</MenuItem>
                                <MenuItem value="POST_PURCHASE_UPDATE">{t("customer.postPurchaseUpdate")}</MenuItem>
                            </Select>
                        </FormControl>
                    }
                    <FormControl size="small" fullWidth sx={{marginTop: '10px'}}>
                        <InputLabel id="demo-simple-select-label">{t("customer.label.selectBlockToSend")}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t("customer.label.selectBlockToSend")}
                            {...register('block_id')}
                        >
                            {
                                props.blocks.map((blockItem, key) => {
                                    return (
                                        <MenuItem value={blockItem.code}>{blockItem.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                        <small>
                            {/* Thêm mới block <Link to="/dashboard/chatbot">tại đây</Link> */}
                            <Trans
                                i18nKey={"customer.addNewBlock"}
                                components={{ 1: <Link to="/dashboard/chatbot" /> }}
                            />
                        </small>
                    </FormControl>
                    {/* <textarea style={{marginTop: '20px'}} {...register('message')} rows="10" placeholder="Tin nhắn gửi cho khách" className="textarea"></textarea> */}
                    <FormControl sx={{marginTop: '10px'}} fullWidth >
                        <FormLabel id="demo-radio-buttons-group-label">{t("customer.label.sendTime")}</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="now"
                            name="radio-buttons-group"
                            onChange={onChangeTypeTime}
                        >
                            <FormControlLabel value="now" control={<Radio {...register('type_time')}/>} label={t('customer.label.sendNow')} />
                            <FormControlLabel value="custom" control={<Radio {...register('type_time')} />} label={t("customer.label.schedule")} />
                        </RadioGroup>
                    </FormControl>
                    {
                        state.type_time == 'custom'
                        &&
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    fullWidth
                                    size="small"
                                    defaultValue={0}
                                    {...register('hour')}
                                >
                                    {getHours()}
                                </Select>
                            </Grid>
                            <Grid item xs={3}>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    fullWidth
                                    size="small"
                                    defaultValue={0}
                                    {...register('minute')}
                                >
                                    {getMinutes()}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type="date" {...register('date')} fullWidth size="small" id="outlined-basic" variant="outlined" />
                            </Grid>
                        </Grid>
                    }
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <LoadingButton loading={state.loading_submit} type="submit" sx={{marginTop: '10px'}} variant="contained" className="text-transform">{t("customer.confirmSend")}</LoadingButton>
                        <LoadingButton loading={state.loading_check} type="button" onClick={handleSubmit(getTotalCustomerReciveBroadcast)} color="warning" sx={{marginTop: '10px', marginLeft: '10px'}} variant="contained" className="text-transform">{t("customer.checkRecipientsCount")}</LoadingButton>
                        {
                            state.total_customer != null &&
                            <span style={{marginLeft: '10px'}}>
                                {/* Tổng khách nhận được là: <b>{state.total_customer}</b> */}
                                <Trans
                                    i18nKey={"customer.totalCustomersReceived"}
                                    values={{ totalCustomer: state.total_customer }}
                                    components={{ b: <b /> }}
                                />
                            </span>
                        }
                    </div>
                </form>
            </Grid>
            <Grid item xs={1} md={1}></Grid>
            <Grid item xs={6} md={6}>
                <div style={{maxHeight: '700px', overflow: 'auto'}}>
                    <h3 style={{marginTop: '0px'}}>
                        {t("customer.note.title")}
                    </h3>
                    <p>
                        {/* { process.env.REACT_APP_NAME } chỉ cung cấp công cụ, giải pháp và sẽ không chịu trách nhiệm bất cứ vấn đề gì liên quan đến Fanpage của bạn, ví dụ như: page bị vi phạm chính sách Facebook, page bị khóa,..... */}
                        {t("customer.note.content1", {
                            appName: process.env.REACT_APP_NAME
                        })}
                    </p>
                    <p>
                        {/* Bạn nên thêm biến <b>{'{full_name}'}</b> trong chiến dịch hoặc sử dụng SPIN CONTENT để tránh nội dung giống hệt nhau để hạn chế tỷ lệ bị đánh dấu spam. */}
                        <Trans
                            i18nKey={"customer.note.content2"}
                            components={{ b: <b /> }}
                        />
                        
                    </p>
                    <p>
                    {/* <b>- Tin nhắn tiêu chuẩn 24h</b>: gửi cho những khách hàng nhắn tin với page trong vòng 24h. Với loại tin nhắn này, bạn được phép gửi nội dung chứa quảng cáo. */}
                    <Trans
                        i18nKey={"customer.note.content3"}
                        components={{ b: <b /> }}
                    />
                    </p>
                    <p>
                    {/* <b>- Tin nhắn CSKH</b>: gửi cho cả những khách hàng tương tác trong và ngoài 24h. Với loại tin nhắn này, bạn không được gửi nội dung chứa quảng cáo như các từ: Khuyến mãi, Sale off, Giảm giá, Deal, Coupon, Voucher, sale off, survey,... Khi gửi loại tin nhắn này bạn phải lựa chọn Message Tag (thẻ Tag Facebook) phù hợp với nội dung chiến dịch.  */}
                    <Trans
                        i18nKey={"customer.note.content4"}
                        components={{ b: <b /> }}
                    />
                    </p>
                    <p>
                        {t("customer.note.content5")}
                    {/* - Chiến dịch không được phép gửi trong khoảng thời gian từ 23h - 6h do chính sách của facebook tránh làm phiền người dùng, trong khoảng thời gian nghỉ ngơi này nếu gửi sẽ dễ bị FB quét. */}
                    </p>
                    <p>
                    {/* - Cùng 1 tệp khách hàng nên gửi chiến dịch cách nhau từ 7-9 ngày đối với loại tin nhắn CSKH với 3 thẻ (sự kiện-giao dịch-tài khoản). */}
                    {t("customer.note.content6")}
                    </p>
                    <h3>
                        {t("customer.note.content7")}
                    </h3>
                    <p >
                        {/* Khi khách hàng tương tác với Messenger của Page (nhắn tin, thích tin nhắn, truy cập link ref m.me), thì Page có 24 giờ để bán hàng. */}
                        {t("customer.note.content8")}
                    </p>
                    <p >
                        {/* Sau 24 giờ kể từ lần tương tác cuối cùng, bạn không được tin nhắn quảng cáo, bán hàng cho khách hàng này. */}
                        {t("customer.note.content9")}
                    </p>
                    <p >
                        {/* Nhưng, Facebook cho phép Page gửi tin nhắn Chăm sóc khách hàng. Với các tin nhắn này, bạn cần gắn thẻ tin nhắn. */}
                        {t("customer.note.content10")}
                    </p>
                    <p ><strong>{t("customer.note.content11")}</strong></p>
                    <p >
                        {/* Khi gửi gửi tin nhắn 1:1 đến khách hàng đã tương tác với page quá 24 giờ, bạn cần gắn thẻ tin nhắn để phân loại nội dung gửi: */}
                        {t("customer.note.content12")}
                    </p>
                    <p ><strong>{t("customer.note.content13")}&nbsp;</strong></p>
                    <p >
                        {/* Tin nhắn cập nhật cho khách hàng về sự kiện mà họ đã đăng ký, có thể được gửi tới khách hàng bất kỳ lúc nào. */}
                        {t("customer.note.content14")}
                    </p>
                    <p >{t("customer.note.content15")}</p>
                    <ul>
                        <li >{t("customer.note.content16")}</li>
                        <li >{t("customer.note.content17")}</li>
                        <li >{t("customer.note.content18")}</li>
                    </ul>
                    <p ><strong>
                       {t("customer.note.content19")} 
                        </strong></p>
                    <p >
                        {t("customer.note.content20")}
                    </p>
                    <p >{t("customer.note.content15")}</p>
                    <ul>
                        <li >{t("customer.note.content21")}</li>
                        <li >{t("customer.note.content22")}</li>
                        <li >{t("customer.note.content23")}</li>
                    </ul>
                    <p ><strong>{t("customer.note.content24")}</strong></p>
                    <p >
                        {t("customer.note.content25")}
                    </p>
                    <p >{t("customer.note.content15")}</p>
                    <ul>
                        <li >{t("customer.note.content26")}</li>
                        <li >{t("customer.note.content27")}</li>
                        <li >{t("customer.note.content28")}</li>
                        <li>{t("customer.note.content29")}</li>
                    </ul>
                    <p >{t("customer.note.content30")}</p>
                    <ul>
                        <li >{t("customer.note.content31")}</li>
                        <li >{t("customer.note.content32")}</li>
                    </ul>
                    <p >{t("customer.note.content33")}</p>
                    <p ><em><strong>{t("customer.note.content15")}</strong></em></p>
                    <p >{t("customer.note.content34")}</p>
                    <p >{t("customer.note.content35")}</p>
                    <p >{t("customer.note.content36")}</p>
                    <p >{t("customer.note.content37")}</p>
                    <p >{t("customer.note.content38")}</p>
                    <p >{t("customer.note.content39")}</p>
                    <p >{t("customer.note.content40")}</p>
                    <p >&nbsp;</p>
                    <p >{t("customer.note.content41")}</p>
                    <ul>
                        <li >{t("customer.note.content42")}</li>
                        <li >{t("customer.note.content43")}</li>
                        <li >{t("customer.note.content44")}</li>
                    </ul>
                </div>
            </Grid>
        </Grid>
    )
}
