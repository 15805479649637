import { Button } from "@mui/material";
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import { useRef, useEffect, useState } from "react";
import Swal from "sweetalert2";
import LoadingButton from '@mui/lab/LoadingButton';
import api from "../../Apis";
import { useTranslation } from 'react-i18next';

export default function ButtonUploadImage(props) {
    const { t, i18n } = useTranslation();
    const selectFile = (e) => {
        inputFile.current.click();
    }
    const inputFile = useRef(null);
    const [state, setState] = useState({
        loading: false
    })

    const onChangeFile = (e) => {
        const file = e.target.files;
        if (file.length > 0) {
            setState({
                ...state,
                loading: true
            })
            const formData = new FormData();
            for (var i = 0; i < file.length; i++) {
                formData.append('file[]', file[i]);
            }
            formData.append('fanpage_id', props.fanpage_id);
            formData.append('lang', i18n.language);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: formData
            };
            fetch(api.upload_attachment, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success == true) {
                        props.updateAttachment(result.datas);
                    } else if (result.success == false && result.type == 'validate') {
                        if (result.errors.length > 0) {
                            var string_error = '';
                            result.errors.map((value, key) => {
                                string_error = string_error + '<p>' + value + '</p>';
                            })
                            Swal.fire({
                                title: string_error,
                                icon: 'warning',
                                confirmButtonText: t("common.exit")
                            })
                        }
                    } else if (result.success == false && result.type == 'error') {
                        Swal.fire({
                            title: result.message,
                            icon: 'error',
                            confirmButtonText: t("common.exit")
                        })
                    }
                    setState({
                        ...state,
                        loading: false
                    })
                }
            )
        }
    }

    return (
        <LoadingButton loading={state.loading} onClick={selectFile} sx={{
            marginRight: '20px'
            }}
            variant="outlined" color="primary" startIcon={<UploadRoundedIcon />}>
           {t("common.upload.uploadImage")}
            <input style={{display: 'none'}} ref={inputFile} onChange={onChangeFile} multiple type="file" className='file'/>
        </LoadingButton>
    )
}