import TabPanel from "@mui/lab/TabPanel";
import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ProvinceDistrictWard from "../../../../Globals/ProvinceDistrictWard";
import Swal from "sweetalert2";
import api from './../../../../../Apis'
import DeleteIcon from '@mui/icons-material/Delete';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import LoadingButton from "@mui/lab/LoadingButton";
import {getAddress} from './../../../../../Helpers/function'
import { useTranslation } from "react-i18next"; 

function Warehouse(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        dialog: false,
        warehouses: [],
        status_edit: false,
        transport_id: '',
        errors: {},
        data_edit: {
            name: '',
            full_name: '',
            phone: '',
            address: '',
            province: {
                id: '',
                name: ''
            },
            district: {
                id: '',
                name: ''
            },
            ward: {
                id: '',
                name: ''
            },
            province_id: '',
            district_id: '',
            ward_id: '',
            transport_id: ''
        },
        warehouse_id: '',
        index_edit: '',
        loading: false,
        provinces: [],
        districts: [],
        wards: [],
        transport_id: ''
    })
    const {register, handleSubmit, reset, control} = useForm({
        defaultValues: useMemo(() => {
            return state.data_edit;
        }, [state.data_edit])
    });

    useEffect(() => {
        reset(state.data_edit);
    }, [state.data_edit]);

    const editWarehouse = (data, index) => {
        setState({
            ...state,
            dialog: true,
            provinces: data.provinces,
            districts: data.districts,
            wards: data.wards,
            data_edit: {
                name: data.name,
                full_name: data.full_name,
                phone: data.phone,
                address: data.address,
                province: {
                    name: data.province.name,
                    id: data.province.id
                },
                district: {
                    name: data.district.name,
                    id: data.district.id
                },
                ward: {
                    name: data.ward.name,
                    id: data.ward.id
                },
                ward_id: data.ward.id,
                district_id: data.district.id,
                province_id: data.province.id,
                transport_id: data.transport_id
            },
            warehouse_id: data.id,
            index_edit: index,
            transport_id: data.transport_id
        })
    }

    const createWarehouse = (data) => {
        setState({
            ...state,
            loading: true,
            error: {}
        })
        const url_api = state.warehouse_id != '' ? api.edit_warehouse : api.create_warehouse;
        data['warehouse_id'] = state.warehouse_id;
        data['lang'] = i18n.language;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify(data)
        };
        fetch(url_api, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    if (state.warehouse_id != '') {
                        const copy_warehouses = [...state.warehouses];
                        copy_warehouses[state.index_edit] = result.new_warehouse
                        setState({
                            ...state,
                            warehouses: copy_warehouses,
                            warehouse_id: '',
                            index_edit: '',
                            dialog: false,
                            loading: false,
                            data_edit: {
                                name: '',
                                full_name: '',
                                phone: '',
                                address: '',
                                province: {
                                    id: '',
                                    name: ''
                                },
                                district: {
                                    id: '',
                                    name: ''
                                },
                                ward: {
                                    id: '',
                                    name: ''
                                },
                            }
                        })
                    } else {
                       setState({
                            ...state,
                            dialog: false,
                            loading: false,
                            warehouses: [result.new_warehouse, ...state.warehouses]
                        }) 
                    }
                    
                    Swal.fire({
                        title: result.message,
                        timer: 1500,
                        icon: 'success'
                    });
                } else {
                    if (result.type == 'validate') {
                        // var text_error = '';
                        // result.errors.map((value, key) => {
                        //     text_error+= '<p>' + value + '</p>';
                        // })
                        // Swal.fire({
                        //     title: result.message,
                        //     icon: 'warning',
                        //     html: text_error
                        // })
                        setState({
                            ...state,
                            errors: result.errors,
                            loading: false,
                        })
                    } else if (result.type == 'error') {
                        Swal.fire({
                            title: result.message,
                            timer: 1500,
                            icon: 'error'
                        });
                        setState({
                            ...state,
                            loading: false,
                        })
                    }
                }
            }
        )
    }

    useEffect(() => {
        setState({
            ...state,
            warehouses: props.state.warehouses
        })
    }, [props.state.warehouses])

    const deleteWarehouse = (id, index) => {
        Swal.fire({
            title: t("common.alert.confirmDelete"),
            showDenyButton: true,
            confirmButtonText: t("common.alert.confirm"),
            denyButtonText: t("common.alert.cancel"),
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        warehouse_id: id,
                        lang: i18n.language
                    })
                };
                fetch(api.delete_warehouse, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const copy_warehouses = [...state.warehouses];
                            copy_warehouses.splice(index, 1);
                            setState({
                                ...state,
                                warehouses: copy_warehouses
                            })
                            Swal.fire({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error'
                            })
                        }
                    }
                )
            }
        })
    }

    const onCloseDialog = () => {
        setState({
            ...state,
            dialog: false,
            warehouse_id: '',
            index_edit: '',
            errors: {},
            data_edit: {
                name: '',
                full_name: '',
                phone: '',
                address: '',
                province: {
                    id: '',
                    name: ''
                },
                district: {
                    id: '',
                    name: ''
                },
                ward: {
                    id: '',
                    name: ''
                },
            }
        })
    }

    const changeTransport = (e) => {
        const transport_id = e.target.value;
        const res = getAddress(transport_id, props.cookie, api.get_province_by_transport, null, null, i18n.language);
        res.then(result => {
            setState({
                ...state,
                provinces: result,
                transport_id: transport_id
            })
        })
    }

    return (
        <TabPanel style={{padding: '30px 0px'}} value="6">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h3 className='m-t-0'>
                        {t("setting.warehouseList")}
                    </h3>
                </Grid>
                <Dialog 
                    open={state.dialog}
                    fullWidth="true"
                    maxWidth='sm'
                    onClose={onCloseDialog}
                >
                    <DialogTitle>{t("setting.warehouseInfo")}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleSubmit(createWarehouse)}>
                            <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="dense"
                                        {...register('name')}
                                        autoComplete='off'
                                        fullWidth
                                        size="large"
                                        id="outlined-basic"
                                        label={t("setting.label.warehouseName") + " *"}
                                        variant="outlined"
                                        helperText={state.errors.name?.message}
                                        error={state.errors.name?.status}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth error={state.errors.transport_id?.status}>
                                        <InputLabel id="demo-simple-select-label">{t("setting.label.selectCarrier")}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={t("setting.label.selectCarrier")}
                                            {...register('transport_id', {
                                                onChange: (e) => changeTransport(e) 
                                            })}
                                            defaultValue={state.data_edit.transport_id}
                                        >
                                            {
                                                Object.keys(props.state.company_transports).map(function(key) {
                                                    return (
                                                        <MenuItem value={key}>
                                                            {props.state.company_transports[key]}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <FormHelperText>{state.errors.transport_id?.message}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField 
                                        autoComplete='off'
                                        {...register('full_name')}
                                        fullWidth size="large"
                                        id="outlined-basic"
                                        label={t("setting.label.manager") + " *"}
                                        variant="outlined"
                                        helperText={state.errors.full_name?.message}
                                        error={state.errors.full_name?.status}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        autoComplete='off'
                                        fullWidth
                                        {...register('phone')}
                                        helperText={state.errors.phone?.message}
                                        error={state.errors.phone?.status}
                                        size="large"
                                        id="outlined-basic"
                                        label={t("common.label.phoneNumber") + " *"}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <ProvinceDistrictWard
                                    provinces={state.provinces}
                                    control={control}
                                    state={state}
                                    register={register}
                                    errors={state.errors}
                            />
                            <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                                <Grid item xs={12}>
                                    <TextField
                                        {...register('address')}
                                        helperText={state.errors.address?.message}
                                        error={state.errors.address?.status}
                                        autoComplete='off'
                                        fullWidth size="large"
                                        id="outlined-basic"
                                        label={t("common.label.houseNumberStreet")}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                                <Grid item xs={12}>
                                    <LoadingButton loading={state.loading} type="submit" variant="contained">{t("common.confirm")}</LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                <Grid item xs={12}>
                    <Button onClick={() => setState({...state, dialog: true})} type="submit" startIcon={<AddRoundedIcon/>} className="text-transform" variant="contained">
                        {t("common.add")}
                    </Button>
                </Grid>
                <Grid item xs={12}>                    
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("setting.table.no")}</TableCell>
                                    <TableCell>{t("setting.label.warehouseName")}</TableCell>
                                    <TableCell>{t("setting.label.manager")}</TableCell>
                                    <TableCell>{t("setting.label.shippingCarrier")}</TableCell>
                                    <TableCell>{t("common.label.address")}</TableCell>
                                    <TableCell>{t("common.label.provinceCity")}</TableCell>
                                    <TableCell>{t("common.label.district")}</TableCell>
                                    <TableCell>{t("common.label.wardCommune")}</TableCell>
                                    <TableCell>{t("common.label.phoneNumber")}</TableCell>
                                    <TableCell>{t("setting.action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    state.warehouses.map((value, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell>{ value.name }</TableCell>
                                                <TableCell>{ value.full_name }</TableCell>
                                                <TableCell>{ value.company_transport }</TableCell>
                                                <TableCell>{ value.address }</TableCell>
                                                <TableCell>{ value.province.name }</TableCell>
                                                <TableCell>{ value.district.name }</TableCell>
                                                <TableCell>{ value.ward.name }</TableCell>
                                                <TableCell>{ value.phone }</TableCell>
                                                <TableCell style={{whiteSpace: 'nowrap'}}>
                                                    <EditRoundedIcon onClick={() => editWarehouse(value, index)} className="cursor" color="primary" sx={{marginRight: "10px"}}/>
                                                    <DeleteIcon onClick={() => deleteWarehouse(value.id, index)} className="cursor" color="error"/>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>            
        </TabPanel>
    )
}

export default Warehouse;