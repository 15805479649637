import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, TextField, FormHelperText, Tab  } from "@mui/material"
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import api from './../../../../../Apis';
import Swal from "sweetalert2";
import FormCreateBill from "./FormCreateBill";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Box } from "@mui/system";
import TabList from "@mui/lab/TabList";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CreateBillTransport(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        dialog: false,
        loading_create_bill: false,
        loading_print_bill: false,
        error: {
            transport_id: '',
            warehouse_id: '',
            insurance: ''
        },
        value: String([1,2,3,4].includes(props.state.order.transport) ? props.state.order.transport : "1")
    })
    const {register, handleSubmit, reset, control} = useForm();

    const printBill = () => {
        if (props.state.order.transport == 3 || props.state.order.transport == 4) {
            window.open('/print-bill/' + props.state.order.code_transport, '_blank');
        } else {
            setState({
                ...state,
                loading_print_bill: true
            })
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({
                    order_id: props.order_id,
                    lang: i18n.language
                })
            };
            fetch(api.get_bill_transport, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        setState({
                            ...state,
                            loading_print_bill: false
                        })
                        window.open(result.file, '_blank');
                    } else {
                        Swal.fire({
                            title: result.message,
                            icon: 'error',
                        })
                        setState({
                            ...state,
                            loading_print_bill: false
                        })
                    }
                }
            )
        }
        
    }
    const refreshOrder = (data) => {
        props.refreshOrder(data);
        setState({
            ...state,
            dialog: false
        })
    }

    const printBillOrder = (code) => {
        window.open('/print-bill-order/' + code, '_blank');
    }
    // console.log(props.state.order.transport, 'chinh');
    
    return (
        <p className="t-right">
            <Dialog open={state.dialog} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog: false})}}>
                <DialogTitle className="display-flex space-between">
                    {t("order.createBillTransport.createShipment")}
                    <p className="m-b-0 m-t-0 t-right">
                        <Link className="a-none" to="/dashboard/setting?value=6">
                            <Button className="text-transform" color="success" variant="contained">{t("order.createBillTransport.createWarehouse")}</Button>
                        </Link>
                    </p>
                </DialogTitle>
                <DialogContent>
                    <TabContext value={state.value}>
                        <Box sx={{ borderBottom: 0.5, borderColor: 'divider', borderColor: 'rgba(0, 0, 0, 0.12)'}}>
                            <TabList onChange={(e, newValue) => setState({...state, value: newValue})} aria-label="lab API tabs example">
                                <Tab className='text-transform' label="GHTK" value="1" />
                                <Tab className='text-transform' label="GHN" value="2" />
                                <Tab className='text-transform' label="VNPOST" value="3" />
                                <Tab className='text-transform' label="Viettel Post" value="4" />
                            </TabList>
                        </Box>
                        <TabPanel style={{padding: '10px 0px'}} value="1">
                            <FormCreateBill
                                order_id={props.order_id}
                                state={props.state}
                                cookie={props.cookie}
                                refreshOrder={refreshOrder}
                                value={state.value}
                                type="single-order"
                            />
                        </TabPanel>
                        <TabPanel style={{padding: '10px 0px'}} value="2">
                            <FormCreateBill
                                order_id={props.order_id}
                                state={props.state}
                                cookie={props.cookie}
                                refreshOrder={refreshOrder}
                                value={state.value}
                                type="single-order"
                            />
                        </TabPanel>
                        <TabPanel style={{padding: '10px 0px'}} value="3">
                            <FormCreateBill
                                order_id={props.order_id}
                                state={props.state}
                                cookie={props.cookie}
                                refreshOrder={refreshOrder}
                                value={state.value}
                                type="single-order"
                            />
                        </TabPanel>
                        <TabPanel style={{padding: '10px 0px'}} value="4">
                            <FormCreateBill
                                order_id={props.order_id}
                                state={props.state}
                                cookie={props.cookie}
                                refreshOrder={refreshOrder}
                                value={state.value}
                                type="single-order"
                            />
                        </TabPanel>
                    </TabContext>
                    
                </DialogContent>
            </Dialog>
            <Button 
                style={{marginRight: '10px'}}
                startIcon={<LocalPrintshopRoundedIcon/>}
                variant="contained"
                className="text-transform"
                color="success"
                onClick={() => printBillOrder(props.state.order.code)}
            >
                {t("order.createBillTransport.printInvoice")}
            </Button>
            <Button 
                style={{marginRight: '10px'}}
                startIcon={<LocalShippingRoundedIcon/>}
                variant="contained"
                className="text-transform"
                onClick={() => setState({...state, dialog: true})}
            >
               {t("order.createBillTransport.createShipment")}
            </Button>
            <LoadingButton
                startIcon={<LocalPrintshopRoundedIcon/>}
                loading={state.loading_print_bill}
                variant="contained"
                color="warning"
                className="text-transform"
                onClick={printBill}
            >
                {t("order.createBillTransport.printBillOfLading")}
            </LoadingButton>
            {/* <Button 
                startIcon={<LocalPrintshopRoundedIcon/>}
                variant="contained"
                color="warning"
                className="text-transform"
                onClick={printBill}
            >
                In vận đơn
            </Button> */}
        </p>
    )
}

export default CreateBillTransport;