import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import api from './../../../../Apis'
import parse from 'html-react-parser';
import { Backdrop, CircularProgress, Dialog, DialogContent, DialogTitle, fabClasses, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';

function Price() {
	const [state, setState] = useState({
		prices: [],
		message: '',
		dialog: false,
		dialog_payment: false,
		package_purchase: {},
		amount: 12,
		discount: {},
		banks: [],
		hotline: '',
		username: '',
		loading_button: false,
		purchased: null,
		loading_page: true,
		list_purchase_sended: [],
		code: '',
		percent_discount: 0
	})
	const [cookies, setCookie] = useCookies('is_login');
	const {register, handleSubmit, reset, setValue, getValues} = useForm();
	
	useEffect(() => {
		const requestOptions = {
            method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + cookies.is_login
			},
        };
		fetch(api.price_list, requestOptions)
		.then(res => res.json())
		.then(
			(result) => {
				if (result.success) {
					setState({
						...state,
						prices: result.datas,
						discount: result.discount,
						banks: result.banks,
						hotline: result.hotline,
						username: result.username,
						purchased: result.purchased,
						loading_page: false,
						list_purchase_sended: result.list_purchase_sended
					})
				} else {
					setState({
						...state,
						message: result.message,
						loading_page: false
					})
				}
			}
		)
	}, [])

	const placeOrder = () => {
		const price_payment = total(state.package_purchase) - (state.purchased != null ? state.purchased.balance : 0);

		if (price_payment < 0) {
			Swal.fire({
				title: 'Số tiền cần thanh toán không hợp lệ',
				icon: 'warning',
				timer: 2500
			})
		}  else if (price_payment == 0 && state.purchased.code == state.package_purchase.code) {
			Swal.fire({
				title: 'Bạn đang dùng gói này rồi',
				icon: 'warning',
				timer: 2500
			})
		} else {
			setState({
				...state,
				loading_button: true
			})
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + cookies.is_login
				},
				body: JSON.stringify({
					code: state.package_purchase.code,
					month: state.package_purchase.amount,
					coupon_code: getValues('code')
				})
			};
			fetch(api.purchase, requestOptions)
			.then(res => res.json())
			.then(
				(result) => {
					if (result.success) {
						Swal.fire({
							title: result.message,
							icon: 'success',
							timer: 2000
						})
						setState({
							...state,
							dialog: false,
							loading_button: false,
							list_purchase_sended: [result.data, ...state.list_purchase_sended]
						})
					} else {
						if (result.type == 'validate') {
							const text_html = '';
							result.errors.map((item, key) => {
								text_html+= '<p>' + item + '</p>';
						})
							Swal.fire({
								title: 'Dữ liệu sai',
								icon: 'error',
								html: text_html
							})
						} else {
							Swal.fire({
								title: result.message,
								icon: 'error',
								timer: 2000
							})
						}
						setState({
							...state,
							loading_button: false
						})
					}
				}
			)
		}
		
	}

	const purchase = (data) => {
		data['discount'] = 0;
		data['amount'] = 3;
		setValue('code', '')
		setState({
			...state,
			dialog: true,
			amount: 3,
			package_purchase: data,
			percent_discount: 0
		})
	}

	const total = (data) => {
		return data.price * data.amount - (data.price * data.amount * data.discount/100);
	}

	const handleYearNumber = (e) => {
		const amount = e.target.value;
		const package_purchase = {...state.package_purchase};
		package_purchase.discount = state.discount[amount];
		package_purchase.amount = amount;
		setState({
			...state,
			package_purchase: package_purchase,
			amount: amount
		});
	}

	const getTextPurchase = (priceItem) => {
		if (state.purchased != null) {
			if (state.purchased.code == priceItem.code) {
				return 'Gia hạn';
			} else {
				return 'Chuyển gói';
			}
		}

		return 'Mua ngay';
	}

	const verifyCouponCode = (data) => {
		data['amount'] = state.amount;
		const requestOptions = {
            method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + cookies.is_login
			},
			body: JSON.stringify(data)
        };
		fetch(api.verify_code, requestOptions)
		.then(res => res.json())
		.then(
			(result) => {
				if (result.success) {
					setState({
						...state,
						percent_discount: result.percent
					})
				} else {
					Swal.fire({
						title: result.message,
						icon: 'error'
					})
					setState({
						...state,
						percent_discount: 0
					})
				}
			}
		)
	}

	return (
		<div>
			{
				state.loading_page
				?
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={state.loading_page}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
				:
				state.message != null &&
				<div>
					<Dialog open={state.dialog_payment} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog_payment: false})}}>
						<DialogTitle>Thông tin thanh toán</DialogTitle>
						<Grid container spacing={2} style={{width: "calc(100% - 16px)", marginLeft: '0px'}}>
							{
								state.banks.map((bankItem, index) => {
									return (
										<Grid
											item
											md={6}
										>
											<Card>
												<CardHeader
													title={bankItem.name}
													titleTypographyProps={{ align: 'center' }}
													subheaderTypographyProps={{
														align: 'center',
													}}
													sx={{
														backgroundColor: "#E0E3E7",
														color: "#007FFF"
													}}
												/>
												<CardContent>
													<ul style={{paddingLeft: '10px'}}>
														<Typography
															component="li"
															align="left"
															sx={{fontSize: '14px', marginBottom: '10px'}}
														>
															Chủ TK: <b>{bankItem.owner}</b>
														</Typography>
														<Typography
															component="li"
															align="left"
															sx={{fontSize: '14px', marginBottom: '10px'}}
														>
															Stk: <b>{bankItem.account}</b>
														</Typography>
														<Typography
															component="li"
															align="left"
															sx={{fontSize: '14px', marginBottom: '10px'}}
														>
															Chi nhánh: <b>{bankItem.branch}</b>
														</Typography>
														<Typography
															component="li"
															align="left"
															sx={{fontSize: '14px', marginBottom: '10px'}}
														>
															Ghi chú: <b>{state.code}</b>
														</Typography>
													</ul>
												</CardContent>
											</Card>
										</Grid>
									)
								})
							}
						</Grid>
						<DialogTitle sx={{textAlign: 'center'}}>
							Mọi thắc mắc vui lòng liên hệ <b style={{color: 'red'}}>{state.hotline}</b> để được giải quyết
						</DialogTitle>
					</Dialog>
					<Dialog open={state.dialog} fullWidth="true" maxWidth='md' onClose={() => {setState({...state, dialog: false})}}>
						<DialogTitle>Thông tin đơn hàng</DialogTitle>
						<DialogContent>
							<TableContainer component={Paper}>
								<Table size="small" aria-label="a dense table">
									<TableHead>
										<TableRow>
											<TableCell>
												Mã gói
											</TableCell>
											<TableCell>
												Số năm
											</TableCell>
											<TableCell>
												Đơn giá
											</TableCell>
											<TableCell>
												Giảm giá
											</TableCell>
											<TableCell>
												Thành tiền
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>
												{state.package_purchase.code}
											</TableCell>
											<TableCell width={'200px'}>
												<FormControl fullWidth>
													<Select
														labelId="demo-simple-select-label"
														id="demo-simple-select"
														size='small'
														value={state.amount}
														onChange={handleYearNumber}
													>
														{
															Object.keys(state.discount).map(function(month) {
																return (
																	<MenuItem value={month}>
																		{month/12 < 1 ? month + ' tháng' : month/12 + ' năm'}
																	</MenuItem>
																)
															})
														}
													</Select>
												</FormControl>
											</TableCell>
											<TableCell>
												<b>{Number(state.package_purchase.price).toLocaleString()}</b> vnđ /tháng
											</TableCell>
											<TableCell>
												{state.package_purchase.discount} %
											</TableCell>
											<TableCell align="right">
												<b>
													{Number(total(state.package_purchase)).toLocaleString()} vnđ
												</b>
											</TableCell>
										</TableRow>
										{
											state.purchased != null &&
											<TableRow>
												<TableCell colSpan={4}>
													Bạn đang sử dụng gói <b>{state.purchased.code}</b> ({state.purchased.month < 12 ? state.purchased.month + ' tháng' : state.purchased.month/12 + ' năm'}) mua ngày <b>{state.purchased.date_purchase}</b>. Ngày hết hạn ngày <b>{state.purchased.date_expire}</b>, còn dư: 
												</TableCell>
												<TableCell align='right'>
													<b>
														{Number(state.purchased.balance).toLocaleString()} vnđ
													</b>
												</TableCell>
											</TableRow>
										}
										<TableRow>
											<TableCell colSpan={5} align="right">
												<form onSubmit={handleSubmit(verifyCouponCode)}>
													<TextField size='small' {...register('code')} label="Mã giảm giá" variant="outlined"/>
													<Button type="submit" color="warning" sx={{marginLeft: '10px'}} className="text-transform" variant='contained'>Xác thực</Button>
												</form>
											</TableCell>
										</TableRow>
										{
											state.percent_discount > 0 &&
											<TableRow>
												<TableCell colSpan={3} align="right">
													Mã khuyến mại giảm:
												</TableCell>
												<TableCell colSpan={2} sx={{fontSize: '25px'}} align="right">
													<b>{state.percent_discount}%</b>
												</TableCell>
											</TableRow>
										}
										
										<TableRow>
											<TableCell colSpan={3} align="right">
												Cần thanh toán:
											</TableCell>
											<TableCell colSpan={2} sx={{fontSize: '25px'}} align="right">
												<b>{Number(total(state.package_purchase) - (state.purchased != null ? state.purchased.balance : 0) - state.percent_discount/100 * state.package_purchase.price * state.amount).toLocaleString()} vnđ</b>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell colSpan={5} align="right">
												<LoadingButton loading={state.loading_button} onClick={placeOrder} startIcon={<CheckRoundedIcon/>} variant="contained">
													Xác nhận {getTextPurchase(state.package_purchase)}
												</LoadingButton>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</DialogContent>
						<DialogTitle sx={{textAlign: 'center'}}>
							Mọi thắc mắc vui lòng liên hệ <b style={{color: 'red'}}>{state.hotline}</b> để được giải quyết
						</DialogTitle>
						<br></br>
					</Dialog>
					{/* Hero unit */}
					<Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
						<Typography
						component="h1"
						variant="h3"
						align="center"
						color="text.primary"
						gutterBottom
						>
							Bảng giá
						</Typography>
						{/* <Typography variant="h5" align="center" color="text.secondary" component="p">
						Quickly build an effective pricing table for your potential customers with
						this layout. It&apos;s built with default MUI components with little
						customization.
						</Typography> */}
					</Container>
					<Container component="main">
						{
							state.message != ''
							?
							<center>
								{state.message}
							</center>
							:
							<Grid container spacing={5} alignItems="flex-end">
								{state.prices.map((priceItem) => (
										<Grid
											item
											key={priceItem.title}
											md={3}
										>
											<Card>
												<CardHeader
													title={priceItem.name}
													subheader={priceItem.code == 'grow' ? 'Phổ biến' : ''}
													titleTypographyProps={{ align: 'center' }}
													action={priceItem.code === 'grow' ? <StarIcon /> : null}
													subheaderTypographyProps={{
														align: 'center',
													}}
													sx={{
														backgroundColor: "#E0E3E7",
														color: "#007FFF"
													}}
												/>
												<CardContent>
													<Box
														sx={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'baseline',
														mb: 2,
														}}
													>
														<Typography component="h2" variant="h4">
															<b>{Number(priceItem.price).toLocaleString()}</b>
														</Typography>
														<br></br>
														<Typography variant="h6" color="text.secondary">
														/tháng
														</Typography>
													</Box>
													<ul>
														{priceItem.description.map((line) => (
														<Typography
															component="li"
															variant="subtitle1"
															align="center"
															key={line}
														>
															{parse(line)}
														</Typography>
														))}
													</ul>
												</CardContent>
												<CardActions>
													{
														priceItem.code == 'free'
														?
														<Button fullWidth variant="outlined">
															Miễn phí
														</Button>
														:
														<Button color={getTextPurchase(priceItem) == 'Gia hạn' ? 'warning' : 'primary'} onClick={() => purchase(priceItem)} fullWidth variant="contained">
															{getTextPurchase(priceItem)}
														</Button>
													}
												</CardActions>
											</Card>
									</Grid>
								))}
							</Grid>
						}
						
					</Container>
					<Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
						<Typography
						component="h1"
						variant="h3"
						align="center"
						color="text.primary"
						gutterBottom
						>
							Thanh toán
						</Typography>
					</Container>
					<Container maxWidth="sm">
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<img width="100%" src="/vietin_qr.jpg" alt="" />
							</Grid>
							<Grid item xs={6}>
								<img width="100%" src="/tech_qr.jpg" alt="" />
							</Grid>
						</Grid>
					</Container>
					<Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
						<Typography
						component="h1"
						variant="h3"
						align="center"
						color="text.primary"
						gutterBottom
						>
							Gói đã mua
						</Typography>
					</Container>
					<Container maxWidth="xl">
						<TableContainer component={Paper}>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow style={{whiteSpace: 'nowrap'}}>
										<TableCell>STT</TableCell>
										<TableCell>Mã đơn</TableCell>
										<TableCell>Gói mua</TableCell>
										<TableCell>Thời gian</TableCell>
										<TableCell>Thời gian sử dụng</TableCell>
										<TableCell>Đơn giá</TableCell>
										<TableCell>Giảm giá</TableCell>
										<TableCell>Mã giảm giá</TableCell>
										<TableCell>Cần thanh toán</TableCell>
										<TableCell>Mô tả</TableCell>
										<TableCell>Thanh toán</TableCell>
										<TableCell>Trạng thái</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										state.list_purchase_sended.map((item, index) => {
											return (
												<TableRow>
													<TableCell>{index + 1}</TableCell>
													<TableCell>{item.code}</TableCell>
													<TableCell>{item.package}</TableCell>
													<TableCell style={{whiteSpace: 'nowrap'}}>
														<p>
															Ngày mua: <b>{item.date_purchase}</b>
														</p>
														<p>
															Ngày duyệt: <b>{item.date_accept}</b>
														</p>
														<p>
															Hết hạn: <b>{item.date_expire}</b>
														</p>
													</TableCell>
													<TableCell align="center">{item.month} tháng</TableCell>
													<TableCell>{Number(item.price).toLocaleString()}/tháng</TableCell>
													<TableCell>{Number(item.discount).toLocaleString()}%</TableCell>
													<TableCell>{item.coupon_code}</TableCell>
													<TableCell align="right">
														<b>{Number(item.price_payment).toLocaleString()}</b>
													</TableCell>
													<TableCell>{item.description}</TableCell>
													<TableCell style={{whiteSpace: 'nowrap'}}>
														<Button onClick={() => setState({...state, dialog_payment: true, code: item.code})} className='text-transform' color='primary' variant="contained">
															Chuyển khoản
														</Button>
														<img src="" alt="" />
													</TableCell>
													<TableCell style={{whiteSpace: 'nowrap'}}>
														<span class={item.status.color}>{item.status.name}</span>
													</TableCell>
												</TableRow>
											)
										})
									}
								</TableBody>
							</Table>
						</TableContainer>
					</Container>
					<br></br>
				</div>
			}
		</div>
	);
}

export default Price;