import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { requestOptions } from "../../../../../Helpers/function";
import api from './../../../../../Apis';
import { useTranslation } from "react-i18next"
export default function SwitchDefaultBlock(props) {
    const { t, i18n } = useTranslation()
    const [state, setState] = useState({
        is_checked: false
    })

    useEffect(() => {
        setState({
            ...state,
            is_checked: props.is_default_block_active
        })
    }, [props.is_default_block_active])

    const onChangeBlockDefault = (e) => {
        setState({
            ...state,
            is_checked: e.target.checked
        })
        const body = {
            is_default: e.target.checked,
            code: props.block_active.code,
            lang: i18n.language
        }
        fetch(api.active_block_default_for_first_message, requestOptions('POST', props.cookie, body))
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    Swal.fire({
                        title: result.message,
                        timer: 1000,
                        icon: 'error'
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        timer: 1000,
                        icon: 'success'
                    })
                }
            }
        )
    }

    return (
        <FormGroup onChange={onChangeBlockDefault}>
            <FormControlLabel control={<Switch checked={state.is_checked} />} label={t("chatbot.buildScript.setAsDefaultBlockOnFirstInbox")} />
        </FormGroup>
    )
}