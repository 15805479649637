import { Checkbox, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useState } from "react";

function TagSearch(props) {
    const checked = [];
    const handleToggle = (e) => {
        const value = e.target.value;
        props.responePropsTag(value);
    }

    // console.log(props.filter, 'chinh123', );
    
    return (
        <div className='list-saler'>
            <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {props.tags.map((value) => {
                    //const labelId = `checkbox-list-secondary-label-${value}`;
                    return (
                    <ListItem
                        secondaryAction={
                        <Checkbox
                            edge="end"
                            onChange={handleToggle}
                            value={value.tag_id}
                            name="tag"
                            checked={(props.filter.tags).includes(value.tag_id.toString()) ? true : false}
                            //inputProps={{ 'aria-labelledby': labelId }}
                        />
                        }
                        disablePadding
                    >
                        <ListItemButton>
                            <ListItemText sx={{color: value.color}} primary={value.name} />
                        </ListItemButton>
                    </ListItem>
                    );
                })}
            </List>
        </div>
    )
}

export default TagSearch;