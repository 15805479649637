import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import './../../../Css/dashboard.scss'
import api from './../../../Apis'
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
    NavLink, Link, useNavigate
} from "react-router-dom";
import UserOption from './UserOption';
import LocalMallRoundedIcon from '@mui/icons-material/LocalMallRounded';
import PriceChangeRoundedIcon from '@mui/icons-material/PriceChangeRounded';
import { Fade, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import PieChartOutlineRoundedIcon from '@mui/icons-material/PieChartOutlineRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import MarkUnreadChatAltRoundedIcon from '@mui/icons-material/MarkUnreadChatAltRounded';
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import {useTranslation} from "react-i18next";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
const useInfo = (access_token, i18n) => {
    
    
    let navigate = useNavigate();
    const [state, setState] = useState({
        user: {
            name: '',
            avatar: ''
        },
        shops: [],
        shop_active: {
            full_name: '',
            avatar: ''
        },
        domain: ''
    })

    useEffect(() => {
        
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            }
        };
        fetch(api.get_shop + `?lang=${i18n.language}`, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    const beamsClient = new PusherPushNotifications.Client({
                        instanceId: process.env.REACT_APP_PUSHER_INSTANCEID,
                      });
                        beamsClient.start()
                        .then(() => beamsClient.addDeviceInterest("shop-" + result.datas.shop_active.id))
                        .then(() => console.log('Successfully registered and subscribed!'))
                        .catch(console.error);
                    if (result.datas.user.phone == null || result.datas.user.email == null || result.datas.user.phone == null) {
                        let route = '/' + result.datas.user.uid + '/update-login';
                        return navigate(route);
                    } else {
                        setState({
                            ...state,
                            user: result.datas.user,
                            shops: result.datas.shops,
                            shop_active: result.datas.shop_active,
                            domain: result.datas.domain
                        })
                    }
                }
            }
        )
    }, [])

    return state;
}

function Header() {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [state, setState] = useState({
        anchorEl: null,
        anchorEl2: null
    })
    const open = Boolean(state.anchorEl);
    const open2 = Boolean(state.anchorEl2);
    const handleClick = (event) => {
        setState({...state, anchorEl: event.currentTarget});
    };
    const handleClose = () => {
        setState({...state, anchorEl: null});
    };
    const handleClick2 = (event) => {
        setState({...state, anchorEl2: event.currentTarget});
    };
    const handleClose2 = () => {
        setState({...state, anchorEl2: null});
    };
    const [cookies, setCookie] = useCookies(['is_login']);
    // const profile = useData(cookies.is_login);
    const user_info = useInfo(cookies.is_login, i18n);

    const getClass = () => {
        if (window.location.pathname == '/dashboard/expense' || window.location.pathname == '/dashboard/revenue' || window.location.pathname == '/dashboard/transaction') {
            return 'button-active';
        }
    }
    if (user_info.domain != window.location.origin + '/' && user_info.domain != '' && process.env.NODE_ENV == 'production') {
        window.location.href = user_info.domain + 'dashboard';
    }

    return (
        <div className="header">
            <Container maxWidth="xl">
                <Stack direction="row" className='menu' spacing={2}>
                    <div className='menu-item'>
                        <a href={`https://${ process.env.REACT_APP_DOMAIN }`} target="_blank" style={{fontSize: '20px', color: '#fff', fontWeight: 'bold'}}>
                            { process.env.REACT_APP_NAME }.com
                        </a>
                        <NavLink to='/dashboard/'>
                            <Button className='text-transform' startIcon={<HomeRoundedIcon/>}>
                               {t('header.menu.dashboard.title')}
                            </Button>
                        </NavLink>
                        <NavLink to='/dashboard/live-chat'>
                            <Button className='text-transform' startIcon={<MarkUnreadChatAltRoundedIcon/>}>
                                {t('header.menu.liveChat.title')}
                            </Button>
                        </NavLink>
                        <NavLink to='/dashboard/order'>
                            <Button className='text-transform' startIcon={<LocalMallRoundedIcon/>}>
                                {t('header.menu.order.title')}
                            </Button>
                        </NavLink>
                        {currentLanguage === "vi" && (
                        <>
                            <Button
                                id="fade-button"
                                startIcon={<BarChartRoundedIcon/>}
                                aria-controls={open ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                className={'text-transform ' + getClass()}
                                sx={{color: '#fff'}}
                                endIcon={<KeyboardArrowDownRoundedIcon/>}
                            >
                                {t('header.menu.finance.title')}
                            </Button>
                            <Menu
                                id="fade-menu"
                                MenuListProps={{
                                'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={state.anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                                // sx={{marginTop: '-3px'}}
                            >
                                <MenuItem onClick={handleClose}>
                                    <Link className="a-none" to='/dashboard/revenue'>
                                        {t('header.menu.finance.subMenu.revenue')}
                                    </Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <Link className="a-none" to='/dashboard/expense'>
                                        {t('header.menu.finance.subMenu.expense')}
                                    </Link>
                                </MenuItem>
                                {/* <MenuItem onClick={handleClose}>
                                    <Link className="a-none" to='/dashboard/transaction'>
                                        Lịch sử giao dịch
                                    </Link>
                                </MenuItem> */}
                            </Menu>
                        </>
                        )}
                        {/* <NavLink to='/dashboard/setting'>
                            <Button
                                className='text-transform'
                                startIcon={<PieChartOutlineRoundedIcon/>}
                            >
                                Thống kê
                            </Button>
                        </NavLink> */}
                        <NavLink to='/dashboard/setting'>
                            <Button className='text-transform' startIcon={<SettingsApplicationsRoundedIcon/>}>
                                {t('header.menu.shopSetting.title')}
                            </Button>
                        </NavLink>
                        <NavLink to='/dashboard/product'>
                            <Button className='text-transform' startIcon={<AddRoundedIcon/>}>
                                {t('header.menu.product.title')}
                            </Button>
                        </NavLink>
                        {
                            currentLanguage !== "vi" && 
                            <NavLink to='/dashboard/pricing'>
                                <Button className='text-transform' startIcon={<AttachMoneyIcon/>}>
                                    {t('header.menu.pricing.title')}
                                </Button>
                            </NavLink>
                        }
                        { currentLanguage === "vi" && (
                        <>
                        <NavLink to='/dashboard/price'>
                            <Button className='text-transform' startIcon={<PriceChangeRoundedIcon/>}>
                                Bảng giá
                            </Button>
                        </NavLink>
                        <Button 
                            sx={{color: '#fff'}}
                            startIcon={<MoreHorizRoundedIcon/>}
                            id="fade-button"
                            aria-controls={open2 ? 'fade-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open2 ? 'true' : undefined}
                            onClick={handleClick2}
                            className='text-transform'
                        >
                        </Button>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                            'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={state.anchorEl2}
                            open={open2}
                            onClose={handleClose2}
                            TransitionComponent={Fade}
                        >
                            {/* <MenuItem onClick={handleClose2}>
                                <Link className="a-none" to='/dashboard/price'>
                                    Bảng giá
                                </Link>
                            </MenuItem> */}
                            <MenuItem onClick={handleClose2}>
                                <Link className="a-none" to='/dashboard/fee'>
                                    Tính cước
                                </Link>
                            </MenuItem>
                            {/* <MenuItem onClick={handleClose2}>
                                <Link className="a-none" to='/dashboard/customer'>
                                    Khách hàng
                                </Link>
                            </MenuItem> */}
                            <MenuItem onClick={handleClose2}>
                                <Link className="a-none" to='/dashboard/check-phone-number'>
                                    Check bom hàng
                                </Link>
                            </MenuItem>
                            {/* <MenuItem onClick={handleClose2}>
                                <Link className="a-none" to='/dashboard/bank'>
                                    Nạp tiền
                                </Link>
                            </MenuItem> */}
                        </Menu>
                        </>
                        ) }
                        {/* <NavLink to='/dashboard/check-phone-number'>
                            <Button className='text-transform' startIcon={<ReportProblemRoundedIcon/>}>
                                Check bom hàng
                            </Button>
                        </NavLink>
                        <NavLink to='/dashboard/fee'>
                            <Button className='text-transform' startIcon={<LocalShippingRoundedIcon/>}>
                                Tính cước
                            </Button>
                        </NavLink> */}
                    </div>
                    <UserOption user_info={user_info}/>
                </Stack>
            </Container>
            {/* <div className="left">
                
            </div>
            <div className="right">
                <div className='full-name'>
                    {profile ? profile.full_name : ''}
                </div>
                <div>
                    <img className='user-image' src={profile && profile.avatar}></img>
                </div>
            </div> */}
        </div>
    )
}

export default Header;