import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Dialog, DialogActions, DialogContent, Fade, DialogTitle, Menu, TextField, MenuItem, Grid, Radio, FormControl, InputLabel, Select, Chip } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useEffect, useRef, useState } from 'react';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useForm } from 'react-hook-form';
import api from './../../../../../Apis';
import Swal from 'sweetalert2';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import { Box } from '@mui/system';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import DialogAttachmentManager from '../../../../Globals/DialogAttachmentManager';
import { useTranslation } from "react-i18next";

function MessSample(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        dialog_link: false,
        open: false,
        anchorEl: null,
        symbol: '',
        isOpenDialogAttachment: false,
        imageAttach: [],
        list_mess_samples: [],
        attachments: [],
        form: {
            name: '',
            keyword: '',
            content: '',
            id: ''
        },
        edit: false,
        index_edit: '',
        dialog_manage_image: false
    })
    const {register, handleSubmit, reset, getValues, setValue} = useForm();
    const open = Boolean(state.anchorEl);
    const handleClick = (event) => {
        setState({...state, anchorEl: event.currentTarget})
      };
    const handleClose = (e) => {
        if (e.target.getAttribute('value') != null) {
            const content = state.form.content + " " + e.target.getAttribute('value');
            setState({...state, anchorEl: null, form: {...state.form, content: content}});
        } else {
            setState({...state, anchorEl: null});
        }
    };
    const handleChangeContent = (e) => {
        setState({...state, symbol: e.target.value});
    }

    const imagePicked = (images) => {
        setState((oldState) => {
            return {...oldState, imageAttach: images}
        })
    }
    const onSubmitMessSample = (data) => {
        const new_mess_sample = {
            'name': state.form.name,
            'keyword': state.form.keyword,
            'content': state.form.content
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                content: state.form.content,
                keyword: state.form.keyword,
                name: state.form.name,
                id: state.form.id,
                images: state.imageAttach,
                lang: i18n.language
            })
        };
        const api_link = state.edit == true ? api.edit_mess_sample : api.create_mess_sample;
        fetch(api_link, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    const list_mess_samples = [...state.list_mess_samples];

                    if (state.edit) {
                        list_mess_samples[state.index_edit] = result.data;
                    } else {
                        list_mess_samples.unshift(result.data);
                    }
                    setState({
                                ...state,
                                open: false,
                                edit: false,
                                index_edit: '',
                                list_mess_samples: list_mess_samples,
                                form: {
                                    name: '',
                                    content: '',
                                    id: '',
                                    keyword: ''
                                }
                            })
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        confirmButtonText: t("common.exit")
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: t("common.exit")
                    })
                }
                reset();
            }
        )
    }

    const removeAndEdit = (data, action, index) => {
        if (action == 'delete') {
            if (window.confirm(t("common.alert.confirmDelete"))) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        id: data.id,
                        lang: i18n.language
                    })
                };
                fetch(api.delete_mess_sample, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            setState({...state, list_mess_samples: result.datas})
                            Swal.fire({
                                title: result.message,
                                icon: 'success',
                                confirmButtonText: t("common.exit")
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                confirmButtonText: t("common.exit")
                            })
                        }
                    }
                )
            }

            return false;
        } else {
            // setValue('links', data.images);
            setState({
                ...state,
                open: true,
                imageAttach: data.images,
                form: {name: data.name, keyword: data.keyword, content: data.content, id: data.id},
                edit: true,
                index_edit: index
            });
        }
    }

    const closeDialog = () => {
        if (state.edit == true) {
            // setValue('links', [])
            setState({...state, edit: false, open: false, imageAttach: [], form: {name: '', keyword: '', content: ''}});
        } else {
            setState({...state, open: false, edit: false, imageAttach: []});
        }
    }

    const onChangeFile = (e) => {
        const file = e.target.files[0];
        if (file.size/1024 > 25600) {
            Swal.fire({
                title: t("common.alert.fileSizeLimit"),
                icon: 'warning',
                confirmButtonText: t("common.exit")
            })
        } else {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('lang', i18n.language);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: formData
            };
            fetch(api.upload_attachment, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success == true) {
                        setState({...state, attachments: [result.datas, ...state.attachments]});
                    } else if (result.success == false && result.type == 'validate') {
                        if (result.errors.length > 0) {
                            var string_error = '';
                            result.errors.map((value, key) => {
                                string_error = string_error + '<p>' + value + '</p>';
                            })
                            Swal.fire({
                                title: string_error,
                                icon: 'warning',
                                confirmButtonText: t("common.exit")
                            })
                        }
                    } else if (result.success == false && result.type == 'error') {
                        Swal.fire({
                            title: result.message,
                            icon: 'error',
                            confirmButtonText: t("common.exit")
                        })
                    }
                }
            )
        }
    }

    useEffect(() => {
        setState({
            ...state,
            list_mess_samples: props.list_mess_samples,
            attachments: props.attachments
        })
    }, [props.value]) //[props.list_mess_samples])

    const onSendImage = (data) => {
        setState({
            ...state,
            dialog_manage_image: false
        })
    }

    const handleChangeKeySearch = () => {

    }

    const deleteImage = (id, index) => {
        if (window.confirm(t("common.alert.confirmDelete"))) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({
                    id: id,
                    lang: i18n.language
                })
            };
            fetch(api.delete_file, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        const copy_attachments = [...state.attachments];
                        copy_attachments.splice(index, 1);
                        setState({...state,
                                    attachments: copy_attachments
                                });
                        Swal.fire({
                            title: result.message,
                            icon: 'success',
                            confirmButtonText: 'OK'
                        })
                    } else {
                        Swal.fire({
                            title: result.message,
                            icon: 'error',
                            confirmButtonText: 'OK'
                        })
                    }
                }
            )
        }

        return false;
    }

    const inputFile = useRef(null);

    const selectFile = (e) => {
        inputFile.current.click();
    }

    const onUploadImageByLink = (data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                fanpage_id: props.fanpage_id,
                url: data.url,
                lang: i18n.language
            })
        };
        fetch(api.upload_url, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success == true) {
                    setState({...state,
                                dialog_link: false,
                                attachments: [
                                    result.datas,
                                    ...state.attachments
                                ]
                            });
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
        reset();
    }
    // const buttonSubmit = () => {
        
    // }

    const closeDialogAttachment = () => {
        setState((oldState) => {
            return {...oldState, isOpenDialogAttachment: false}
        })
    }
    
    return (
        <TabPanel value="3" style={{padding: '10px 0px'}}>
            <input ref={inputFile} style={{display: 'none'}} onChange={onChangeFile} type="file" className='file'/>
            <p>
                <Button onClick={() => {setState({...state, open: true, symbol: ''})}} startIcon={<AddRoundedIcon/>} sx={{textTransform: 'none'}} variant="contained">{t("common.add")}</Button>
            </p>
            <Dialog open={state.dialog_link} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog_link: false})}}>
                <DialogTitle>{t("common.upload.enterImageLink")}</DialogTitle>
                <form onSubmit={handleSubmit(onUploadImageByLink)}>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={t("common.upload.imageLink")}
                            type="text"
                            fullWidth
                            variant="standard"
                            {...register('url')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {setState({...state, dialog_link: false})}}>{t("common.close")}</Button>
                        <Button type="submit">{t("common.confirm")}</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={state.open} fullWidth="true" maxWidth='sm' onClose={closeDialog}>
                <DialogTitle>{t("setting.updateQuickReplyTemplate")}</DialogTitle>
                <form onSubmit={handleSubmit(onSubmitMessSample)}>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label={t("setting.label.displayName")}
                            type="text"
                            fullWidth
                            variant="outlined"
                            {...register('name')}
                            onChange={(e) => {setState({...state, form: {...state.form, name: e.target.value}})}}
                            required
                            value={state.form.name}
                        />
                        <TextField
                            margin="dense"
                            label={t("setting.label.keyword")}
                            type="text"
                            fullWidth
                            variant="outlined"
                            {...register('keyword')}
                            onChange={(e) => {setState({...state, form: {...state.form, keyword: e.target.value}})}}
                            value={state.form.keyword}
                            required
                        />
                        {/* <FormControl margin="dense" fullWidth>
                            <InputLabel id="demo-simple-select-label">Cài đặt tự động nhắn tin khi nhận được bình luận</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Cài đặt tự động nhắn tin khi nhận được bình luận"
                            >
                                <MenuItem value={1}>Đồng ý</MenuItem>
                                <MenuItem value={0}>Từ chối</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl margin="dense" fullWidth>
                            <InputLabel id="demo-simple-select-label">Áp dụng cho Fanpage</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Áp dụng cho Fanpage"
                            >
                                <MenuItem value={10}>Tất cả</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl> */}
                        <p className='t-right'>
                            <Button
                                id="fade-button"
                                aria-controls={open ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                variant="contained"
                                sx={{textTransform: 'none'}}
                                startIcon={<PersonAddAltRoundedIcon/>}
                            >
                                {t("setting.personalize")}
                            </Button>
                        </p>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                            'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={state.anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem onClick={handleClose} value="{name}">{t("setting.customerName")}</MenuItem>
                            <MenuItem onClick={handleClose} value="{full_name}">{t("setting.fullName")}</MenuItem>
                            <MenuItem onClick={handleClose} value="{page_name}">{t("setting.pageName")}</MenuItem>
                        </Menu>
                        <textarea required onChange={(e) => {setState({...state, form: {...state.form, content: e.target.value}})}} value={state.form.content} className="textarea" rows={10} placeholder={t("setting.messageReplyContent")}></textarea>
                        <Button onClick={() => setState({...state, isOpenDialogAttachment: true})} startIcon={<DriveFolderUploadOutlinedIcon/>} className="text-transform" sx={{marginTop: '5px'}} variant='contained' color="warning">{t("common.upload.attachedImage")}</Button>
                        {
                            state.imageAttach.length > 0 &&
                            <Chip sx={{marginLeft: "10px"}} label={t("setting.selectedImages", { count: state.imageAttach.length })} onDelete={() => setState({...state, imageAttach: []})} />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>{t("common.close")}</Button>
                        <Button type="submit">{t("common.save")}</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <DialogAttachmentManager
                fanpage_id={props.fanpage_id}
                closeDialogAttachment={closeDialogAttachment}
                cookie={props.cookie}
                imagePicked={imagePicked}
                isOpenDialogAttachment={state.isOpenDialogAttachment}
                imageSelectedOld={state.imageAttach}
                page_name="mess_sample_setting"
            />
            <Dialog
                fullWidth="true"
                maxWidth='lg'
                open={state.dialog_manage_image}
                onClose={() => setState({...state, dialog_manage_image: false})}
            >
                <DialogTitle sx={{display: "flex", justifyContent: "space-between"}}>
                    {t("common.upload.shopImages")}
                    <Button type="button" onClick={() => setState({...state, dialog_manage_image: false})} startIcon={<CloseOutlinedIcon />} variant="contained" color="warning">
                        {t("common.close")}
                    </Button>
                </DialogTitle>
                <DialogContent sx={{overflowY: "unset"}}>
                    <Button onClick={selectFile} sx={{
                        marginRight: '20px'
                        }}
                        variant="outlined" color="primary" startIcon={<UploadRoundedIcon />}>
                        {t("common.upload.uploadImage")}
                    </Button>
                    <Button sx={{
                        marginRight: '20px'
                        }} onClick={() => {setState({...state, input_link: true, dialog_link: true})}} variant="outlined" color="success" startIcon={<AddLinkRoundedIcon />}>
                        {t("common.upload.uploadFromUrl")}
                    </Button>
                    <TextField size="small" id="outlined-basic" onChange={handleChangeKeySearch} label={t("common.upload.searchImageName")} variant="outlined"></TextField>
                </DialogContent>
                <form onSubmit={handleSubmit(onSendImage)}>
                    <DialogContent>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                {
                                    state.attachments.map((value, key) => {
                                        return (
                                            <Grid key={key} item xs={2} sx={{position: 'relative'}}>
                                                <input {...register('links')} style={{position: 'absolute', right: '0px', borderRadius: 'unset'}} type="checkbox" name="links" value={value.id}></input>
                                                {/* <Radio {...register('links')} name="links" value={value.url} size="medium" sx={{position: 'absolute', right: '0px', borderRadius: 'unset'}}></Radio> */}
                                                <div className='block-image'>
                                                    <img className='lib-image' src={value.link}></img>
                                                    <p className='name'>
                                                        {value.name}
                                                    </p>
                                                    <p className='m-b-0'>
                                                        <Button onClick={() => deleteImage(value.id, key) } color='error' startIcon={<DeleteRoundedIcon/>} size='small' variant="contained">{t("common.delete")}</Button>
                                                    </p>
                                                </div>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                        {/* <button type='submit' ref={buttonSubmit} style={{display: 'none'}}>Submit</button> */}
                    </DialogContent>
                    {/* <Button sx={{margin: "10px 20px 20px 20px"}} type="submit" startIcon={<SendRoundedIcon />} variant="contained" color="primary">
                        Xác nhận
                    </Button> */}
                </form>
            </Dialog>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("setting.label.keyword")}</TableCell>
                            <TableCell>{t("setting.label.displayName")}</TableCell>
                            <TableCell>{t("setting.content")}</TableCell>
                            <TableCell>{t("setting.action")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            state.list_mess_samples.map((row, key) => (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell component="th" scope="row">
                                        {row.keyword}
                                    </TableCell>
                                    <TableCell>
                                        {row.name}
                                    </TableCell>
                                    <TableCell>
                                        {row.content}
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => removeAndEdit(row, 'edit', key)} sx={{marginRight: '5px'}} variant="contained" color="warning">
                                            <EditRoundedIcon></EditRoundedIcon>
                                        </Button>
                                        <Button onClick={() => removeAndEdit(row, 'delete', key)} variant="contained" color="error">
                                            <DeleteRoundedIcon></DeleteRoundedIcon>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </TabPanel>
    )
}

export default MessSample;