import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Attachment from "../../Fanpages/Messages/Chats/Components/Attachment";
import ReadMoreMess from "../../Fanpages/Messages/Chats/Components/ReadMoreMess";

function Chat(props) {
    const [state, setState] = useState({
        messages: [],
        fanpage_id: '',
        conversation_id: '',
    })
    const responsePropsMessage = (data) => {
        const old_mess = [...state.messages];
        const new_messages = data.concat(old_mess);
        setState({
            ...state,
            messages: new_messages
        })
    }

    useEffect(() => {
        setState({
            ...state,
            messages: props.messages,
            fanpage_id: props.fanpage_id,
            conversation_id: props.conversation_id,
        })
    }, [])

    return (
        <Grid item xs={6} md={6} className="messenger-box">
            <div className="chat" style={{width: '100%'}}>
                <div className="detail-mess" style={{height: '80%'}}>
                {
                    state.messages.length > 0 &&
                    <ReadMoreMess 
                        responsePropsMessage={responsePropsMessage}
                        count_mess={Array.isArray(state.messages) ? state.messages.length : 0}
                        fanpage_id={state.fanpage_id} 
                        cookie={props.cookie} 
                        conversation_id={state.conversation_id}
                    />
                }
                
                {
                    state.messages.map((value, index) => {
                        return (
                            <div className="m-b-20">
                                <div className={'clear ' + value.position}>
                                    {
                                        value.position == 'mess-left' &&
                                        <div className="avatar">
                                            <img src={props.avatar_user} alt="" />
                                        </div>
                                    }
                                    
                                    <div className="mess-text">
                                        <span>
                                            {value.message}
                                        </span>
                                    </div>
                                </div>
                                {
                                    value.attachment.length > 0 &&
                                    value.attachment.map((attachment, key) => {
                                        return (
                                            <Attachment sending={true} position_mess={value.position} attachment={attachment}/>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </Grid>
    )
}

export default Chat;