import { Button, DialogContent, Grid, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
import ReplayIcon from '@mui/icons-material/Replay';
import {useTranslation} from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import api from "../../../../../Apis";
import Swal from "sweetalert2";
export default function SearchFanpageInActive(props) {
    const {t} = useTranslation();
    const {register, handleSubmit, reset} = useForm();
    const [loadingButton, setLoadingButton] = useState(false)

    const searchFanpage = (data) => {
        props.loadFanpage(data.name, 'page_in_active');
    }

    const reloadFanpage = () => {
        reset();
        props.loadFanpage('', 'page_in_active');
    }

    const connectAll = () => {
        setLoadingButton(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            }
        };
        fetch(api.connect_all_page + `?lang=${props.lang}`, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setLoadingButton(false);
                    Swal.fire("", result.message, "success")
                } else {
                    setLoadingButton(false);
                    Swal.fire("", result.message, "warning")
                }
            }
        )
    }

    return (
        <form onSubmit={handleSubmit(searchFanpage)}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField {...register('name')} autoComplete='off' fullWidth size="small" id="outlined-basic" label={t("common.searchFanpageLabel")} variant="outlined" />
                </Grid>
                <Grid item xs={6}>
                    <Button type="submit" className='text-transform' startIcon={<SearchIcon/>} variant="contained">{t('common.search')}</Button>
                    <Button onClick={reloadFanpage} sx={{marginLeft: '5px'}} type="button" className='text-transform' color="error" startIcon={<ReplayIcon/>} variant="contained">{t('common.reload')}</Button>
                    <LoadingButton loading={loadingButton} onClick={connectAll} sx={{marginLeft: '5px'}} type="button" className='text-transform' color="warning" variant="contained">{t('common.connect_all')}</LoadingButton>
                </Grid>
            </Grid>
        </form>
    )
}