import { useState } from 'react';
import { useCookies } from 'react-cookie';
import FacebookLogin from 'react-facebook-login';
import Swal from 'sweetalert2';
import api from '../../Apis';
import { Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function LoginWithFacebook(props) {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['is_login', 'source']);
    const [state, setState] = useState({
        loading_page: false
    })
    function responseFacebook(response) {
        if (response.userID != undefined) {
            setState({
                ...state,
                loading_page: true
            })
            if (props.page == 'home') {
                var body = {
                    accessToken: response.accessToken,
                    fb_id: response.userID
                }
                var header = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + cookies.is_login
                }
            } else {
                var body = {
                    email: response.email,
                    full_name: response.name,
                    fb_id: response.userID,
                    avatar: response.picture.data.url,
                    accessToken: response.accessToken,
                    source: cookies.source
                }
                var header = {
                    'Content-Type': 'application/json'
                }
            }
            const requestOptions = {
                method: 'POST',
                headers: header,
                body: JSON.stringify({...body, lang: i18n.language})
            };
            const url_api = props.page == 'home' ? api.refresh_connect_page : api.register;
            fetch(url_api, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        if (props.page == 'home') {
                            props.responseFacebook(result);
                            setState({
                                ...state,
                                loading_page: false
                            })
                        } else {
                            let date = new Date();
                            date.setTime(date.getTime() + result.expires_in * 1000);
                            
                            if (result.user.username == null || result.user.phone == null || result.user.email == null) {
                                let route = '/' + result.user.fb_id + '/update-login';
        
                                return navigate(route);
                            } else {
                                setCookie('is_login', result.access_token, { path: '/', expires: date, domain: '.' + result.domain});
                                //setCookie('is_login', result.access_token, { path: '/', expires: date});
                                window.location.href = result.url_redirect + 'dashboard';
                                // return navigate('/dashboard');
                            }
                        }
                        
                    } else {
                        setState({
                            ...state,
                            loading_page: false
                        })
                        Swal.fire({
                            title: result.message,
                            icon: 'error'
                        })
                    }
                }
            )
        }
        
    }

    return (
        <p>
            {
                state.loading_page && 
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={state.loading_page}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            <FacebookLogin
                appId={ process.env.REACT_APP_FB_APP_ID }
                autoLoad={false}
                fields="name,email,picture"
                scope={ process.env.REACT_APP_FB_SCOPE } // instagram_basic,instagram_manage_messages,instagram_manage_comments
                callback={responseFacebook}
                size="small"
                textButton={props.title}
                cssClass="css-fb-custom"
            /> 
        </p>
        
    )
}