import Selectable from './Selectable';

export default function ReactSelectFast() {
    const onSelect = (selected) => {
        if (selected.length) {
            console.log(selected[0].props.number);
        //   console.log(selected);
        }
    }

    return (
        <Selectable onSelect={onSelect} />
    )
}