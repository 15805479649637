import { Button, Checkbox, CircularProgress, Container, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, Tooltip, InputLabel, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Pagination, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import api from './../../../../Apis'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip as TooltipChart,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import TabContext from "@mui/lab/TabContext";
import { Box } from "@mui/system";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import LoadingButton from "@mui/lab/LoadingButton";
import {convertNumber} from './../../../../Helpers/function.js';
import Swal from "sweetalert2";
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    TooltipChart,
    Legend
);

function Index() {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        loading: true,
        expenses: [],
        value: '1',
        value_per_months: [],
        list_months: [],
        year: '',
        dialog: false,
        loading_expense: false,
        total_page: 1,
        total_expense: 0,
        errors: {},
        month: convertNumber(parseInt(new Date().getMonth() + 1)),
        year: new Date().getFullYear(),
        data_edit: {}
    })
    const [cookies, setCookie] = useCookies('is_login');
    const {register, handleSubmit, reset} = useForm({
        defaultValues: useMemo(() => {
            return state.data_edit;
          }, [state.data_edit])
    });
    useEffect(() => {
        callApi({month: state.month, year: state.year, page: 1});
    }, [])
    
    useEffect(() => {
        reset(state.data_edit);
    }, [state.data_edit])

    const handleChangePage = (e, page_num) => {
        setState({
            ...state,
            expenses: [],
            loading: true
        })
        callApi({month: state.month, year: state.year, page: page_num});
    }

    const callApi = (obj) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            }
        };
        fetch(api.expense + '?month=' + obj.month + '&year=' + obj.year + '&page=' + obj.page + `&lang=${i18n.language}`, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        loading: false,
                        expenses: result.expenses,
                        total_page: result.total_page,
                        total_expense: result.total_expense,
                        list_months: result.list_months,
                        value_per_months: result.value_per_months,
                        value: '1'
                    })
                } else {
                    setState({
                        ...state,
                        loading: false
                    })
                }
            }
        )
    }

    const searchRevenue = (data) => {
        setState({
            ...state,
            loading: true,
            expenses: [],
        })
        callApi({
            month: convertNumber(data.month),
            year: data.year,
            page: 1
        });
    }
    const labels = state.list_months;
    const data = {
        labels,
        datasets: [{
            'label': t("expense.amountSpent"),
            'borderColor': "rgb(255, 99, 33)",
            'backgroundColor': "rgba(255, 99, 132, 33)",
            'yAxisID': 'y',
            'data': state.value_per_months
        }]
    };
    const options = {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,
        plugins: {
          title: {
            display: true,
            text: t("expense.currentYearSpendingChart") + state.year,
          },
        },
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false,
            },
          },
        },
    };

    const listMonth = () => {
        const months = [];
        for (var month = 1; month <= 12; month++) {
            months.push(<MenuItem value={month}>{t("expense.label.month")} {month}</MenuItem>);
        }

        return months;
    }

    const listYear = () => {
        const years = [];
        for (var year = 2022; year <= new Date().getFullYear(); year++) {
            years.push(<MenuItem value={year}>{t("expense.label.year")} {year}</MenuItem>);
        }

        return years;
    }

    const addExpense = (data) => {
        setState({
            ...state,
            loading_expense: true,
            errors: {}
        })
        if (state.data_edit.id != undefined) {
            var url = api.edit_expense;
            var body = {
                id: state.data_edit.id,
                date: data.date,
                money: data.money,
                note: data.note
            }
        } else {
            var url = api.create_expense;
            var body = {
                date: data.date,
                money: data.money,
                note: data.note
            }
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({...body, lang: i18n.language})
        };
        fetch(url, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    if (state.data_edit.id == undefined) {
                        setState({
                            ...state,
                            dialog: false,
                            expenses: [
                                result.expense,
                                ...state.expenses
                            ]
                        })
                    } else {
                        const expense_copy = [...state.expenses];
                        expense_copy[state.data_edit.index] = result.expense_updated;
                        setState({
                            ...state,
                            loading_expense: false,
                            dialog: false,
                            expenses: expense_copy,
                            data_edit: {}
                        })
                    }
                    
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        errors: {},
                        timer: 2000
                    })
                } else {
                    if (result.type == 'validate') {
                        setState({
                            ...state,
                            errors: result.errors,
                            loading_expense: false
                        })
                    } else if (result.type == 'error') {
                        Swal.fire({
                            title: result.message,
                            icon: 'error',
                            timer: 2000
                        })
                        setState({
                            ...state,
                            loading_expense: false
                        })
                    }
                    
                }
            }
        )
    }

    const deleteExpense = (index, expense) => {
        Swal.fire({
            title: t("expense.alert.confirmDelete"),
            showDenyButton: true,
            confirmButtonText: t("expense.alert.confirm"),
            denyButtonText: t("expense.alert.cancel"),
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.is_login
                    },
                    body: JSON.stringify({
                        id: expense.id,
                        lang: i18n.language
                    })
                };
                fetch(api.delete_expense, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const expense_copy = [...state.expenses];
                            expense_copy.splice(index, 1);
                            setState({
                                ...state,
                                expenses: expense_copy
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                timer: '2000'
                            })
                        }
                    }
                )
            }
          })
    }

    const editExpense = (index, item) => {
        setState({
            ...state,
            dialog: true,
            data_edit: {
                id: item.id,
                index: index,
                date: item.date,
                note: item.note_edit,
                money: item.money,
                month: state.month,
                year: state.year
            }
        })
    }

    const year = new Date().getFullYear();
    const month = convertNumber(parseInt(new Date().getMonth() + 1));
    const day = convertNumber(new Date().getDate());

    return (
        <Container maxWidth="xl" className='m-t-40'>
            <h3>{t("expense.spendingList")}</h3>
            <Dialog open={state.dialog} fullWidth="true" maxWidth='md' onClose={() => {setState({...state, dialog: false,  errors: {}})}}>
                <DialogTitle>{t("expense.spendingInfo")}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(addExpense)}>
                        <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                            <Grid item xs={6}>
                                <TextField
                                    type="date"
                                    {...register('date')}
                                    fullWidth
                                    margin="dense"
                                    id="outlined-required"
                                    label={t("expense.label.creationDate")}
                                    defaultValue={year + '-' + month + '-' + day}
                                    variant="outlined"
                                    helperText={state.errors.date?.message}
                                    error={state.errors.date?.status}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    {...register('money')}
                                    fullWidth
                                    margin="dense"
                                    id="outlined-required"
                                    label={t("expense.label.amountSpent")}
                                    variant="outlined"
                                    helperText={state.errors.money?.message}
                                    error={state.errors.money?.status}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <textarea cols="30" placeholder={t("expense.label.note")} {...register('note')} className="textarea" rows="10"></textarea>
                                {
                                    state.errors.note?.message != undefined &&
                                    <p style={{color: '#d32f2f', marginTop: '3px', fontSize: '0.75rem'}}>
                                        {state.errors.note.message}
                                    </p>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton type="submit" size="large" fullWidth loading={state.loading_expense} variant="contained">
                                    {t("common.confirm")}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <form onSubmit={handleSubmit(searchRevenue)}>
                <Grid container spacing={2}>
                    <Grid item xs={2} md={2}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            {...register('month')}
                            size="small"
                            fullWidth
                            label={t("expense.label.month")}
                            defaultValue={parseInt(new Date().getMonth() + 1)}
                        >
                            {listMonth()}
                        </TextField>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            {...register('year')}
                            size="small"
                            fullWidth
                            label={t("expense.label.year")}
                            defaultValue={new Date().getFullYear()}
                        >
                            {listYear()}
                        </TextField>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <Button
                            type='submit'
                            startIcon={<SearchRoundedIcon/>}
                            className="text-transform"
                            variant="contained"
                            sx={{marginRight: '10px'}}
                        >
                            {t("common.search")}
                        </Button>
                        <Button
                            type='button'
                            startIcon={<AddRoundedIcon/>}
                            className="text-transform"
                            variant="contained"
                            color="success"
                            onClick={() => setState({...state, dialog: true, data_edit: {}})}
                        >
                                {t("expense.addSpending")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <br></br>
            <TabContext value={state.value}>
                <Box sx={{ borderBottom: 1, borderColor: 'rgba(0, 0, 0, 0.12)' }}>
                    <TabList onChange={(event, newValue) => setState({...state, value: newValue})} aria-label="lab API tabs example">
                        <Tab className='text-transform' label={t("expense.tabs.tabTitle1")} value="1" />
                        <Tab className='text-transform' label={t("expense.tabs.tabTitle2")} value="2" />
                    </TabList>
                </Box>
                <TabPanel style={{padding: '10px 0px'}} value="2">
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <Bar options={options} data={data} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel style={{padding: '10px 0px'}} value="1">
                    <Grid item xs={12} md={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow style={{whiteSpace: 'nowrap'}}>
                                        <TableCell>{t("expense.table.no")}</TableCell>
                                        <TableCell>Shop</TableCell>
                                        <TableCell>{t("expense.table.creator")}</TableCell>
                                        <TableCell>{t("expense.table.amountSpent")}</TableCell>
                                        <TableCell>{t("expense.table.spendingContent")}</TableCell>
                                        <TableCell>{t("expense.table.time")}</TableCell>
                                        <TableCell>{t("expense.table.action.title")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        state.expenses.length > 0 &&
                                        <TableRow sx={{background: '#5fa6ffd9'}}>
                                            <TableCell align="right" colSpan={3}>
                                                {t("expense.total")}: 
                                            </TableCell>
                                            <TableCell colSpan={4}>
                                                <b style={{fontSize: '20px'}}>
                                                    {Number(state.total_expense).toLocaleString()} vnđ
                                                </b>
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {
                                        state.loading
                                        ?
                                        <TableRow>
                                            <TableCell colSpan={7} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                        :
                                        state.expenses.length > 0
                                        ?

                                            state.expenses.map((dataItem, key) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{key + 1}</TableCell>
                                                        <TableCell>
                                                            <div className="display-flex align-items-center">
                                                                <div><img src={dataItem.shop.image}></img></div>
                                                                <div style={{marginLeft: '10px'}}>{dataItem.shop.name}</div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className="display-flex align-items-center">
                                                                <div><img src={dataItem.user.image}></img></div>
                                                                <div style={{marginLeft: '10px'}}>{dataItem.user.name}</div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <b>{Number(dataItem.money).toLocaleString()}</b> vnđ
                                                        </TableCell>
                                                        <TableCell>
                                                            {parse(dataItem.note)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {parse(dataItem.date)}
                                                        </TableCell>
                                                        <TableCell>
                                                            <p>
                                                                <Button variant="contained" sx={{marginRight: "10px", minWidth: '45px'}} size="small">
                                                                    <EditRoundedIcon onClick={() => editExpense(key, dataItem)} sx={{fontSize: '20px'}} size="small" className="cursor"/>
                                                                </Button>
                                                                <Tooltip title="Xóa" arrow placement="top-start">
                                                                    <Button onClick={() => deleteExpense(key, dataItem)} color="error" variant="contained" sx={{minWidth: '45px'}} size="small">
                                                                        <DeleteIcon sx={{fontSize: '20px'}} className="cursor"/>
                                                                    </Button>
                                                                </Tooltip>                                                     
                                                            </p>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={7} align="center">
                                                <i>{t("common.message.emptyData")}</i>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <br></br>
                    {
                        state.total_page > 1 &&
                        <Grid item xs={12} md={12} className="display-flex justify-content-center">
                            <Stack spacing={2}>
                                <Pagination
                                    onChange={handleChangePage}
                                    color="primary"
                                    count={state.total_page}
                                    showFirstButton
                                    showLastButton
                                />
                            </Stack>
                        </Grid>
                    }
                    <br></br>
                </TabPanel>
            </TabContext>
        </Container>
    )
}

export default Index;