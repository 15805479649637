import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { requestOptions } from "../../../../../../Helpers/function";
import api from './../../../../../..//Apis'
import { Trans, useTranslation } from "react-i18next"

export default function ProcessPublishPost(props) {
    const { i18n } = useTranslation();
    const [state, setState] = useState({
        post_success: 0,
        post_error: 0
    })

    useEffect(() => {
        const body = {
            process_code: props.data_process.code,
            lang: i18n.language
        }
        const result_process = setInterval(() => {
            fetch(api.list_post_by_process, requestOptions('POST', props.cookie, body))
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        setState({
                            ...state,
                            post_success: result.total_post_publish_success,
                            post_error: result.total_post_publish_error
                        })
                        if (parseInt(result.total_post_publish_success) + parseInt(result.total_post_publish_error) == props.data_process.total) {
                            clearInterval(result_process);
                            props.clearProcess(props.data_process.code, result);
                        }
                    }
                }
            )
        }, 8000);
        
        return () => {
            clearInterval(result_process);
        }
        //fetch(api.publish_post_to_facebook, requestOptions('POST', props.cookie, body));
    }, [])

    return (
        <div style={{marginBottom: '20px'}}>
            <p>
                {/* <b>Tiến trình {parseInt(props.stt) + 1}</b>: Thành công: <b>{state.post_success}/{props.data_process.total}</b> page.  */}
                <Trans
                    i18nKey={"post.progress"}
                    values={
                        {
                            step: parseInt(props.stt) + 1,
                            success: state.post_success,
                            total: props.data_process.total
                        }
                    }
                    components={{ b: <b /> }}
                />
                {/* <span style={{color: 'red'}}> Không thành công: </span> <b>{state.post_error}/{props.data_process.total}</b> page */}
                <Trans
                    i18nKey={"post.progressError"}
                    values={
                        {
                            error: state.post_error,
                            total: props.data_process.total
                        }
                    }
                    components={{
                        span: <span style={{ color: 'red' }} />,
                        b: <b />
                      }}
                />
            </p>
            <LinearProgress/>
        </div>
    )
}