import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import api from './../../../../../Apis';
import { useTranslation } from 'react-i18next'

export default function LinkChatbot(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        dialog: false,
        block_active: {}
    })
    const { register, handleSubmit, setValue, reset} = useForm();

    useEffect(() => {
        setState({
            ...state,
            block_active: props.block_active
        })
    }, [props.block_active])

    const onSaveLinkChatbot = (data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                code_handle: data.code_handle,
                code: props.block_active.code,
                lang: i18n.language
            })
        };
        fetch(api.update_block, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    const block_active = {...state.block_active};
                    block_active.code_handle = data.code_handle;
                    setState({
                        ...state,
                        dialog: false,
                        block_active: block_active
                    })
                    Swal.fire({
                        title: result.message,
                        timer: 1000,
                        icon: "success"
                    })
                    reset();
                } else {
                    Swal.fire({
                        title: result.message,
                        timer: 1000,
                        icon: "error"
                    })
                }
            }
        )
    }

    const copyLink = () => {
        navigator.clipboard.writeText("https://m.me/" + props.fanpage_id + "?ref=" + state.block_active.code_handle);
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: t("common.copied"),
            showConfirmButton: false,
            timer: 1000
        })
    }

    return (
        <p>
            https://m.me/{props.fanpage_id}?ref={state.block_active?.code_handle}
            <Button
                sx={{marginLeft: "10px"}} 
                variant="contained" 
                className="text-transform" 
                size="small" 
                startIcon={<ModeEditOutlinedIcon/>}
                onClick={() => setState({...state, dialog: true})}
            >{t("common.edit")}</Button>
            <Button onClick={copyLink} startIcon={<ContentCopyOutlinedIcon/>} className="text-transform" variant="contained" color="warning" size="small" sx={{marginLeft: "10px"}}>Copy</Button>
            <Dialog
                open={state.dialog}
                fullWidth="true"
                maxWidth='sm'
                onClose={() => setState({...state, dialog: false})}>
                <DialogTitle>{t("chatbot.buildScript.chatbotScriptLink")}</DialogTitle>
                    <form onSubmit={handleSubmit(onSaveLinkChatbot)}>
                        <DialogContent>
                            https://m.me/{props.fanpage_id}?ref=
                            <input
                                defaultValue={props.block_active.code_handle}
                                {...register('code_handle')}
                            ></input><br></br><br></br>
                            <Button
                                type="submit"
                                variant="contained"
                                className="text-transform"
                            >{t("common.update")}</Button>  
                        </DialogContent>
                    </form>
                <DialogContent></DialogContent>
            </Dialog>
        </p>
    )
}