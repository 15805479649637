import { Button, Tooltip } from "@mui/material";
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import Swal from "sweetalert2";
import api from './../../../../../Apis'
import AdfScannerRoundedIcon from '@mui/icons-material/AdfScannerRounded';
import { useTranslation } from "react-i18next"

function PrintBill(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        loading_print_bill: false
    })

    const printBill = () => {
        if (props.order.transport_id == 3 || props.order.transport_id == 4) {
            window.open('/print-bill/' + props.order.code_transport, '_blank');
        } else {
            setState({
                ...state,
                loading_print_bill: true
            })
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({
                    order_id: props.order.code,
                    lang: i18n.language
                })
            };
            fetch(api.get_bill_transport, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        setState({
                            ...state,
                            loading_print_bill: false
                        })
                        window.open(result.file, '_blank');
                    } else {
                        Swal.fire({
                            title: result.message,
                            timer: 2000,
                            icon: 'error'
                        })
                        setState({
                            ...state,
                            loading_print_bill: false
                        })
                    }
                }
            )
        }
    }

    return (
        <Tooltip title={t("order.tooltip.printShippingOrder")} arrow placement="top">
            <LoadingButton
                color="warning"
                sx={{ minWidth: '45px'}}
                size="small"
                loading={false}
                variant="contained"
                onClick={printBill}
            >
            {/* <Button  variant="contained" sx={{ minWidth: '45px'}} size="small"> */}
                <AdfScannerRoundedIcon sx={{fontSize: '20px'}} color="default" className="cursor"/>
            {/* </Button> */}
            </LoadingButton>
        </Tooltip>
    )
}

export default PrintBill;