import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Dialog, DialogTitle } from '@mui/material';
import { useState } from 'react';
import FormCreateProduct from './FromCreateProduct';
import { useTranslation } from "react-i18next";

export default function EditProduct(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        dialog: false,
        data_edit: null
    })

    const editProduct = (value, index) => {
        setState({
            ...state,
            dialog: true,
            data_edit: value
        })
       
    }

    const updateProduct = (data, key) => {
        setState({
            ...state,
            dialog: false
        })
        props.updateProduct(data, key);
    }

    return (
        <div>
            <EditRoundedIcon onClick={() => editProduct(props.value, props.key)} className="cursor" color="primary" sx={{marginRight: "10px"}}/>
            <Dialog open={state.dialog} fullWidth="true" maxWidth='xl' onClose={() => {setState({...state, dialog: false})}}>
                <DialogTitle>{t("product.productInfo")}</DialogTitle>
                <FormCreateProduct
                    data_edit={state.data_edit}
                    cookie={props.cookie}
                    updateProduct={updateProduct}
                    stt={props.stt}
                />
            </Dialog>
        </div>
    )
}