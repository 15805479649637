import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Swal from "sweetalert2"
import api from './../../../../../Apis';
import { useTranslation } from "react-i18next";
export default function InputSaveConstant(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        name: "",
        errors: {}
    })

    useEffect(() => {
        setState({
            ...state,
            name: props.data.constant
        })
    }, [props.data.constant])

    const changeConstant = (e) => {
        setState({
            ...state,
            name: e.target.value
        })
    }

    const updateConstant = () => {
        if (state.name != "" && state.name != null) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({
                    'constant': state.name,
                    'code': props.data.code,
                    'lang': i18n.language
                })
            };
            fetch(api.update_block_message, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result.success && result.type == 'validate') {
                        setState({
                            ...state,
                            errors: result.errors
                        })
                    } else if (!result.success && result.type == undefined) {
                        Swal.fire({
                            title: result.message,
                            timer: 1500,
                            icon: 'error'
                        })
                        if (Object.keys(state.errors).length > 0) {
                            setState({
                                ...state,
                                errors: {}
                            })
                        }
                    } else if (result.success && Object.keys(state.errors).length > 0) {
                        setState({
                            ...state,
                            errors: {}
                        })
                    }
                }
            )
        }
    }

    return (
        <TextField
            variant="outlined"
            size="small"
            margin="dense"
            error={state.errors?.constant ? true: false}
            helperText={state.errors?.constant?.message}
            fullWidth
            value={state.name}
            onChange={changeConstant}
            onBlur={updateConstant}
            label={t("chatbot.buildScript.variableName")}
        />
    )
}