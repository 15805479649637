import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function CopyData(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        dialog: false
    })
    const {register, handleSubmit: handleSubmit1, reset} = useForm();

    const onCopyData = (data) => {
        props.onCopyData(data);
        setState({
            ...state,
            dialog: false
        })
        reset();
    }

    return (
        <div>
            <Dialog open={state.dialog} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog: false})}}>
                <DialogTitle>
                    {t("product.dataSyncInfo")}
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit1(onCopyData)}>
                        <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                            <Grid item xs={6}>
                                <TextField
                                fullWidth
                                {...register('price_root')}
                                size="small"
                                margin="dense"
                                label={t("product.label.importPrice")}
                                type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                fullWidth
                                size="small"
                                {...register('price')}
                                margin="dense"
                                label={t("product.label.sellingPrice")}
                                type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                fullWidth
                                size="small"
                                margin="dense"
                                {...register('amount')}
                                label={t("product.label.quantity")}
                                type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                fullWidth
                                size="small"
                                margin="dense"
                                {...register('weight')}
                                label={t("product.label.weight")}
                                type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                fullWidth
                                size="small"
                                margin="dense"
                                {...register('amount_warning_sold')}
                                label={t("product.label.stockWarningQuantity")}
                                type="number"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary" className="text-transform">
                                   {t("common.confirm")}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>                               
            <Button
                type="button"
                variant="contained"
                color="success"
                sx={{marginRight: '10px'}}
                className="text-transform"
                onClick={() => setState({...state, dialog: true})}
            >
                {t("product.dataSynchronization")}
            </Button>
        </div>
    )
}