import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import api from './../../../../../Apis';
import { useTranslation } from "react-i18next"

function FormCreateBill(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        dialog: false,
        loading_create_bill: false,
        loading_fee_ship: false,
        loading_print_bill: false,
        loading_get_service: false,
        fee_ship: null,
        error: {
            transport_id: '',
            warehouse_id: '',
        },
        list_service_viettel_post: []
    })
    const {register, handleSubmit, reset, control} = useForm();
    const createBill = (data) => {
        if ((data.required_note == '' || data.required_note == undefined) && data.transport_id == 2) {
            Swal.fire({
                title: t("order.selectGhnWarning"),
                icon: 'warning',
            });
        } else {
            setState({
                ...state,
                loading_create_bill: true,
            })
            if (props.order_id == null) {
                const list_order_ids = [];
                props.state.orders.map((value, index) => {
                    if (value.is_checked) {
                        list_order_ids.push(value.code);
                    }
                })
                var api_url = api.create_bill_picked;
                data['list_order_ids'] = list_order_ids;
            } else {
                data['order_id'] = props.order_id;
                var api_url = api.create_bill_transport;
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({...data, lang: i18n.language})
            };
            fetch(api_url, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        setState({
                            ...state,
                            dialog: false,
                            loading_create_bill: false,
                            error: {
                                transport_id: '',
                                warehouse_id: '',
                            }
                        })
                        if (props.order_id != null && props.type == 'single-order') {
                            props.refreshOrder(result.data);
                        }
                        Swal.fire({
                            title: result.message,
                            icon: 'success',
                            timer: 1500
                        })
                    } else {
                        if (result.type == 'validate') {
                            setState({
                                ...state,
                                error: result.errors,
                                loading_create_bill: false
                            })
                        } else if (result.errors != undefined) {
                            var text_error = '';
                            result.errors.map((value, key) => {
                                text_error+= '<p>' + value + '</p>';
                            })
                            Swal.fire({
                                title: result.message,
                                icon: 'warning',
                                html: text_error
                            })
                            setState({
                                ...state,
                                loading_create_bill: false,
                                error: {
                                    transport_id: '',
                                    warehouse_id: '',
                                }
                            })
                        } else {
                            Swal.fire({
                                title: 'Vui lòng thử lại',
                                text: result.message,
                                icon: 'error',
                            })
                            setState({
                                ...state,
                                loading_create_bill: false,
                                error: {
                                    transport_id: '',
                                    warehouse_id: '',
                                }
                            })
                        }
                    }
                }
            )
        }
        
    }

    const getService = (data) => {
        setState({
            ...state,
            loading_get_service: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                order_id: props.order_id,
                warehouse_id: data.warehouse_id,
                lang: i18n.language
            })
        };
        fetch(api.get_service_viettel_post, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        loading_get_service: false,
                        list_service_viettel_post: result.list_services
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error'
                    })
                    setState({
                        ...state,
                        loading_get_service: false,
                    })
                }
            }
        )
    }

    const checkFeeShip = (data) => {
        setState({
            ...state,
            loading_fee_ship: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                order_id: props.order_id,
                warehouse_id: data.warehouse_id,
                service_id: data.service_id,
                cod: data.cod,
                insurance_value: data.insurance_value,
                lang: i18n.language
            })
        };
        fetch(api.check_fee_ship, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        loading_fee_ship: false,
                        fee_ship: result.fee
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error'
                    })
                    setState({
                        ...state,
                        loading_fee_ship: false,
                    })
                }
            }
        )
    }

    const resultFeeShip = () => {
        return Number(state.fee_ship).toLocaleString() + ' đ';
        if (props.state.order.transport != 3) {
            // Viettel Post
            return Number(state.fee_ship).toLocaleString() + ' đ';
        } else if (props.state.order.transport == 3) {
            // Vnpost
            return (
                <div>
                    <span>
                        Nhanh: {Number(state.fee_ship.fee_fast).toLocaleString()}
                    </span>
                    <span style={{marginLeft: '15px'}}>Chậm: {Number(state.fee_ship.fee_slow).toLocaleString()}</span>
                </div>
                
            )
        }
    }

    return (
        <form onSubmit={handleSubmit(createBill)}>
            <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                <Grid item xs={12}>
                    <FormControl margin="dense" fullWidth error={state.error.warehouse_id != undefined && state.error.warehouse_id != '' ? true : false}>
                        <InputLabel id="demo-simple-select-label">{t("order.label.selectWarehouse")}*</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t("order.label.selectWarehouse")}
                            {...register('warehouse_id')}
                        >
                            {
                                props.state.warehouses.map((value, index) => {
                                    if (value.transport_id == props.value) {
                                        return (
                                            <MenuItem value={value.id}>
                                                {value.name}
                                            </MenuItem>
                                        )
                                    }

                                })
                            }
                        </Select>
                        <FormHelperText>{state.error.warehouse_id}</FormHelperText>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={6}>
                    <FormControl margin="dense" fullWidth error={state.error.transport_id != undefined && state.error.transport_id != '' ? true : false}>
                        <InputLabel id="demo-simple-select-label">Hãng vận chuyển *</InputLabel>
                        <Select
                            {...register('transport_id')}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Đơn vị vận chuyển"
                            value={props.state.order.transport}                                 
                        >
                            <MenuItem value={props.state.order.transport}>
                                {props.state.transports[props.state.order.transport]}
                            </MenuItem>
                            {
                                props.state.transports.map((value, index) => {
                                    return (
                                        <MenuItem value={index}>
                                            {value}
                                        </MenuItem>
                                    )
                                    
                                })
                            }
                        </Select>
                        <FormHelperText>{state.error.transport_id}</FormHelperText>
                    </FormControl>
                </Grid> */}
                {
                    (props.value == 2 || props.value == 1) &&
                    <Grid item xs={props.value == 2 ? 6 : 12}>
                        <FormControl margin="dense" fullWidth>
                            <InputLabel id="demo-simple-select-label">{t("order.label.service")}*</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t("order.label.service")}
                                required
                                {...register('service_id')}
                            >
                                <MenuItem value="1">
                                    {t("order.air")}
                                </MenuItem>
                                <MenuItem value="2">
                                    {t("order.land")}
                                </MenuItem>
                                {/* <MenuItem value="53320">
                                    Chuyển phát tiêu chuẩn
                                </MenuItem> */}
                            </Select>
                            {/* <FormHelperText>
                                Nếu chọn đơn vị GHN thì cần chọn lưu ý giao hàng
                            </FormHelperText> */}
                        </FormControl>
                    </Grid>
                }
                {
                    props.value == 3 &&
                    <Grid item xs={12}>
                        <FormControl margin="dense" fullWidth>
                            <InputLabel id="demo-simple-select-label">{t("order.label.service")}*</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t("order.label.service")}
                                required
                                {...register('service_id')}
                            >
                                <MenuItem value="EMS">
                                    {t("order.expressDelivery")}
                                </MenuItem>
                                <MenuItem value="BK">
                                    {t("order.standardDelivery")}
                                </MenuItem>
                                <MenuItem value="TMDT_BK">
                                    {t("order.standardShipping")}
                                </MenuItem>
                            </Select>
                            {/* <FormHelperText>
                                Nếu chọn đơn vị GHN thì cần chọn lưu ý giao hàng
                            </FormHelperText> */}
                        </FormControl>
                    </Grid>
                }
                {
                    props.value == 2 &&
                    <Grid item xs={6}>
                        <FormControl margin="dense" fullWidth>
                            <InputLabel id="demo-simple-select-label">{t("order.deliveryNote")}*</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t("order.deliveryNote")}
                                {...register('required_note')}
                            >
                                <MenuItem value="CHOTHUHANG">
                                    {t("order.confirmTrialDelivery")}
                                </MenuItem>
                                <MenuItem value="CHOXEMHANGKHONGTHU">
                                    {t("order.viewGoodsWithoutTrial")}
                                </MenuItem>
                                <MenuItem value="KHONGCHOXEMHANG">
                                    {t("order.noViewGoods")}
                                </MenuItem>
                            </Select>
                            {/* <FormHelperText>
                                Nếu chọn đơn vị GHN thì cần chọn lưu ý giao hàng
                            </FormHelperText> */}
                        </FormControl>
                    </Grid>
                }
                {/* {
                    props.value == 2 &&
                    <Grid item xs={12}>
                        <FormControl margin="dense" fullWidth>
                            <InputLabel id="demo-simple-select-label">Trả phí ship*</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Trả phí dịch vụ"
                                defaultValue="1"
                                {...register('payment_type_id')}
                            >
                                <MenuItem value="1">
                                    Cần trừ số dư tài khoản
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                } */}
                {/* {
                    <Grid item xs={12}>
                        <FormControl margin="dense" fullWidth>
                            <InputLabel id="demo-simple-select-label">Trả phí ship*</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Trả phí dịch vụ"
                                defaultValue="2"
                                {...register('payment_type_id')}
                            >
                                <MenuItem value="2">
                                    Cấn trừ từ số dư tài khoản
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                } */}
                {
                    props.value == 2 &&
                    <Grid item xs={12}>
                        <TextField defaultValue="0" margin="dense" {...register('insurance_value')} autoComplete="off" fullWidth id="outlined-basic" label={t("order.label.declareValue")} variant="outlined" />
                        <small>
                            {t("order.maxValueInfo")}
                        </small>
                    </Grid>
                }
                {/* <Grid item xs={12}>
                    <TextField
                        {...register('insurance')}
                        autoComplete="off"
                        fullWidth
                        id="outlined-basic"
                        label="Số tiền đòi bồi thường khi gặp sự cố"
                        variant="outlined"
                        helperText={state.error.insurance}
                        error={state.error.insurance != undefined && state.error.insurance != '' ? true : false}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <TextField margin="dense" {...register('note')} autoComplete="off" fullWidth id="outlined-basic" label={t("order.label.note")} variant="outlined" />
                </Grid>
                {
                    state.list_service_viettel_post.length > 0 &&
                    <Grid item xs={12}>
                        <FormControl margin="dense" fullWidth>
                            <InputLabel id="demo-simple-select-label">{t("order.label.service")}*</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t("order.label.service")}
                                {...register('service_id')}
                            >
                                {
                                    state.list_service_viettel_post.map((value, key) => {
                                        return (
                                            <MenuItem value={value.MA_DV_CHINH}>
                                                {value.TEN_DICHVU} - {Number(value.GIA_CUOC).toLocaleString()} vnđ
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            {/* <FormHelperText>
                                Nếu chọn đơn vị GHN thì cần chọn lưu ý giao hàng
                            </FormHelperText> */}
                        </FormControl>
                    </Grid>
                }
                {
                    props.value == 4 &&
                    <Grid item xs={12}>
                        <LoadingButton onClick={handleSubmit(getService)} loading={state.loading_get_service} className="text-transform" variant="outlined" color="info">{t("order.findAppropriateService")}</LoadingButton>
                    </Grid>
                }
                {/* {
                    props.value == 2 &&
                    <Grid item xs={12}>
                        <small>
                            Chú ý: <span style={{color: 'red'}}>Nếu phí ship là người nhận trả thì tiền COD thu hộ sẽ cộng cả phí ship vào.</span>
                        </small>
                    </Grid>
                } */}
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel {...register('cod')} control={<Checkbox defaultChecked />} label={t("order.label.codCollection")} />
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    <LoadingButton sx={{marginRight: '10px'}} type="submit" loading={state.loading_create_bill} variant="contained">
                        {t("common.confirm")}
                    </LoadingButton>
                    {
                        (props.type == 'single-order' || props.type == 'list-order') &&
                        <LoadingButton onClick={handleSubmit(checkFeeShip)} loading={state.loading_fee_ship} variant="contained" color="warning" className="text-transform">
                            {t("order.checkShippingFee")}
                        </LoadingButton>
                    }

                </Grid>
                <Grid item xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                    {
                        state.fee_ship != null &&
                        <b style={{fontSize: '20px'}}>
                            {resultFeeShip()}
                        </b> 
                    }
                </Grid>
            </Grid>
        </form>
    )
}

export default FormCreateBill;