const initState = {
    message: '',
    conversation_id: '',
    new_conversation: ''
}
const userReducer = (state = initState, action) => {
    switch (action.type) {
        case 'MESSAGE':
            return action.payload; 
        default:
            return state;
    }
}

export default userReducer;