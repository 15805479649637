import { Autocomplete, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next"

export function ListFanpage(props) {
    const { t } = useTranslation();
    const onChangeFanpage = (e, data) => {
        props.redirectToFanpage(data.fanpage_id);
    }

    return (
        <Autocomplete
            id="tags-outlined"
            options={props.list_fanpage}
            getOptionLabel={(option) => option.name + ' (' + option.fanpage_id + ')'}
            value={props.fanpage_active}
            onChange={onChangeFanpage}
            filterSelectedOptions
            size="small"
            renderInput={(params) => (
            <TextField
                size="small"
                {...params}
                label={t("common.selectFanpage")}
            />
            )}
        />
        // <Controller
        //      render={(props) => (
        //         <Autocomplete
        //         onChange={(e, value) => {
        //             props.field.onChange(value.fanpage_id);
        //         }}
        //         size="small"
        //         onChange={onChangeFanpage}
        //         {...props}
        //         value={props.fanpage_active}
        //         options={props.list_fanpage}
        //         getOptionLabel={(option) => option.name}
        //         renderInput={(params) => (
        //             <TextField
        //             {...params}
        //             label="Chọn Fanpage *"
        //             variant="outlined"
        //             />
        //         )}
        //         />
        //      )}
        //      name="fanpage_id"
        //      control={control}
        //  />
    )
}