import { Button } from "@mui/material";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from "react-router-dom";

export default function GoBack() {
    let navigate = useNavigate();
    
    return (
        <p>
            <Button
                className="text-transform"
                startIcon={<ArrowBackRoundedIcon/>}
                variant="contained"
                color="inherit"
                onClick={() => navigate(-1)}
            >Quay lại</Button>
        </p>
    )
}