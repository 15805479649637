import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import ListOrder from "./ListOrder";
import Order from "./Index";
import { useEffect, useState } from "react";
import api from './../../../../../../Apis'
import { useTranslation } from "react-i18next";

function TabContent(props) {
    const { t, i18n } = useTranslation();
    const [value, setValue] = useState('1');
    const [state, setState] = useState({
        orders: [],
        provinces: [],
        conversation: {}
    })

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                conversation_id: props.state.conversation_id,
                lang: i18n.language
            })
        };
        fetch(api.get_list_order_by_conversation, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        orders: result.orders,
                        provinces: result.provinces,
                        conversation: result.conversation
                    })
                }
            }
        )
    }, [props.state.conversation_id])

    return (
        <TabContext key={props.state.conversation_id}  value={value}>
            <Box className='title-bill' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList style={{width: "100%"}} onChange={handleChange} aria-label="lab API tabs example">
                    <Tab style={{width: "50%"}} label={t("liveChat.tabContent.createOrder")} value="1" />
                    <Tab style={{width: "50%"}} label={t("liveChat.tabContent.orderList")} value="2" />
                </TabList>
            </Box>
            <TabPanel className='form-book' value="1">
                <Order
                    // key={props.state.conversation_id}
                    fanpage_id={props.fanpage_id} 
                    state={state}
                    state_parent={props.state}
                    cookie={props.cookie}
                />
            </TabPanel>
            <TabPanel className='form-book' value="2">
                <ListOrder orders={state.orders}/>
            </TabPanel>
        </TabContext>
    )
}

export default TabContent;