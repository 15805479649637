import { Grid, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import Avatar from '@mui/material/Avatar';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';

function FanpageItem(props) {
    const runOrPauseReciveMessage = (data, index, status) => {
        props.runOrPauseReciveMessage(data, index, status)
    }

    const remove = (data, index) => {
        props.remove(data, index);
    }

    const runOrPauseReciveComment = (data, index, status) => {
        props.runOrPauseReciveComment(data, index, status);
    }

    return (
        <Grid item xs={6} md={2}>
            <div className='fanpage-item'>
                <p>
                    {/* <Link to={'/dashboard/fanpage/' + props.data.fanpage_id + '/tin-nhan'}> */}
                    <Link to={'/dashboard/live-chat/'}>
                        {
                            props.data.fanpage_avatar != null && <Avatar alt={props.data.fanpage_name} src={props.data.fanpage_avatar} sx={{margin: 'auto'}} />
                            // <img className='avatar-fanpage' src=></img>
                        }
                    </Link>
                </p>
                <p className='name-page'>
                    {/* <Link to={'/dashboard/fanpage/' + props.data.fanpage_id + '/tin-nhan'}> */}
                    <Link to={'/dashboard/live-chat/'}>
                        <Tooltip arrow title={props.data.fanpage_name} placement="top">
                            <span>
                                {props.data.fanpage_name}
                            </span>
                        </Tooltip>
                    </Link>
                </p>
                {/* <p className='m-10'>
                    <span className={'status ' + props.data.class}>
                        {props.data.status}
                    </span>
                </p> */}
                <p className='m-10'>
                    {Number(props.data.fanpage_like).toLocaleString()} Like
                </p>
                <br></br>
                <p className='m-10'>
                    {
                        props.social_name == "facebook" &&
                        <Link to={"/dashboard/fanpage/" + props.data.fanpage_id + '/bai-viet'}>
                            <Tooltip arrow title="Bài viết" placement="top">
                                <PostAddRoundedIcon className="option-fanpage option-fanpage-post"/>
                            </Tooltip>
                        </Link>
                    }
                    {
                        props.data.value_status && props.social_name == "facebook"
                        ?
                        <Tooltip arrow title={props.title_pause} placement="top">
                            <PauseOutlinedIcon onClick={() => runOrPauseReciveMessage(props.data, props.index, 0)} className="cursor option-fanpage-pause-message option-fanpage"/>
                        </Tooltip>
                        :
                        props.social_name == "facebook" &&
                        <Tooltip arrow title={props.title_play} placement="top">
                            <PlayCircleFilledWhiteOutlinedIcon onClick={() => runOrPauseReciveMessage(props.data, props.index, 1)} className="cursor option-fanpage-pause-message option-fanpage"/>
                        </Tooltip>
                    }
                    {
                        props.social_name == "facebook" &&
                        <Tooltip arrow title={props.data.value_status_comment ? 'Dừng nhận bình luận từ Facebook' : 'Kết nối nhận bình luận từ Facebook'} placement="top">
                            {
                                props.data.value_status_comment
                                ?
                                <MarkChatReadIcon onClick={() => runOrPauseReciveComment(props.data, props.index, 0)} sx={{background: 'rgb(156, 39, 176)'}} className="cursor option-fanpage-pause-message option-fanpage"/>
                                :
                                <SpeakerNotesOffIcon onClick={() => runOrPauseReciveComment(props.data, props.index, 1)} sx={{background: 'rgb(156, 39, 176)'}} className="cursor option-fanpage-pause-message option-fanpage"/>
                            }
                        </Tooltip>
                    }
                </p>
                <p style={{margin: '0px 0px 0px 0px'}}>
                    <Tooltip arrow title="Xóa" placement="top">
                        <DeleteOutlineOutlinedIcon onClick={() => remove(props.data, props.index)} className="cursor option-fanpage option-fanpage-remove"/>
                    </Tooltip>
                    {
                        props.social_name == "facebook" &&
                        <Link to="/dashboard/chatbot">
                            <Tooltip arrow title="Kịch bản chatbot" placement="top">
                                <ChatOutlinedIcon className="cursor option-fanpage option-fanpage-chatbot"/>
                            </Tooltip>
                        </Link>
                    }
                    {
                        props.social_name == 'facebook' &&
                        <Link to={props.data.fanpage_id + "/customer"}>
                            <Tooltip arrow title="Khách hàng" placement="top">
                                <PeopleOutlineOutlinedIcon style={{background: '#828282'}} className="cursor option-fanpage option-fanpage-chatbot"/>
                            </Tooltip>
                        </Link>
                    }
                </p>
            </div>
        </Grid>
    )
}

export default FanpageItem;