import { Autocomplete, Button, Grid, LinearProgress, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import SearchIcon from '@mui/icons-material/Search';
import api from './../../../../../Apis'
import { requestOptions } from "../../../../../Helpers/function";
import Swal from "sweetalert2";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import ProcessGetCustomer from "./ProcessGetCustomer";
import { useTranslation, Trans } from "react-i18next"

export default function CustomerOld(props) {
    const { t, i18n } = useTranslation();
    const {register, handleSubmit, reset, control} = useForm();
    const [state, setState] = useState({
        loading_button: false,
        is_process: false
    })

    const getCustomerOld = (data) => {
        data['fanpage_id'] = props.fanpage_id;
        data['lang'] = i18n.language;
        setState({
            ...state,
            loading_button: true,
            is_process: true
        })
        fetch(api.list_customer_old, requestOptions('POST', props.cookie, data))
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'warning'
                    })
                }
                setState({
                    ...state,
                    loading_button: false,
                    is_process: false
                })
            }
        )
    }
    const fanpages = props.fanpages;
    return (
        <div>
            <h3 style={{marginBottom: '0px'}}>{t("customer.scanOldCustomers")}</h3>
            <Grid container spacing={2}>
                <Grid item xs={3} md={3}>
                    <p>
                        {/* Gói <b>FREE</b>: lấy tối đa thêm <b>{Number(props.max_customers?.free).toLocaleString()}</b> khách hàng cũ */}
                        <Trans
                            i18nKey={"customer.freePackage"}
                            values={{
                                maxCustomers :Number(props.max_customers?.free).toLocaleString()
                            }}
                            components={{ b: <b /> }}
                        />
                    </p>
                    <p>
                        {/* Gói <b>PRO</b>: lấy tối đa thêm <b>{Number(props.max_customers?.pro).toLocaleString()}</b> khách hàng cũ */}
                        <Trans
                            i18nKey={"customer.proPackage"}
                            values={{
                                maxCustomers :Number(props.max_customers?.pro).toLocaleString()
                            }}
                            components={{ b: <b /> }}
                        />
                    </p>
                </Grid>
                <Grid item xs={4} md={4}>
                    <p>
                        {/* Gói <b>GROW</b>: lấy tối đa thêm <b>{Number(props.max_customers?.grow).toLocaleString()}</b> khách hàng cũ */}
                        <Trans
                            i18nKey={"customer.growPackage"}
                            values={{
                                maxCustomers :Number(props.max_customers?.grow).toLocaleString()
                            }}
                            components={{ b: <b /> }}
                        />
                    </p>
                    <p>
                        {/* Gói <b>ENTERPRISE</b>: lấy tối đa thêm <b>{Number(props.max_customers?.enterprise).toLocaleString()}</b> khách hàng cũ */}
                        <Trans 
                            i18nKey={"customer.enterprisePackage"}
                            values={{
                                maxCustomers :Number(props.max_customers?.enterprise).toLocaleString()
                            }}
                            components={{ b: <b /> }}
                        />
                    </p>
                </Grid>
            </Grid>
            <p>
                {/* Tổng khách hàng hiện tại là <b>{Number(props.total_customer_all).toLocaleString()}</b>.
                Bạn đang sử dụng gói <b style={{textTransform: 'uppercase'}}>{props.package_use}</b>, */}
                <Trans
                    i18nKey={"customer.currentTotal"}
                    values={{
                        totalCustomers: Number(props.total_customer_all).toLocaleString(),
                        packageUse: props.package_use
                    }}
                    components={{ b: <b /> }}
                />
                {
                    props.total_customer_all > props.max_customers?.pro
                    ?
                    t("customer.limitExceeded")
                    :
                    // ' bạn quét tối đa được ' + Number(props.max_customers?.pro - props.total_customer_all) + ' khách hàng nữa.'
                    t("customer.canScanMore", {remaining: Number(props.max_customers?.pro - props.total_customer_all)})
                }
            </p>
            {
                state.is_process &&
                <ProcessGetCustomer owner_id={props.owner_id}/>
            }
            <form onSubmit={handleSubmit(getCustomerOld)}>
                <Grid container spacing={2}>
                    <Grid item xs={2} md={2}>
                        <TextField
                            type="number"
                            id="outlined-select-currency"
                            size="small"
                            fullWidth
                            label={t("customer.label.quantity")}
                            {...register('amount')}
                        >
                        </TextField>
                    </Grid>
                    {/* <Grid item xs={3} md={3}>
                        <Controller
                            render={(props) => (
                                <Autocomplete
                                onChange={(e, value) => {
                                    props.field.onChange(value.fanpage_id);
                                }}
                                size="small"
                                {...props}
                                options={fanpages}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Chọn Fanpage *"
                                    variant="outlined"
                                    />
                                )}
                                />
                            )}
                            name="fanpage_id"
                            control={control}
                        />
                    </Grid> */}
                    <Grid item xs={2}>
                        <LoadingButton startIcon={<SearchIcon/>} type="submit" className="text-transform" loading={state.loading_button} variant="contained">
                            {t("common.confirm")}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}