import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import api from './../../../../../Apis';
import Swal from "sweetalert2";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

function DetailBillTransport(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        loading_cancel: false,
        loading_update: false
    })
    const cancelBill = () => {
        Swal.fire({
            title: t("common.alert.confirmDelete"),
            showDenyButton: true,
            confirmButtonText: t("common.alert.confirm"),
            denyButtonText: t("common.alert.cancel"),
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setState({
                    ...state,
                    loading_cancel: true
                })
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        order_id: props.state.order.code, 
                        lang: i18n.language
                    })
                };
                fetch(api.cancel_bill_transport, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            Swal.fire({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                timer: 1500
                            })
                        }
                        setState({
                            ...state,
                            loading_cancel: false
                        })
                    }
                )
            }
        })
    }

    const {register, handleSubmit, reset} = useForm();

    const updateStatusTransport = (data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                status_transport: data.status_transport,
                order_id: props.state.order.code,
                lang: i18n.language
            })
        };
        fetch(api.update_status_transport_order, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        timer: 2000,
                        icon: 'success'
                    })
                }

            }
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <p className="m-t-0">
                    <LoadingButton loading={state.loading_cancel} onClick={cancelBill} startIcon={<CloseRoundedIcon/>} className="text-transform" color="error" variant="contained">
                        {t("order.detailBillTransport.cancelTheBillOfLading")}
                    </LoadingButton>
                </p>
                <form onSubmit={handleSubmit(updateStatusTransport)}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell width='15%'>
                                        {t("order.detailBillTransport.unit")}
                                    </TableCell>
                                    <TableCell>
                                        <b>
                                            {props.state.transports[props.state.order.transport]}
                                        </b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width='15%'>
                                        {t("order.detailBillTransport.sender")}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            props.state.order.sender != '' &&
                                            <span>
                                                {props.state.order.sender.name} - {props.state.order.sender.phone} ({props.state.order.sender.address}, {props.state.order.sender.ward}, {props.state.order.sender.district}, {props.state.order.sender.province})
                                            </span>
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width='15%'>
                                        {t("order.table.shippingFee")}
                                    </TableCell>
                                    <TableCell>
                                        <b style={{fontSize: '20px', color: 'green'}}>
                                            {/* {Number(props.state.order.data_transport.fee).toLocaleString()} VNĐ */}
                                            {Number(props.state.order.fee_ship).toLocaleString()} VNĐ
                                        </b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width='15%'>
                                       {t("order.detailBillTransport.returnFee")}
                                    </TableCell>
                                    <TableCell>
                                        <b style={{fontSize: '20px', color: 'red'}}>
                                            {/* {Number(props.state.order.data_transport.fee).toLocaleString()} VNĐ */}
                                            {Number(props.state.order.return_fee_ship).toLocaleString()} VNĐ
                                        </b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width='15%'>
                                        {t("order.label.codCollection")}
                                    </TableCell>
                                    <TableCell>
                                        <b style={{fontSize: '20px', color: '#1B74E4'}}>
                                            {/* {Number(props.state.order.data_transport.fee).toLocaleString()} VNĐ */}
                                            {Number(props.state.order.cod_money).toLocaleString()} VNĐ
                                        </b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("order.table.orderCode")}</TableCell>
                                    <TableCell>
                                        <b>{props.state.order.code_transport}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("order.table.status")}</TableCell>
                                    <TableCell>
                                        <b style={{fontSize: '20px'}}>
                                            {props.state.order.status_transport_id} - {props.state.order.status_transport}
                                        </b>
                                        {/* <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Trạng thái</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Trạng thái"
                                                    {...register('status_transport')}
                                                    defaultValue={props.state.order.status_transport_id}
                                                >
                                                    {
                                                        Object.keys(props.state.status_transports).map(function(key) {
                                                            // return <option value={key}>{tifs[key]}</option>
                                                            return (
                                                                <MenuItem value={key}>
                                                                    {key} - {props.state.status_transports[key]}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl> */}
                                        {/* <b>{props.state.order.status_transport}</b> */}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Tracking ID</TableCell>
                                    <TableCell>
                                        <b>{props.state.order.tracking_id}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("order.detailBillTransport.pickupTime")}</TableCell>
                                    <TableCell>
                                        <b>{props.state.order.data_transport.estimated_pick_time}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("order.detailBillTransport.shippingTime")}</TableCell>
                                    <TableCell>
                                        <b>{props.state.order.data_transport.estimated_deliver_time}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("order.detailBillTransport.insuranceFee")}</TableCell>
                                    <TableCell>
                                        <b>{props.state.order.data_transport.insurance_fee}</b>
                                    </TableCell>
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <LoadingButton type="submit" loading={state.loading_update} variant="contained">
                                            Cập nhật
                                        </LoadingButton>
                                    </TableCell>
                                </TableRow> */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </form>
            </Grid>
        </Grid>
    )
}

export default DetailBillTransport;