import { Grid } from "@mui/material";
import { createSelectable } from "react-selectable-fast";

const ImageItem = ({ selectableRef, isSelected, isSelecting, value })  => (
    <Grid item xs={2} sx={{position: 'relative'}}>
        {/* <input {...register('links')} style={{position: 'absolute', right: '0px', borderRadius: 'unset'}} type="checkbox" name="links" value={value.link}></input> */}
        {/* <Radio {...register('links')} name="links" value={value.link} size="medium" sx={{position: 'absolute', right: '0px', borderRadius: 'unset'}}></Radio> */}
        <div ref={selectableRef} className={`cursor-pointer block-image ${isSelected ? "image-selected" : ""} ${isSelecting ? "selecting" : ""}`}>
            <img className='lib-image' src={value.link}></img>
            <p className='name'>
                {value.name}
            </p>
            {/* <p className='m-b-0'>
                <Button onClick={() => deleteImage(value.id, index) } color='error' startIcon={<DeleteRoundedIcon/>} size='small' variant="contained">Xóa</Button>
            </p> */}
        </div>
    </Grid>
)


export default createSelectable(ImageItem);