import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from "react";
import api from './../../../../../../../Apis'
import { requestOptions } from '../../../../../../../Helpers/function';
import { useTranslation } from "react-i18next";

export default function LoadMoreConversation(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        loading: false
    })

    const loadMore = () => {
        setState({
            ...state,
            loading: true
        })
        const filter = props.filter_search;
        filter['offset'] = props.conversations.length;
        filter['conversation_type'] = 'search';
        filter['limit'] = 5;
        filter['lang'] = i18n.language;
        fetch(api.get_conversation, requestOptions('POST', props.cookie, filter))
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    props.loadMoreConversation(result);
                    setState({
                        ...state,
                        loading: false
                    })
                } else {
                    setState({
                        ...state,
                        loading: false
                    })
                }
            }
        )
    }

    return (
        <div className="load-more">
            <center>
                <LoadingButton onClick={loadMore} loading={state.loading} className="text-transform" size="small" variant="contained">{t("common.loadMore")}</LoadingButton>
            </center>  
        </div>
    )
}