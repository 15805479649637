import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

function TimeLineStatusOrder(props) {
    return (
        <Timeline position="alternate">
            {
                props.status.map((value, index) => {
                    return (
                        <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                                {
                                    props.history_orders[index] != null ? props.history_orders[index].time : <i>...</i>
                                }
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot style={{background: value.color}} />
                            <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <b>
                                    {value.name}
                                </b>
                                
                                {
                                    props.history_orders[index] != null && <small> ({props.history_orders[index].user})</small>
                                }
                            </TimelineContent>
                        </TimelineItem>
                    )
                })
            }
        </Timeline>
    )

}

export default TimeLineStatusOrder;