import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import SlowMotionVideoOutlinedIcon from '@mui/icons-material/SlowMotionVideoOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useRef, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useState } from "react";
import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import api from '../../../../../../../Apis';
import Swal from 'sweetalert2'
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { Controller, useForm } from "react-hook-form";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { FormControlLabel, Paper, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MarkUnreadChatAltRoundedIcon from '@mui/icons-material/MarkUnreadChatAltRounded';
import Radio from '@mui/material/Radio';
import { useDispatch, useSelector } from 'react-redux';
import { getMessSample } from '../../../../../../../Store/Actions/GetMessSample';
import DialogBlock from './DialogBlock';
import ButtonUploadImage from './ButtonUploadImage';
import { SelectableGroup } from 'react-selectable-fast';
import ImageItem from './ImageItem';
import DialogAttachmentManager from '../../../../../../Globals/DialogAttachmentManager';
import { useTranslation } from "react-i18next";

function ManageAttachment(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState(
        {
            open: false,
            dialog_link: false,
            dialog_mess: false,
            dialog_mess_coupon: false,
            dialog_block: false,
            attachments: [],
            errors: [],
            image_selected: [],
            input_link: false,
            new_image: [],
            isOpenDialogAttachment: false
        }  
    );
    const data_redux = useSelector(state => state.mess_sample);
    const inputFile = useRef(null);
    const buttonSubmit = useRef(null);
    const {register, handleSubmit, reset, control, getValues, setValue} = useForm();
    const dispatch = useDispatch();
    const selectFile = (e) => {
        inputFile.current.click();
    }

    const updateAttachment = (newImages) => {
        const attachments = newImages.concat(state.attachments);
        setState({
            ...state,
            new_image: newImages,
            attachments: attachments
        });
    }

    const closeDialogAttachment = () => {
        setState({...state, isOpenDialogAttachment: false})
    }

    const handleClickOpen = () => {
        setState({...state, isOpenDialogAttachment: true})
        // reset();
        // setState({...state, open: true});
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + props.cookie
        //     },
        //     body: JSON.stringify({
        //         fanpage_id: props.fanpage_id,
        //     })
        // };
        // fetch(api.get_attachment, requestOptions)
        // .then(res => res.json())
        // .then(
        //     (result) => {
        //         if (result.success) {
        //             setState({
        //                 ...state,
        //                 attachments: result.datas,
        //                 image_selected: [],
        //                 open: true
        //             })
        //         }
        //     }
        // )
    }

    const onSelected = (selected) => {
        if (selected.length) {
            const image_selected = [];
            selected.map((item) => {
                image_selected.push({
                    'mime_type': "image/jpeg",
                    'file_url': null,
                    'video_data': null,
                    'image_data': item.props.value.link,
                    'code': item.props.value.code
                })
            })
            setState({...state, image_selected: image_selected})
        } else {
            setState({...state, image_selected: []})
        }
    }

    const handleClose = () => {
        setState({...state, open: false});
    }

    // const handleSubmitImage = () => {
    //     buttonSubmit.current.click();
    // }

    const handleChangeKeySearch = (e) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                fanpage_id: props.fanpage_id,
                name_file: e.target.value,
                lang: i18n.language
            })
        };
        fetch(api.search_name_file, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success == true) {
                    setState({...state, attachments: result.datas});
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
    }

    const onDeleteImage = () => {
        Swal.fire({
            title: t("common.alert.confirmDelete"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("common.alert.confirm"),
            cancelButtonText: t("common.alert.cancel")
        }).then((result) => {
            if (result.isConfirmed) {
                const image_codes = [];
                state.image_selected.map((item) => {
                    image_codes.push(item.code)
                })
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        ids: image_codes,
                        lang: i18n.language
                    })
                };
                fetch(api.delete_multiple_image, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const attachmentNew = state.attachments.filter((item) => { return !image_codes.includes(item.code)})
                            setState({...state, attachments: attachmentNew})
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                confirmButtonText: 'OK'
                            })
                        }
                    }
                )
            }
        })        
    }

    const onSendImage = () => {
        if (state.image_selected.length > 0) {
            const action = getMessSample({
                message: '',
                attachments: state.image_selected,
                rand: Math.random(),
                page_name: 'livechat'
            });
            dispatch(action);
            setState({
                ...state,
                open: false
            })
        } else {
            Swal.fire({
                title: t("common.alert.pleaseSelectImage"),
                icon: 'warning',
                timer: 1500
            });
        }
        
    }

    // const onSendImage = (data) => {
    //     if (data.links.length > 0) {
    //         const attachments = [];
    //         if (Array.isArray(data.links)) {
    //             data.links.map((value, key) => {
    //                 attachments.push({
    //                     'mime_type': "image/jpeg",
    //                     'file_url': null,
    //                     'video_data': null,
    //                     'image_data': value
    //                 })
    //             })
    //         } else {
    //             attachments.push({
    //                 'mime_type': "image/jpeg",
    //                 'file_url': null,
    //                 'video_data': null,
    //                 'image_data': data.links
    //             })
    //         }
    //         const action = getMessSample({
    //             message: '',
    //             attachments: attachments,
    //             rand: Math.random()
    //         });
    //         dispatch(action);
    //         setState({
    //             ...state,
    //             open: false
    //         })
    //     } else {
    //         Swal.fire({
    //             title: 'Vui lòng chọn ảnh',
    //             icon: 'warning',
    //             timer: 1500
    //         });
    //     }
    // }

    const onUploadImageByLink = (data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                fanpage_id: props.fanpage_id,
                url: data.url,
                lang: i18n.language
            })
        };
        fetch(api.upload_url, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success == true) {
                    setState({...state,
                                dialog_link: false,
                                attachments: [
                                    {link: data.url, name: data.url},
                                    ...state.attachments
                                ]
                            });
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
        reset();
    }
    
    const deleteImage = (id, index) => {
        if (window.confirm(t("common.alert.confirmDeleteImage"))) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({
                    id: id,
                    fanpage_id: props.fanpage_id,
                    lang: i18n.language
                })
            };
            fetch(api.delete_file, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        const copy_attachments = [...state.attachments];
                        copy_attachments.splice(index, 1);
                        setState({...state,
                                    attachments: copy_attachments
                                });
                        Swal.fire({
                            title: result.message,
                            icon: 'success',
                            confirmButtonText: 'OK'
                        })
                    } else {
                        Swal.fire({
                            title: result.message,
                            icon: 'error',
                            confirmButtonText: 'OK'
                        })
                    }
                }
            )
        }

        return false;
    }

    const selectedImage = (e) => {
        const isChecked = e.target.checked;

        if (isChecked) {
            setState({...state, image_selected: [...state.image_selected, e.target.value]});
        } else {
            // const index = array.indexOf(item);
        }    
    }
    const pickMessSample = (data) => {
        var content = data.content;
        const array_replace = [{key: "{name}", value: props.name_sender}, {key: "{page_name}", value: props.fanpage_name}];
        for (var i = 0; i < array_replace.length; i++) {
            var content_new = content.replaceAll(array_replace[i].key, array_replace[i].value);
            content = content_new;
        }
        const action = getMessSample({
            message: content_new,
            attachments: data.images,
            rand: Math.random(),
            page_name: 'livechat'
        });
        dispatch(action);
        setState({...state, dialog_mess: false});
        //props.getMessSample(content_new);
    }

    const sendCouponMessage = (data) => {
        var content = data.content;
        const array_replace = [{key: "{name}", value: props.name_sender}, {key: "{page_name}", value: props.fanpage_name}];
        for (var i = 0; i < array_replace.length; i++) {
            var content_new = content.replaceAll(array_replace[i].key, array_replace[i].value);
            content = content_new;
        }
        const action = getMessSample({
            message: content_new,
            attachments: [],
            rand: Math.random(),
            page_name: 'livechat'
        });
        dispatch(action);
        setState({...state, dialog_mess_coupon: false});
    }

    return (
        <div className="attachment">
            <Dialog open={state.dialog_link} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog_link: false})}}>
                <DialogTitle>{t("common.upload.enterImageLink")}</DialogTitle>
                <form onSubmit={handleSubmit(onUploadImageByLink)}>
                    <DialogContent sx={{paddingTop: '0px'}}>
                        <p className='m-b-0 m-t-0'>
                            {t("common.upload.uploadImageToFanpage")}
                        </p>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={t("common.upload.yourImageUrl")}
                            type="text"
                            fullWidth
                            variant="outlined"
                            {...register('url')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {setState({...state, dialog_link: false})}}>{t("common.close")}</Button>
                        <Button type="submit">{t("common.confirm")}</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={state.dialog_mess} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog_mess: false})}}>
                <DialogTitle>{t("liveChat.conversation.selectSampleMessage")}</DialogTitle>
                    <DialogContent>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("liveChat.conversation.keyword")}</TableCell>
                                        <TableCell>{t("liveChat.conversation.content")}</TableCell>
                                        <TableCell>{t("liveChat.conversation.action")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.mess_samples.map((row, key) => (
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                <TableCell component="th" scope="row">
                                                    {row.keyword}
                                                </TableCell>
                                                <TableCell>
                                                    {row.content}
                                                </TableCell>
                                                <TableCell>
                                                    <Button onClick={() => pickMessSample(row)} sx={{marginRight: '5px', textTransform: 'none'}} variant="contained" color="primary">
                                                        {t("liveChat.conversation.use")}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
            </Dialog>
            <Dialog open={state.dialog_mess_coupon} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog_mess_coupon: false})}}>
                <DialogTitle>{t("liveChat.conversation.selectPromotionMessage")}</DialogTitle>
                    <DialogContent>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("liveChat.conversation.name")}</TableCell>
                                        <TableCell>{t("liveChat.conversation.content")}</TableCell>
                                        <TableCell>{t("liveChat.conversation.action")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.coupon_messages.map((value, index) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>
                                                        {value.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {value.content}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button onClick={() => sendCouponMessage(value)} variant='contained' className='text-transform'>
                                                            {t("liveChat.conversation.use")}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
            </Dialog>
            
            <Dialog
                fullWidth="true"
                maxWidth='lg'
                open={state.open}
                onClose={handleClose}
            >
                <DialogTitle sx={{display: "flex", justifyContent: "space-between"}}>
                    {t("common.upload.shopImages")}
                    <Button startIcon={<CloseOutlinedIcon />} variant="contained" color="warning" onClick={handleClose}>
                        {t("common.close")}
                    </Button>
                </DialogTitle>
                <DialogContent sx={{overflowY: "unset"}}>
                    {/* <Button onClick={selectFile} sx={{
                        marginRight: '20px'
                        }}
                        variant="outlined" color="primary" startIcon={<UploadRoundedIcon />}>
                        Tải ảnh lên
                    </Button> */}
                    <ButtonUploadImage
                        cookie={props.cookie}
                        fanpage_id={props.fanpage_id}
                        updateAttachment={updateAttachment}
                    />
                    <Button sx={{
                        marginRight: '20px'
                        }} onClick={() => {setState({...state, input_link: true, dialog_link: true})}} variant="outlined" color="success" startIcon={<AddLinkRoundedIcon />}>
                        {t("common.upload.uploadFromUrl")}
                    </Button>
                    <TextField sx={{
                        marginRight: '20px'
                        }} size="small" id="outlined-basic" onChange={handleChangeKeySearch} label="Tìm kiếm tên ảnh" variant="outlined"></TextField>
                    {
                        state.image_selected.length > 0 &&
                        <Button sx={{
                            marginRight: '20px'
                            }} onClick={onSendImage} variant="outlined" color="warning">
                                {/* Sử dụng { state.image_selected.length } ảnh */}
                                {t("common.upload.useImages", {
                                    count: state.image_selected.length
                                })}
                            </Button>
                    }
                    {
                        state.image_selected.length > 0 &&
                        <Button onClick={onDeleteImage} variant="outlined" color="error">
                            {/* Xóa { state.image_selected.length } ảnh */}
                            {t("common.upload.deleteImages", {
                                count: state.image_selected.length
                            })}
                        </Button>
                    }
                </DialogContent>
                <DialogContent>
                    <SelectableGroup
                        allowClickWithoutSelected={true}
                        // globalMouse={true}
                        // resetOnStart={true}
                        tolerance={0}
                        onSelectionFinish={(selected) => onSelected(selected)}
                        ignoreList={['.name-col', '.h-col', '.s-col', '.u-col', '.v-col']}
                    >
                        <form onSubmit={handleSubmit(onSendImage)}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    {
                                        state.attachments.map((value, key) => {
                                            return (
                                                <ImageItem
                                                value={value}
                                                key={key}
                                                index={key}
                                                />
                                            )
                                        })
                                    }
                                </Grid>
                                {/* <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button type="submit" sx={{margin: "20px 0px 0px 0px", width: '100%'}} startIcon={<SendRoundedIcon />} variant="contained" color="primary">
                                            Xác nhận
                                        </Button>
                                    </Grid>
                                </Grid> */}
                            </Box>
                        </form>
                    </SelectableGroup>
                </DialogContent>
            </Dialog>
            {/* <input ref={inputFile} onChange={onChangeFile} type="file" multiple className='file'/> */}
            <DialogAttachmentManager
            page_name="livechat"
            imagePicked={() => {return 1}}
            fanpage_id={props.fanpage_id}
            closeDialogAttachment={closeDialogAttachment}
            cookie={props.cookie}
            isOpenDialogAttachment={state.isOpenDialogAttachment}
            imageSelectedOld={data_redux.page_name == 'livechat' ? data_redux.attachments : []}
            />
            <div>
                <Tooltip arrow onClick={handleClickOpen} title={t("liveChat.conversation.sendImage")} placement="top">
                    <ImageOutlinedIcon></ImageOutlinedIcon>
                </Tooltip>
            </div>
            
            {/* <Tooltip title="Gửi video" placement="top">
                <SlowMotionVideoOutlinedIcon></SlowMotionVideoOutlinedIcon>
            </Tooltip>
            <Tooltip title="Gửi block" placement="top">
                <WidgetsOutlinedIcon></WidgetsOutlinedIcon>
            </Tooltip> */}
            <div>
                <Tooltip arrow onClick={() => {setState({...state, dialog_mess: true})}} title={t("liveChat.conversation.quickReplyTemplate")} placement="top">
                    <ArticleOutlinedIcon></ArticleOutlinedIcon>
                </Tooltip>
            </div>
            <div>
                <Tooltip arrow onClick={() => {setState({...state, dialog_mess_coupon: true})}} title={t("liveChat.conversation.promotionTemplate")} placement="top">
                    <MarkUnreadChatAltRoundedIcon/>
                </Tooltip>
            </div>
            <DialogBlock
                cookie={props.cookie}
                receive_id={props.receive_id}
                fanpage_id={props.fanpage_id}
            />
        </div>
    )
}

export default ManageAttachment;