import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ButtonUploadImage from "../../Messages/Chats/Components/ButtonUploadImage";
import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded';
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import api from './../../../../../../Apis'
import { requestOptions } from "../../../../../../Helpers/function";
import Swal from "sweetalert2";
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import LoadingButton from "@mui/lab/LoadingButton";
import thumbnailVideo from "./../../../../../../Assets/Images/video-thumbnail-default.jpg"
import { useTranslation } from "react-i18next"

export default function AddImage(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        open_dialog: false,
        dialog_link: false,
        attachments: [],
        image_picked: [],
        loading_upload_video: false
    })
    const {register, handleSubmit, setValue, control, reset} = useForm();

    const handleClose = () => {
        setState({
            ...state,
            open_dialog: false
        })
    }

    const handleChangeKeySearch = () => {

    }

    const deleteImage = (value, index) => {
        if (window.confirm(t("common.alert.confirmDeleteImage"))) {
            fetch(api.delete_file, requestOptions('POST', props.cookie, {code: value.code, lang: i18n.language}))
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        const copy_attachments = [...state.attachments];
                        copy_attachments.splice(index, 1);
                        setState({...state,
                                    attachments: copy_attachments
                                });
                        Swal.fire({
                            title: result.message,
                            icon: 'success',
                            confirmButtonText: 'OK'
                        })
                    } else {
                        Swal.fire({
                            title: result.message,
                            icon: 'error',
                            confirmButtonText: 'OK'
                        })
                    }
                }
            )
        }

        return false;
    }

    const onSendImage = (data) => {
        data.links = Array.isArray(data.links) ? data.links : [data.links]
        if ((Array.isArray(data.links) && data.links.length > 0) || data.links !== false) {
            const filterVideo = data.links.filter((item) => item.includes(".mp4"));
            if (filterVideo.length > 0 && (data.links.length != filterVideo.length || filterVideo.length > 1)) {
                Swal.fire("Chỉ được phép chọn 1 video và không đính kèm thêm ảnh", "", "warning")
            } else {
                setState({
                    ...state,
                    image_picked: data.links,
                })
                props.updateImagePicked(data.links);
            }
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Vui lòng chọn ảnh'
            })
        }
    }

    const updateAttachment = (attachment_new) => {
        const attachment_olds = [...state.attachments];
        const attachments = attachment_new.concat(attachment_olds);
        setState({
            ...state,
            attachments: attachments
        })
    }

    const onUploadImageByLink = (data) => {
        fetch(api.upload_url, requestOptions('POST', props.cookie, {url: data.url, lang: i18n.language}))
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success == true) {
                    setState({...state,
                                dialog_link: false,
                                attachments: [
                                    result.datas,
                                    ...state.attachments
                                ]
                            });
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
        reset();
    }

    useEffect(() => {
        fetch(api.get_attachment, requestOptions('POST', props.cookie, { lang: i18n.language }))
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        attachments: result.datas
                    })
                }
            }
        )
    }, [])

    const inputFile = useRef(null);

    const onChangeFile = (e) => {
        const file = e.target.files;
        if (file.length > 0) {
            const formData = new FormData();
            formData.append('video', file[0]);
            formData.append('fanpage_id', props.fanpage_id);
            formData.append('lang', i18n.language);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: formData
            };
            setState({...state, loading_upload_video: true})
            fetch(api.upload_video, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        setState({
                            ...state,
                            attachments: [result.datas, ...state.attachments]
                        })
                    } else {
                        if (result.type == "validate") {
                            Swal.fire(result.errors, "", "warning");
                        } else {
                            Swal.fire(result.message, "", "warning");
                        }
                    }
                }
            )
        }
    }

    return (
        <div>
            <Dialog open={state.dialog_link} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog_link: false})}}>
                <DialogTitle>{t("post.enterImageLink")}</DialogTitle>
                <form onSubmit={handleSubmit(onUploadImageByLink)}>
                    <DialogContent sx={{paddingTop: '0px'}}>
                        <p className='m-b-0 m-t-0'>
                          {t("post.uploadImageAndCopyLink")}
                        </p>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={t("post.yourImageLink")}
                            type="text"
                            fullWidth
                            variant="outlined"
                            {...register('url')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {setState({...state, dialog_link: false})}}>{t('common.close')}</Button>
                        <Button type="submit">{t("common.confirm")}</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <DialogContent>
                <ButtonUploadImage
                    cookie={props.cookie}
                    fanpage_id={props.fanpage_id}
                    updateAttachment={updateAttachment}
                />
                {
                    props.hasUploadVideo === true || props.hasUploadVideo === undefined &&
                    <LoadingButton loading={ state.loading_upload_video } sx={{
                        marginRight: '20px'
                        }} onClick={() => inputFile.current.click()} variant="outlined" color="warning" startIcon={<UploadRoundedIcon />}>
                        {t("common.upload.uploadVideo")}
                    </LoadingButton>
                }
                
                <input style={{display: 'none'}} ref={inputFile} onChange={onChangeFile} multiple type="file" className='file'/>
                <Button sx={{
                    marginRight: '20px'
                    }} onClick={() => {setState({...state, dialog_link: true})}} variant="outlined" color="success" startIcon={<AddLinkRoundedIcon />}>
                    {t("common.upload.uploadFromUrl")}
                </Button>
                {/* <TextField size="small" id="outlined-basic" onChange={handleChangeKeySearch} label="Tìm kiếm tên ảnh" variant="outlined"></TextField> */}
            </DialogContent>
            <DialogContent>
                <form onSubmit={handleSubmit(onSendImage)}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            {
                                state.attachments.map((value, key) => {
                                    return (
                                        <Grid item xs={2} sx={{position: 'relative'}}>
                                            <input {...register('links')} style={{position: 'absolute', right: '0px', borderRadius: 'unset', width: "25px", height: "25px"}} type="checkbox" name="links" value={value.link}></input>
                                            <div className='block-image'>
                                                {
                                                    (value.type == "file" || value.type == "url")
                                                    ?
                                                    <img className='lib-image' src={value.link}></img>
                                                    :
                                                    <img className='lib-image' src={ thumbnailVideo }></img>
                                                }
                                                <p className='name'>
                                                    {value.name}
                                                </p>
                                                <p className='m-b-0'>
                                                    <Button onClick={() => deleteImage(value, key) } color='error' startIcon={<DeleteRoundedIcon/>} size='small' variant="contained">{t("common.delete")}</Button>
                                                </p>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button type="submit" sx={{margin: "20px 0px 0px 0px", width: '100%'}} startIcon={<SendRoundedIcon />} variant="contained" color="primary">
                                    {t("common.confirm")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </DialogContent>
        </div>
        
    )
}