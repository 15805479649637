import LoadingButton from "@mui/lab/LoadingButton";
import { Grid } from "@mui/material";
import { useState } from "react";
import Swal from "sweetalert2";
import api from './../../../../../Apis';
import { useTranslation } from "react-i18next";

function ButtonUpdateOrder(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        loading: false
    })

    const updateOrder = () => {
        setState({
            ...state,
            loading: true
        })
        const body = props.state.order;
        // if (typeof props.state.order.transport === 'object') {
        //     body.transport = props.state.order.transport.id;
        // }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({...body, lang: i18n.language})
        };
        fetch(api.update_order, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    if (result.type == 'validate') {
                        // var text_error = '';
                        // result.errors.map((value, key) => {
                        //     text_error+= '<p>' + value + '</p>';
                        // })
                        // Swal.fire({
                        //     title: result.message,
                        //     icon: 'warning',
                        //     html: text_error
                        // })
                        props.responseValidate(result.errors);
                    } else {
                        Swal.fire(result.message, '', 'error');
                    }
                }
                setState({
                    ...state,
                    loading: false
                })
            }
        )
    }

    return (
        <Grid item xs={12} md={12}>
            <LoadingButton onClick={updateOrder} size="large" fullWidth loading={state.loading} variant="contained">
              {t("order.updateOrder")}
            </LoadingButton>
        </Grid>
    )
}

export default ButtonUpdateOrder;